import * as React from 'react';
import Container from '@mui/material/Container';
import {Button, Stack, Typography} from '@mui/material';
import {strings} from 'settings';

interface ErrorScreenProps {
  title: string;
  message: string;
}

export const ErrorScreen = (props: ErrorScreenProps) => {
  return (
    <Container maxWidth="md">
      <Stack
        direction="column"
        alignItems="flex-start"
        spacing={2}
        sx={{py: 2}}>
        <Typography variant="h2">{props.title}</Typography>
        <Typography>{props.message}</Typography>
        <Typography>{strings.QUIZ_ERROR_RELOAD}</Typography>
        <Button
          variant="contained"
          onClick={() => {
            window.location.reload();
          }}>
          {strings.QUIZ_ERROR_RELOAD_BUTTON}
        </Button>
      </Stack>
    </Container>
  );
};
