import {types, Instance} from 'mobx-state-tree';

export const VideoModalModel = types
  .model('VideoModal')
  .props({
    id: types.maybeNull(types.number),
    solution: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    person: types.maybeNull(types.string),
    questionIndex: types.maybeNull(types.number),
    field: types.maybeNull(types.string),
    prevValue: types.maybeNull(types.string),
    disabledField: types.maybeNull(types.string),
    visible: (types.boolean, false),
    update: (types.boolean, false),
    readOnly: (types.boolean, false),
  })

  .actions((self) => ({
    setId(id: number | null) {
      self.id = id;
    },
    setSolution(solution: string | null) {
      self.solution = solution;
    },
    setDescription(description: string | null) {
      self.description = description;
    },
    setPerson(person: string | null) {
      self.person = person;
    },
    onClose() {
      self.visible = false;
      self.id = null;
      self.solution = null;
      self.description = null;
      self.person = null;
      self.questionIndex = null;
      self.field = null;
      self.prevValue = null;
      self.update = false;
      self.disabledField = null;
    },
    setVisible(visible: boolean) {
      self.visible = visible;
    },
    setUpdate(update: boolean) {
      self.update = update;
    },
    setQuestionIndex(index: number | null) {
      self.questionIndex = index;
    },
    setField(field: string | null) {
      self.field = field;
    },
    setPrevValue(value: string | null) {
      self.prevValue = value;
    },
    setDisabledField(value: string | null) {
      self.disabledField = value;
    },
    setReadOnly(value: boolean) {
      self.readOnly = value;
    },
  }));

export interface VideoModal extends Instance<typeof VideoModalModel> {}
