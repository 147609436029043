export const errors = {
  notFound: {
    success: false,
    code: 404,
    error: 'not found',
  },
  restricted: {
    success: false,
    code: 403,
    error: 'restricted',
  },
  unauthorized: {
    success: false,
    code: 401,
    error: 'unauthorized',
  },
};
