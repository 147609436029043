import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

export const SearchModalSkeleton = () => {
  const skeletonStyle = {
    transform: 'scale(1)',
    marginBottom: '8px',
    marginTop: '8px',
  };
  return (
    <Box data-testid="loading-element">
      <Skeleton
        variant="text"
        animation="wave"
        width="40%"
        height={16}
        sx={skeletonStyle}
      />
      <Skeleton
        variant="text"
        animation="wave"
        height={40}
        sx={skeletonStyle}
      />
      <Skeleton
        variant="text"
        animation="wave"
        height={40}
        sx={skeletonStyle}
      />
      <Box height={16} />
      <Skeleton
        variant="text"
        animation="wave"
        width="40%"
        height={16}
        sx={skeletonStyle}
      />
      <Skeleton
        variant="text"
        animation="wave"
        height={40}
        sx={skeletonStyle}
      />
      <Skeleton
        variant="text"
        animation="wave"
        height={40}
        sx={skeletonStyle}
      />
    </Box>
  );
};
