import * as Sentry from '@sentry/react';

export const removeArrayDuplicates = (
  array1: Array<string>,
  array2: Array<string>,
): Array<string> => {
  return array1.concat(array2.filter((value) => !array1.includes(value)));
};

export const parseJsonSafely = (json: string): unknown => {
  try {
    return JSON.parse(json);
  } catch (e: unknown) {
    Sentry.captureException(e, {extra: {json}});
  }
};
