import {GetTeleprompterResponse} from 'types/api';

export const teleprompter: GetTeleprompterResponse = {
  success: true,
  words: [
    {
      category: 'Discover',
      person: 'a',
      prefix: '02_01',
      quiz: '02_01_1-discover',
      word: "don't-UNDERSTAND",
    },
    {
      category: 'Discover',
      person: 'a',
      prefix: '02_01',
      quiz: '02_01_1-discover',
      word: 'UNDERSTAND',
    },
  ],
};

export const teleprompterPersonB: GetTeleprompterResponse = {
  success: true,
  words: [
    {
      category: 'Discover',
      person: 'b',
      prefix: '02_01 B:',
      quiz: '02_01_1-discover',
      word: 'UNDERSTAND',
    },
    {
      category: 'Discover',
      person: 'b',
      prefix: '02_01 B:',
      quiz: '02_01_1-discover',
      word: 'HELLO',
    },
  ],
};

export const teleprompterDialog: GetTeleprompterResponse = {
  success: true,
  words: [
    {
      category: 'Dialog',
      person: 'a',
      prefix: 'DA1:',
      quiz: '01_02_1-dialog',
      word: 'HELLO WELCOME',
    },
    {
      category: 'Dialog',
      person: 'b',
      prefix: 'DB1:',
      quiz: '01_02_1-dialog',
      word: 'HELLO. HOW YOU?',
    },
  ],
};
