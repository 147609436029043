import {QuestionStatus, QuestionTrainerStatus} from 'screens/QuizDetailScreen';

export const quizEmpty = {
  success: true,
  response: {
    id: 8097,
    lessonId: 2821,
    title: '02_01_4-Review',
    category: 'Review',
    url: 'https://app.lingvano.com/asl/learning/02_01_4-Review',
    questions: [],
  },
};

export const quizDiscover = {
  success: true,
  response: {
    id: 8074,
    lessonId: 2821,
    title: '02_01_1-Discover',
    category: 'Discover',
    url: 'https://app.lingvano.com/asl/learning/02_01_1-discover/',
    questions: [
      {
        id: 65431,
        status: 'published' as QuestionStatus,
        title: 'UNDERSTAND',
        title_code: '01_02_1-1-1a',
        category: '1a',
        question: '[qvideo description="UNDERSTAND" id=\'6373\' person="b"]',
        correctMsg: '',
        incorrectMsg: '',
        type: 'content',
        questionParts: [
          {
            type: 'video',
            mediaUrl: 'https://videos-asl.lingvano.com/6373-480p.mp4',
            teacher: 'Elizabeth',
            dialogPerson: 'b',
            content: 'UNDERSTAND',
          },
        ],
      },
      {
        id: 65433,
        title: 'Shaking your head!',
        title_code: '01_02_1-3-1c',
        category: '1c',
        trainerStatus: 'vocab' as QuestionTrainerStatus,
        question:
          '<p>The sign  <strong>UNDERSTAND </strong> is a perfect example of demonstrating how your facial expression can change the meaning of a sign. </p>[qvideo id="6375"  solution="don\'t-UNDERSTAND" ][qvideo id="6373" solution="UNDERSTAND"]\n<p>Shaking your head while signing  <strong>UNDERSTAND </strong> will translate to "I don\'t understand". In contrast, nodding while signing  <strong>UNDERSTAND </strong> means "I do UNDERSTAND". </p>',
        correctMsg: '',
        incorrectMsg: '',
        type: 'content',
        questionParts: [
          {
            type: 'video',
            mediaUrl: 'https://videos-asl.lingvano.com/6375-480p.mp4',
            teacher: 'Cameron',
            content: 'UNDERSTAND',
          },
          {
            type: 'video',
            mediaUrl: 'https://videos-asl.lingvano.com/6373-480p.mp4',
            teacher: 'Cameron',
            content: 'UNDERSTAND',
          },
        ],
      },
      {
        id: 65434,
        title: "Choose the sign don't-UNDERSTAND!",
        title_code: '01_02_1-4-2l',
        category: '2l',
        question: '',
        correctMsg: '',
        incorrectMsg:
          "To form  <strong>don't-UNDERSTAND </strong> you sign  <strong>UNDERSTAND </strong> and shake your head in a denying way.",
        type: 'single',
        answer1:
          '[qvideo id="6375" description="don\'t-UNDERSTAND" solution="don\'t-UNDERSTAND"]',
        answer2:
          '[qvideo id="6373" description="UNDERSTAND" solution="UNDERSTAND" person="b"]',
        answer: '1',
        answers: [
          {
            answerText: '',
            media: {
              type: 'video',
              mediaUrl: 'https://videos-asl.lingvano.com/6375-480p.mp4',
              teacher: 'Pinky',
              content: 'ME',
            },
            isCorrect: true,
          },
          {
            answerText: '',
            media: {
              person: 'b',
              type: 'video',
              mediaUrl: 'https://videos-asl.lingvano.com/6373-480p.mp4',
              teacher: 'Elizabeth',
              content: 'YOU',
            },
            isCorrect: false,
          },
        ],
      },
      {
        id: 65435,
        title: 'Choose the correct sentence!',
        title_code: '01_02_1-5-2c',
        category: '2c',
        question: '[qvideo id=\'6390\' solution="YOU UNDERSTAND ME?"]',
        correctMsg: '"Do you understand me?"',
        incorrectMsg: '"Do you understand me?"',
        type: 'single',
        answer1: 'YOU UNDERSTAND ME?',
        answer2: "YOU don't-UNDERSTAND ME?",
        answer: '1',
      },
      {
        id: 65436,
        title: 'True or False: You raise your eyebrows for questions.',
        title_code: '01_02_1-6-2a',
        category: '2a',
        question:
          '<h2>Is it appropriate to raise your eyebrows at questions that can be answered with YES or NO?</h2>',
        correctMsg: '"Do you understand me?"',
        incorrectMsg: '"Do you understand me?"',
        type: 'single',
        answer1: 'True',
        answer2: 'False',
        answer: '1',
      },
      {
        id: 65439,
        title: 'Choose the correct sign!',
        title_code: '01_02_1-9-2d',
        category: '2d',
        question: '[qvideo id=\'6384\' solution="HAVE"]',
        correctMsg: '',
        incorrectMsg: '',
        type: 'single',
        answer1: 'HAVE',
        answer2: 'FINE',
        answer: '1',
      },
      {
        id: 65443,
        title: 'Fill the gap with the right word!',
        title_code: '01_02_1-13-4d',
        category: '4d',
        question: '[qvideo id="6389" solution="YOU HAVE GLASS YOU?"]',
        hint: ['DRINK', 'HAVE', 'BABY'],
        correctMsg: '"Do you have a glass, don\'t you?"',
        incorrectMsg: '"Do you have a glass, don\'t you?"',
        type: 'cloze_answer',
        answer: 'YOU {HAVE} GLASS YOU?',
      },
      {
        id: 65446,
        title: 'Which signs are in the video?',
        title_code: '01_02_1-16-3a',
        category: '3a',
        question:
          '[qvideo id="6383" solution="ME HAVE BABY HEARING" person="b"]',
        correctMsg: '"I have a hearing baby."',
        incorrectMsg: '"I have a hearing baby."',
        type: 'multiple',
        answer1: 'HAVE',
        answer2: 'BABY',
        answer3: 'HEARING',
        answer4: "don't-HAVE",
        answer: '|1||2||3',
      },
      {
        id: 65619,
        title: 'Choose the correct video!',
        title_code: '01_01_4-8-2j',
        category: '2j',
        question:
          '[img id="3441" description="person drinking champagne" caption=""]',
        correctMsg: '',
        incorrectMsg: '',
        type: 'single',
        answer1:
          '[qvideo id="e8ac4cae1db12a08e4c3f996a40ed4c1" description="CHAMPAGNE GLASS DRINK" solution=\'"Drinking from a champagne glass"\' origin="29"]',
        answer2:
          '[qvideo id="1028f60f177bd4716138cb201b2d6d44?short=true" description="GLASS DRINK" solution=\'"Drinking from a glass"\' origin="6366"]',
        answer: '1',
      },
      {
        id: 65446,
        status: 'published' as QuestionStatus,
        title: 'What is true?',
        title_code: '01_02_1-16-3a',
        category: '3c',
        question:
          '[qvideo id="6383" solution="TEXTBLOCK IS NOT VISIBLE" person="b"]',
        correctMsg: '"The textblock is not visible"',
        incorrectMsg: '"The textblock is not visible"',
        type: 'multiple',
        answer1: 'TEST',
        answer2: 'BLOCK',
        answer3: 'VISIBLE',
        answer: '|1||2||3',
      },
    ],
  },
};

export const quizTrainer = {
  success: true,
  response: {
    id: 8074,
    lessonId: 2821,
    title: 'vocab_trainer_asl',
    category: 'Discover',
    url: 'https://app.lingvano.com/asl/learning/02_01_1-discover/',
    questions: [
      {
        id: 65431,
        lessonId: '02_01_1-Discover',
        status: 'published' as QuestionStatus,
        title: 'UNDERSTAND',
        title_code: '01_02_1-1-1a',
        category: '1a',
        question: '[qvideo description="UNDERSTAND" id=\'6373\' person="b"]',
        correctMsg: '',
        incorrectMsg: '',
        type: 'content',
        questionParts: [
          {
            type: 'video',
            mediaUrl: 'https://videos-asl.lingvano.com/6373-480p.mp4',
            teacher: 'Elizabeth',
            dialogPerson: 'b',
            content: 'UNDERSTAND',
          },
        ],
      },
      {
        id: 65433,
        title: 'Shaking your head!',
        title_code: '01_02_1-3-1c',
        category: '1c',
        question:
          '<p>The sign  <strong>UNDERSTAND </strong> is a perfect example of demonstrating how your facial expression can change the meaning of a sign. </p>[qvideo id="6375"  solution="don\'t-UNDERSTAND" ][qvideo id="6373" solution="UNDERSTAND"]\n<p>Shaking your head while signing  <strong>UNDERSTAND </strong> will translate to "I don\'t understand". In contrast, nodding while signing  <strong>UNDERSTAND </strong> means "I do UNDERSTAND". </p>',
        correctMsg: '',
        incorrectMsg: '',
        type: 'content',
        questionParts: [
          {
            type: 'video',
            mediaUrl: 'https://videos-asl.lingvano.com/6375-480p.mp4',
            teacher: 'Cameron',
            content: 'UNDERSTAND',
          },
          {
            type: 'video',
            mediaUrl: 'https://videos-asl.lingvano.com/6373-480p.mp4',
            teacher: 'Cameron',
            content: 'UNDERSTAND',
          },
        ],
      },
      {
        id: 65434,
        title: "Choose the sign don't-UNDERSTAND!",
        title_code: '01_02_1-4-2l',
        category: '2l',
        question: '',
        correctMsg: '',
        incorrectMsg:
          "To form  <strong>don't-UNDERSTAND </strong> you sign  <strong>UNDERSTAND </strong> and shake your head in a denying way.",
        type: 'single',
        answer1:
          '[qvideo id="6375" description="don\'t-UNDERSTAND" solution="don\'t-UNDERSTAND"]',
        answer2:
          '[qvideo id="6373" description="UNDERSTAND" solution="UNDERSTAND" person="b"]',
        answer: '1',
        answers: [
          {
            answerText: '',
            media: {
              type: 'video',
              mediaUrl: 'https://videos-asl.lingvano.com/6375-480p.mp4',
              teacher: 'Pinky',
              content: 'ME',
            },
            isCorrect: true,
          },
          {
            answerText: '',
            media: {
              person: 'b',
              type: 'video',
              mediaUrl: 'https://videos-asl.lingvano.com/6373-480p.mp4',
              teacher: 'Elizabeth',
              content: 'YOU',
            },
            isCorrect: false,
          },
        ],
      },
    ],
  },
};

export const quizTpp = {
  success: true,
  response: {
    id: 8999,
    lessonId: 2821,
    title: '02_01_1-Review',
    category: 'Review',
    url: 'https://app.lingvano.com/asl/learning/02_01_6-review/',
    questions: [
      {
        id: 65619,
        title: 'Choose the correct video!',
        title_code: '01_01_4-8-2j',
        category: '2j',
        question:
          '[img id="3441" description="person drinking champagne" caption=""]',
        correctMsg: '',
        incorrectMsg: '',
        type: 'single',
        answer1:
          '[qvideo id="e8ac4cae1db12a08e4c3f996a40ed4c1" description="CHAMPAGNE GLASS DRINK" solution=\'"Drinking from a champagne glass"\' origin="29"]',
        answer2:
          '[qvideo id="1028f60f177bd4716138cb201b2d6d44?short=true" description="GLASS DRINK" solution=\'"Drinking from a glass"\' origin="6366"]',
        answer: '1',
      },
      {
        id: 65434,
        title: "Choose the sign don't-UNDERSTAND!",
        title_code: '01_02_1-4-2l',
        category: '2l',
        question: '',
        correctMsg: '',
        incorrectMsg:
          "To form  <strong>don't-UNDERSTAND </strong> you sign  <strong>UNDERSTAND </strong> and shake your head in a denying way.",
        type: 'single',
        answer1:
          '[qvideo id="" description="don\'t-UNDERSTAND" solution="don\'t-UNDERSTAND"]',
        answer2:
          '[qvideo id="" description="UNDERSTAND" solution="UNDERSTAND" person="b"]',
        answer: '1',
      },
      {
        id: 65443,
        title: 'Fill the gap with the right word!',
        title_code: '01_02_1-13-4d',
        category: '4d',
        question: '[qvideo id="" solution="YOU HAVE BABY?"]',
        hint: ['DRINK', 'HAVE', 'BABY'],
        correctMsg: '"Do you have a glass, don\'t you?"',
        incorrectMsg: '"Do you have a glass, don\'t you?"',
        type: 'cloze_answer',
        answer: 'YOU {HAVE} BABY?',
      },
      {
        id: 65619,
        title: 'Choose the correct video!',
        title_code: '01_01_4-8-2j',
        category: '2j',
        question:
          '[img id="3441" description="person drinking champagne" caption=""]',
        correctMsg: '',
        incorrectMsg: '',
        type: 'single',
        answer1:
          '[qvideo id="" description="CHAMPAGNE GLASS DRINK" solution="DRINK CHAMPAGNE" person="b"]',
        answer2:
          '[qvideo id="1234" description="GLASS DRINK" solution="GLASS DRINK" person="b"]',
        answer: '1',
      },
    ],
  },
};

export const quizDialog = {
  success: true,
  response: {
    id: 9999,
    lessonId: 2821,
    title: '01_02_1-Dialog',
    category: 'Dialog',
    url: 'https://app.lingvano.com/asl/learning/01_02_1-dialog',
    questions: [
      {
        id: 65431,
        title: '',
        title_code: '01_02_1-1-1a',
        category: '1a',
        question:
          '[lv-dialog id="47" subtitle="HELLO WELCOME" person="a"][lv-dialog id="2835" subtitle="HELLO HOW YOU? - dialog only" person="b"]<p>Fill the gap!</p>',
        correctMsg: '',
        incorrectMsg: '',
        type: 'content',
        questionParts: [
          {
            type: 'video',
            mediaUrl: 'https://videos-asl.lingvano.com/47-480p.mp4',
            teacher: 'Elizabeth',
            dialogPerson: 'a',
            content: 'HELLO WELCOME',
          },
          {
            type: 'video',
            mediaUrl: 'https://videos-asl.lingvano.com/2835-480p.mp4',
            teacher: 'Rosie',
            dialogPerson: 'b',
            content: 'HELLO HOW YOU? - dialog only',
          },
          {
            type: 'html',
            content: '<p>Fill the gap!</p>',
          },
        ],
      },
      {
        id: 69210,
        title: '',
        title_code: '06_08_3-4-4e',
        category: '4e',
        correctMsg: '"English."',
        incorrectMsg: '"English."',
        question: '[lv-dialog subtitle="GREAT. WILL FUN" person="b"]',
        type: 'cloze_answer',
        answer: '{GREAT} {FUN} {WILL}',
        hint: ['WILL', 'GREAT', 'FUN'],
      },
    ],
  },
};

export const quizDynamic = {
  success: true,
  response: {
    id: 9999,
    lessonId: 2821,
    title: 'A Dynamic Quiz',
    category: 'Explore',
    url: 'https://app.lingvano.com/asl/learning/01_02_1-dynamic',
    questions: [
      {
        id: 65431,
        title: 'UNDERSTAND',
        title_code: '01_02_1-1-1a',
        category: '1a',
        question: '[qvideo id=\'6373\' description="UNDERSTAND" ]',
        correctMsg: '',
        incorrectMsg: '',
        type: 'content',
      },
      {
        id: 65432,
        title: 'HELLO',
        title_code: '01_02_1-1-1c',
        category: '1c',
        question: '[qvideo id="" solution="HELLO"]',
        correctMsg: '',
        incorrectMsg: '',
        type: 'content',
      },
    ],
  },
};

export const quizUnknownFormat = {
  success: true,
  response: {
    id: 888888888,
    lessonId: 2821,
    title: 'Unknown format quiz',
    category: 'Explore',
    url: 'https://app.lingvano.com/asl/learning/01_02_1-dynamic',
    questions: [
      {
        id: 65431,
        title: 'UNDERSTAND',
        title_code: '01_02_1-1-1a',
        category: '4z',
        question: '[qvideo id=\'6373\' description="UNDERSTAND" ]',
        correctMsg: '',
        incorrectMsg: '',
        type: 'content',
      },
    ],
  },
};
