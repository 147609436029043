import React from 'react';
import {Button, Chip, Skeleton, Stack, Tabs, Typography} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {PageHeaderProps, PageHeaderActionButton} from './';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';
import {SubmitHandler} from 'react-hook-form';
import {CategoryChangeModal} from 'components/molecules/CategoryChangeModal';

export const PageHeader = (props: PageHeaderProps) => {
  const [categoryState, setCategoryState] = React.useState(props.category);
  const [value, setValue] = React.useState(0);
  const [categoryModalOpen, setCategoryModalOpen] = React.useState(false);
  const [categoryFormError, setCategoryFormError] = React.useState(false);

  React.useEffect(() => {
    setCategoryState(props.category);
  }, [props.category]);

  const skeletonStyle = {
    transform: 'scale(1)',
    marginBottom: '8px',
    marginTop: '8px',
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (props.navBar) {
      setValue(newValue);
    }
  };

  const _onCategoryChange: SubmitHandler<{category: string}> = (formValues) => {
    if (formValues.category === 'Dialog') {
      setCategoryFormError(true);
      return;
    }
    // TODO: Integrate API Call to change category here
    setCategoryState(formValues.category);
    setCategoryModalOpen(false);
  };

  const _renderCategoryModal = () => {
    return (
      <CategoryChangeModal
        category={categoryState}
        open={categoryModalOpen}
        onClose={() => setCategoryModalOpen(false)}
        onSubmit={_onCategoryChange}
        error={categoryFormError}
      />
    );
  };

  const _renderActionButton = (button: PageHeaderActionButton, i: number) =>
    button.title ? (
      <Button
        variant={button.variant ? button.variant : 'contained'}
        startIcon={button.icon}
        disabled={button.disabled}
        onClick={button.onPress}
        key={i}>
        {button.title}
      </Button>
    ) : (
      // <span> is needed here to make sure tooltip is also shown in disabled state
      <span>
        <IconButton
          disabled={button.disabled}
          onClick={button.onPress}
          aria-label={button.ariaLabel}>
          {button.icon}
        </IconButton>
      </span>
    );

  return (
    <Container
      maxWidth={false}
      disableGutters={true}
      sx={{
        borderBottom: 1,
        borderColor: 'divider',
        bgcolor: '#dedede',
        position: 'sticky',
        top: '52px',
        zIndex: 10,
      }}>
      <Container maxWidth="md" sx={{py: {xs: 2, xl: 3}}}>
        {props.loading && (
          <>
            <Skeleton
              variant="text"
              animation="wave"
              width="40%"
              height={32}
              sx={{
                transform: 'scale(1)',
                marginBottom: '24px',
                marginTop: '8px',
              }}
            />
            {props.navBar && (
              <Skeleton
                variant="text"
                animation="wave"
                width="60%"
                height={20}
                sx={skeletonStyle}
              />
            )}
          </>
        )}
        {!props.loading && (
          <>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}>
              <Stack direction="column">
                <Typography fontWeight={800} variant="h2">
                  {props.title}
                </Typography>
                {props.subtitle ? (
                  <Typography sx={{marginTop: 1}} variant="h4">
                    {props.subtitle}
                  </Typography>
                ) : null}
              </Stack>
              {categoryState && (
                <Chip
                  label={'Category: ' + categoryState}
                  // onClick={() => setCategoryModalOpen(true)}
                />
              )}
              {_renderCategoryModal()}
              {props.actionButtons && (
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  spacing={2}
                  sx={{ml: 'auto !important'}}>
                  {props.actionButtons.map((button, i) => {
                    return button.tooltip ? (
                      <Tooltip key={i} title={button.tooltip}>
                        <span>{_renderActionButton(button, i)}</span>
                      </Tooltip>
                    ) : (
                      _renderActionButton(button, i)
                    );
                  })}
                </Stack>
              )}
            </Stack>
            {props.navBar && (
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example">
                {props.navBar.map((navItem, i) => (
                  <Tab
                    icon={navItem.icon}
                    iconPosition="start"
                    label={navItem.title}
                    {...a11yProps(0)}
                    key={i}
                  />
                ))}
              </Tabs>
            )}
          </>
        )}
      </Container>
    </Container>
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
