import React from 'react';
import {styled} from '@mui/material/styles';
import {Button} from '@mui/material';

type AddQuestionType = 'single' | 'block';

interface AddButton {
  title: string;
  icon: React.ReactElement;
  type: AddQuestionType;
}

interface DataGridAddRowButtonProps {
  buttons: Array<AddButton>;
  onPress: (type: AddQuestionType) => void;
}

export const DataGridAddRowButton = (props: DataGridAddRowButtonProps) => {
  return (
    <StyledButtonWrapper>
      {props.buttons.map((button, i) => (
        <Button
          key={i}
          variant="outlined"
          sx={{background: 'white', border: '1px solid white', mx: '2px'}}
          startIcon={button.icon}
          fullWidth={true}
          onClick={() => props.onPress(button.type)}>
          {button.title}
        </Button>
      ))}
    </StyledButtonWrapper>
  );
};

const StyledButtonWrapper = styled('div')({
  width: '100%',
  height: '50px',
  display: 'flex',
  padding: '4px 0',
});
