import React from 'react';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
} from '@mui/material';
import {Control, Controller} from 'react-hook-form';
import {autofillQuestions, strings} from 'settings';
import {Modal} from 'components/molecules/Modal';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

interface AddQuestionModalProps {
  onSubmit: () => void;
  control: Control<any, object>;
  onClose: () => void;
}

export const AddQuestionModal = (props: AddQuestionModalProps) => {
  return (
    <Modal
      onSubmit={props.onSubmit}
      onClose={props.onClose}
      size="small"
      testID="add-question-modal"
      title={strings.QUIZ_MODAL_TITLE}
      icon={<AddCircleOutlineIcon />}
      submitText={strings.QUIZ_ADD_QUESTION}>
      <Stack spacing={2}>
        <FormControl>
          <Controller
            control={props.control}
            name="questionCategory"
            rules={{
              required: strings.QUIZ_MODAL_NO_CATEGORY,
            }}
            render={({field, fieldState}) => (
              <>
                <Autocomplete
                  options={autofillQuestions.map((autoFillQuestion) => {
                    return {
                      label:
                        autoFillQuestion.category +
                        ' - ' +
                        autoFillQuestion.helperText,
                      id: autoFillQuestion.category,
                    };
                  })}
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value.id;
                  }}
                  onChange={(_, value) => field.onChange(value)}
                  renderInput={(params) => (
                    <TextField
                      label={strings.QUIZ_MODAL_CATEGORY}
                      type="text"
                      inputProps={{'data-testid': 'quiz-category-select'}}
                      {...params}
                    />
                  )}
                />
                {fieldState.error && (
                  <FormHelperText error={true}>
                    {fieldState.error.message}
                  </FormHelperText>
                )}
              </>
            )}
          />
        </FormControl>
        <FormControl>
          <Controller
            name="targetVocab"
            control={props.control}
            rules={{
              required: strings.QUIZ_MODAL_TARGET_VOCAB_REQUIRED,
            }}
            render={({field: {onChange, value}, fieldState}) => (
              <>
                <TextField
                  label={strings.QUIZ_MODAL_TARGET_VOCAB}
                  value={value}
                  placeholder={strings.QUIZ_MODAL_TARGET_VOCAB_PLACEHOLDER}
                  onChange={onChange}
                />
                {fieldState.error && (
                  <FormHelperText error={true}>
                    {fieldState.error.message}
                  </FormHelperText>
                )}
              </>
            )}
          />
        </FormControl>
      </Stack>
    </Modal>
  );
};
