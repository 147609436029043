import {QuestionCategory} from 'types';
import {
  getAllAnswersInBrackets,
  getQuoteTypes,
  getSplittedTargetVocabsArray,
  isShortCode,
  removeArrayDuplicates,
} from 'helper';
import {getAutofillTemplateQuestion, strings} from 'settings';
import {
  DataGridQuestion,
  DataGridError,
  QuizCategory,
  QuestionStatus,
  QuestionTrainerStatus,
} from '.';

export const getValidatedQuestion = (
  question: DataGridQuestion,
  quizCategory: QuizCategory,
  oldTargetVocab?: string,
): DataGridQuestion => {
  question = _checkTargetVocab(question, quizCategory, oldTargetVocab);

  if (question.category.startsWith('4')) {
    question.falseAnswers = _generateFalseAnswers(
      question.falseAnswers,
      question.answer,
      question.hint,
    );
    question.hint = generateHint(question.answer, question.falseAnswers);
  }

  return {
    id: question.id,
    lessonId: question.lessonId,
    status: question.status || QuestionStatus.DRAFT,
    trainerStatus: question.trainerStatus || QuestionTrainerStatus.NO_TRAINER,
    count: question.count,
    category: question.category,
    targetVocab: question.targetVocab,
    title: question.title,
    question: question.question,
    questionParts: question.questionParts,
    answers: question.answers,
    answer1: question.answer1,
    answer2: question.answer2,
    answer3: question.answer3,
    answer4: question.answer4,
    answer: question.answer,
    hint: question.hint,
    falseAnswers: question.falseAnswers,
    correctMsg: question.correctMsg,
    incorrectMsg: question.incorrectMsg,
    errors: _getErrors(question),
  };
};

type MatchBy = 'solution' | 'description';

const _checkTargetVocab = (
  question: DataGridQuestion,
  quizCategory: QuizCategory,
  oldTargetVocab?: string,
): DataGridQuestion => {
  if (!question.targetVocab) {
    try {
      question.targetVocab = _generateTargetVocab(question);
    } catch (err) {
      question.targetVocab = '#disabled#';
    }

    return question;
  }

  if (question.targetVocab === '#disabled#') {
    return question;
  }

  return assignTargetVocab(question, quizCategory, oldTargetVocab);
};

const _generateTargetVocab = (question: DataGridQuestion): string => {
  const matching = _getMatchingFieldName(question.category);

  const targetVocab = matching.map((match) =>
    getTargetVocabFromExistingField(
      match.matchBy,
      question[match.matchingFieldName],
    ),
  );

  const cleanedTargetVocabs = targetVocab
    .filter((c, index) => {
      return targetVocab.indexOf(c) === index;
    })
    .join(',');

  return cleanedTargetVocabs;
};

export const assignTargetVocab = (
  question: DataGridQuestion,
  quizCategory: QuizCategory,
  oldTargetVocab?: string,
): DataGridQuestion => {
  const targetVocabs = getSplittedTargetVocabsArray(question.targetVocab);

  for (const [key, defaultValue] of Object.entries(
    getAutofillTemplateQuestion(question.category).defaultValues,
  ) as Array<[string, string]>) {
    if (quizCategory === 'Dialog' && key === 'question') {
      continue;
    }

    if (question[key] === undefined) {
      question[key] = defaultValue;
    }

    const outerValues = defaultValue.split(/{\$\d}/);

    if (outerValues.length <= 1) {
      continue;
    }
    let findReplacement = new RegExp(
      `(${outerValues[0]})(.*?)(${outerValues[1] || ''})`,
    );

    if (outerValues[1].length === 1) {
      findReplacement = new RegExp(
        `(${outerValues[0]})(.*?)(.(?!\\${outerValues[1]}))+$`,
      );
    }

    if (question[key] === '{{$1}}') {
      findReplacement = new RegExp(/({)({\$\d})(})/);
    }

    if (isShortCode(defaultValue) && !isShortCode(question[key])) {
      continue;
    }

    if (isShortCode(question[key]) && isShortCode(defaultValue)) {
      const replacementAttribute = outerValues[0].match(/([a-z]*)=["|']$/)[1];

      const quoteType = getQuoteTypes(replacementAttribute, question[key]);

      findReplacement = new RegExp(
        `(.*?${replacementAttribute}=${quoteType})(.*?)(${quoteType}.*)`,
      );
    }

    const toReplace = question[key].match(findReplacement);

    if (toReplace === null) {
      continue;
    }

    const getReplacementCount = defaultValue.match(/{\$(\d)}/);
    if (!getReplacementCount || !getReplacementCount[1]) {
      continue;
    }

    const replacement = targetVocabs[Number(getReplacementCount[1]) - 1];

    if (key === 'answer' && toReplace[2] !== '{$1}') {
      // custom logic for category 4 answer autoFill
      if (toReplace[2] !== oldTargetVocab) {
        continue;
      }
    }

    question[key] =
      toReplace[1] === ''
        ? replacement
        : question[key].replace(findReplacement || /.*/g, `$1${replacement}$3`);

    question[key] = question[key]
      .replace(/!!$/, '!')
      .replace(/\?\?$/, '?')
      .replace(/\?!$/, '?')
      .replace(/!\?$/, '!');
  }

  if (question.category === '4e') {
    let autoFillable = true;

    if (question.answer) {
      question.answer.split(' ').forEach((item) => {
        if (
          !item.startsWith('{') ||
          item.includes('}!') ||
          item.includes('}?')
        ) {
          autoFillable = false;
        }
      });
    }

    if (autoFillable) {
      const clozeTargetVocabs = question.targetVocab
        .split(' ')
        .map((targetVocab) => targetVocab.trim());

      question.answer = clozeTargetVocabs
        .map((targetVocab) => {
          targetVocab = targetVocab.replace(/\./g, '').replace(/,/g, '');

          let specialChar = '';

          if (targetVocab.includes('!')) {
            specialChar = '!';
            targetVocab = targetVocab.replace('!', '');
          }

          if (targetVocab.includes('?')) {
            specialChar = '?';
            targetVocab = targetVocab.replace('?', '');
          }

          return '{' + targetVocab + '}' + specialChar;
        })
        .join(' ');
    }
  }

  return question;
};

const _getMatchingFieldName = (
  category: QuestionCategory,
): Array<{matchingFieldName: string; matchBy: MatchBy}> => {
  let matching: Array<{matchBy: MatchBy; matchingFieldName: string}> = [];

  const templateQuestion = getAutofillTemplateQuestion(category);

  let relatingFields = ['question', 'answer1', 'answer2', 'answer3', 'answer4'];
  let firstFlag = false;

  relatingFields.forEach((field) => {
    if (
      templateQuestion.defaultValues.hasOwnProperty(field) &&
      templateQuestion.defaultValues[field].includes('{$') &&
      templateQuestion.defaultValues[field].includes('[') &&
      templateQuestion.defaultValues[field].includes(']')
    ) {
      if (templateQuestion.defaultValues[field].match(/{\$(.*?)}/)[1] === '1') {
        if (firstFlag) {
          if (templateQuestion.defaultValues[field].includes('[img')) {
            // do nothing because first result was already correct [qvideo $1]
            return;
          }

          // reset matching[] because first result was [img $1]
          matching = [];
        }
        firstFlag = true;
      }

      let matchBy: MatchBy = 'solution';

      if (
        templateQuestion.defaultValues[field].includes('description="{$1}"') ||
        templateQuestion.defaultValues[field].includes('[img')
      ) {
        matchBy = 'description';
      }

      matching.push({matchBy, matchingFieldName: field});
    }
  });

  return matching;
};

export const getTargetVocabFromExistingField = (
  matchBy: MatchBy,
  field: string,
) => {
  const quoteType = getQuoteTypes(matchBy, field);

  const matches = field.match(
    new RegExp(`${matchBy}=${quoteType}(.*?)${quoteType}`),
  );

  if (!matches) {
    throw new Error(
      `No matches found: getTargetVocabFromExistingField(${matchBy}, ${field})`,
    );
  }

  return matches[1];
};

export const generateHint = (
  answer: string | undefined,
  falseAnswers: string,
): string => {
  const cleanHint = removeArrayDuplicates(
    getAllAnswersInBrackets(answer),
    falseAnswers ? falseAnswers.split(',').map((answer) => answer.trim()) : [],
  );

  return cleanHint.join(', ');
};

const _getErrors = (question: DataGridQuestion): Array<DataGridError> => {
  const errors: Array<DataGridError> = [];

  const templateQuestion = getAutofillTemplateQuestion(question.category);

  templateQuestion.enabledFields.forEach((field) => {
    if (
      templateQuestion.optionalFields &&
      templateQuestion.optionalFields.includes(field)
    ) {
      return;
    }

    if (!question[field]) {
      errors.push({
        field,
        message: strings.FIELD_REQUIRED,
      });
    }
  });

  if (templateQuestion.validationRules) {
    templateQuestion.validationRules.forEach((validationRule) => {
      if (validationRule.pattern) {
        const regex = new RegExp(
          validationRule.pattern.regex,
          validationRule.pattern.flag,
        );
        if (!regex.test(question[validationRule.field])) {
          errors.push({
            field: validationRule.field,
            message: validationRule.pattern.message,
          });
        }
      }
    });
  }

  switch (templateQuestion.type) {
    case 'multiple':
      if (
        !/(^\|1\|\|2$)|(^\|1\|\|2\|\|3$)|(^\|1\|\|2\|\|3\|\|4$)/g.test(
          question.answer,
        )
      ) {
        errors.push({
          field: 'answer',
          message: strings.MC_ANSWER_FORMAT,
        });
      }
      break;

    case 'cloze_answer':
      if (!/.*{.*}.*/g.test(question.answer)) {
        errors.push({
          field: 'answer',
          message: strings.CLOZE_ANSWER_FORMAT,
        });
      }
  }

  return errors;
};

const _generateFalseAnswers = (
  falseAnswers: string,
  answer: string,
  hints?: string,
): string => {
  let allAnswers = getAllAnswersInBrackets(answer);

  if (falseAnswers === undefined && hints) {
    falseAnswers = hints;
  }

  let thisFalseAnswers: Array<string> = [];
  if (falseAnswers) {
    thisFalseAnswers = falseAnswers
      .split(',')
      .map((thisHint) => thisHint.trim())
      .filter((value) => value);
  }

  return thisFalseAnswers
    .filter((value) => !allAnswers.includes(value))
    .join(', ');
};
