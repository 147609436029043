import {forwardRef, useImperativeHandle, useRef} from 'react';
import {
  DropTargetMonitor,
  DragSourceMonitor,
  DragSource,
  DropTarget,
  DropTargetConnector,
  DragSourceConnector,
} from 'react-dnd';
import {
  ItemTypes,
  ReorderItemDragObject,
  ReorderItemProps,
  ReorderItemInstance,
} from '.';
import {XYCoord} from 'dnd-core';
import {Box} from '@mui/system';
import {QuestionPreviewItem} from 'components/molecules/QuestionPreviewItem';

const ReorderItem = forwardRef<HTMLDivElement, ReorderItemProps>(
  function ReorderItem(
    {
      title,
      content,
      count,
      category,
      isDragging,
      firstItem,
      lastItem,
      moveItem,
      connectDragSource,
      connectDropTarget,
    },
    ref,
  ) {
    const elementRef = useRef(null);
    connectDragSource(elementRef);
    connectDropTarget(elementRef);

    const opacity = isDragging ? 0 : 1;
    useImperativeHandle<any, ReorderItemInstance>(ref, () => ({
      getNode: () => elementRef.current,
    }));

    return (
      <Box ref={elementRef} style={{opacity}} data-testid="reorder-item">
        <QuestionPreviewItem
          count={count}
          title={title}
          category={category}
          content={content}
          draggable={true}
          moveItem={moveItem}
          firstItem={firstItem}
          lastItem={lastItem}
        />
      </Box>
    );
  },
);

export default DropTarget(
  ItemTypes.REORDERITEM,
  {
    hover(
      props: ReorderItemProps,
      monitor: DropTargetMonitor,
      component: ReorderItemInstance,
    ) {
      if (!component) {
        return null;
      }
      // node = HTML Div element from imperative API
      const node = component.getNode();
      if (!node) {
        return null;
      }

      const dragIndex = monitor.getItem<ReorderItemDragObject>().index;
      const hoverIndex = props.index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = node.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      props.reorderItem(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      monitor.getItem<ReorderItemDragObject>().index = hoverIndex;
    },
  },
  (connect: DropTargetConnector) => ({
    connectDropTarget: connect.dropTarget(),
  }),
)(
  DragSource(
    ItemTypes.REORDERITEM,
    {
      beginDrag: (props: ReorderItemProps) => ({
        id: props.id,
        index: props.index,
      }),
    },
    (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    }),
  )(ReorderItem),
);
