import {RootStoreModel, RootStore} from './root-store';
import Reactotron from 'reactotron-react-js';
import {persist} from 'mst-persist';
import {config} from 'settings';

export async function setupRootStore() {
  let rootStore: RootStore;

  try {
    const data = JSON.parse(localStorage.getItem('lingvano-store') || '{}');
    rootStore = RootStoreModel.create(data);

    if (window.Cypress && window.location.href.includes('token')) {
      rootStore.authStore.setToken(
        new URL(window.location.href).searchParams.get('token'),
      );
    }
  } catch (e) {
    console.error(e);
    // Fallback to empty state on failure to avoid crash
    rootStore = RootStoreModel.create({});
  }

  persist('lingvano-store', rootStore);

  if (config.environment === 'development') {
    Reactotron?.trackMstNode?.(rootStore);
  }

  return rootStore;
}
