import {QuestionField, QuestionCategory, QuestionPart, Answer} from 'types';

export * from './QuizDetailScreen';

export interface QuizData {
  category?: QuizCategory;
  title?: string;
  url?: string;
  lessonId?: number;
  readOnly?: boolean;
}

export type QuizCategory =
  | 'Develop'
  | 'Dialog'
  | 'Discover'
  | 'Explore'
  | 'Iconic'
  | 'Milestone'
  | 'Review'
  | 'Quiz'
  | 'Training';

/* eslint-disable no-unused-vars */
export enum QuestionStatus {
  PUBLISHED = 'published',
  DRAFT = 'draft',
}

/* eslint-disable no-unused-vars */
export enum QuestionTrainerStatus {
  VOCAB = 'vocab',
  NO_TRAINER = 'noTrainer',
}

export interface DataGridQuestion {
  id: number;
  lessonId: number;
  count: string;
  status: QuestionStatus;
  trainerStatus: QuestionTrainerStatus;
  category: QuestionCategory;
  targetVocab: string;
  title?: string;
  question?: string;
  answers?: Array<Answer>;
  questionParts?: Array<QuestionPart> | string;
  answer1?: string;
  answer2?: string;
  answer3?: string;
  answer4?: string;
  answer?: string;
  hint?: string;
  falseAnswers?: string;
  correctMsg?: string;
  incorrectMsg?: string;
  errors?: Array<DataGridError>;
}

export interface DataGridError {
  field: QuestionField;
  message: string;
}

export interface SaveModalErrorMessage {
  count: string;
  fields: string;
}
