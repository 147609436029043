import React from 'react';
import {styled} from '@mui/material/styles';
import {Divider, Paper, Typography} from '@mui/material';
import {ReactComponent as ManoWelcome} from '../../assets/mano/mano-welcome.svg';

export const WelcomeScreen = () => {
  return (
    <StyledMainFrame>
      <ManoWelcome width="250" />
      <Paper
        elevation={3}
        sx={{mt: 8, p: 4, width: '700px', textAlign: 'center'}}>
        <Typography variant="h1" component="div" gutterBottom>
          Welcome
        </Typography>
        <Typography>
          This is the first version of the Lingvano Content Management System.
          <br />
          It will grow from time to time. In the current version you can{' '}
          <b>edit</b> and <b>create questions</b>.
        </Typography>
        <Divider sx={{mt: 6, mb: 2}}>What to do?</Divider>
        <Typography>
          On the top right, you find a search bar to search for Quizzes.
          <Typography variant="caption" display="block" sx={{mb: 2}}>
            Tip: Use the shortcut <b>CMD + K</b> to open the search with your
            keyboard.
          </Typography>
          The search format to find a quiz is the quizzes shortcode (e.g.
          01_01_1). Alternatively, you can click through the
          module/lesson/quizzes hierarchy inside the search modal.
        </Typography>
      </Paper>
    </StyledMainFrame>
  );
};

const StyledMainFrame = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '32px',
});
