import {ToggleButton, ToggleButtonGroup} from '@mui/material';
import {strings} from 'settings';

interface PersonSwitcherProps {
  person: string;
  testID?: string;
  disabled?: boolean;
  onChange: (value: 'a' | 'b') => void;
}

export const PersonSwitcher = (props: PersonSwitcherProps) => {
  return (
    <ToggleButtonGroup
      disabled={props.disabled}
      color="primary"
      size="small"
      sx={{height: '56px'}}
      value={props.person}
      exclusive
      onChange={(_, value) => {
        if (value === null) {
          return;
        }

        props.onChange(value);
      }}
      data-testid={props.testID}>
      <ToggleButton value="a">{strings.PERSON} A</ToggleButton>
      <ToggleButton value="b">{strings.PERSON} B</ToggleButton>
    </ToggleButtonGroup>
  );
};
