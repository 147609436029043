export const login = {
  success: {
    success: true,
    code: 200,
    response: {
      token: 'validToken',
    },
  },
  fail: {
    success: false,
    code: 401,
    message: 'wrong password',
  },
};
