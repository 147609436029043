export const getSplittedTargetVocabsArray = (targtVocab: string): Array<string> => {
  return targtVocab
    .split(',')
    .map((currentTargetVocab) => currentTargetVocab.trim());
};

export const getAllAnswersInBrackets = (answerString?: string): Array<string> => {
  if (!answerString) {
    return [];
  }

  let allAnswers = answerString.match(/{.*?}/g);

  if (!allAnswers) {
    return [];
  }

  allAnswers = allAnswers.map((answer) => {
    answer = answer.replace(/{|}/g, '').trim();

    if (answer.startsWith('[')) {
      answer = answer.match(/^\[(.*?)\]/)[1];
    }

    return answer;
  });

  return allAnswers;
};

export const extractIdFromPlaceholder = (value: string): number => {
  return Number(value.replace('${', '').replace('}', ''));
};
