import * as React from 'react';
import {TextField} from '@mui/material';
import {Modal} from 'components/molecules/Modal';
import {strings} from 'settings';
import FindReplaceIcon from '@mui/icons-material/FindReplace';

interface ReplaceModalProps {
  onSubmit: (newValue: string) => void;
  onClose: () => void;
  oldValue: string;
}

export const ReplaceModal = (props: ReplaceModalProps) => {
  const newValueRef = React.useRef<HTMLInputElement>(null);

  return (
    <Modal
      onSubmit={() => props.onSubmit(newValueRef?.current?.value || '')}
      onClose={props.onClose}
      size="small"
      testID="search-and-replace-modal"
      title={strings.SEARCH_AND_REPLACE.TITLE}
      icon={<FindReplaceIcon />}
      submitText={strings.SEARCH_AND_REPLACE.REPLACE}
      loading={false}>
      <div>
        <TextField
          label={strings.SEARCH_AND_REPLACE.OLD_VALUE}
          id="outlined-basic"
          variant="filled"
          disabled={true}
          value={props.oldValue}
          placeholder={strings.QUIZ_SEARCH.PLACEHOLDER}
          sx={{mr: 2}}
        />
        <TextField
          label={strings.SEARCH_AND_REPLACE.NEW_VALUE}
          id="outlined-basic"
          variant="filled"
          aria-label="replacing-text"
          inputRef={newValueRef}
          placeholder={strings.QUIZ_SEARCH.PLACEHOLDER}
          sx={{}}
        />
      </div>
    </Modal>
  );
};
