import axios, {AxiosInstance, AxiosPromise, Method} from 'axios';
import axiosRetry, {isNetworkOrIdempotentRequestError} from 'axios-retry';
import {
  ApiException,
  NetworkError,
  ResourceNotFound,
  UnauthorizedApiRequest,
} from 'settings/exceptions';

export class JsonApiService {
  private readonly _client: AxiosInstance;

  constructor(baseURL: string, token: string | null) {
    this._client = axios.create({
      baseURL,
      timeout: process.env.JEST_WORKER_ID ? 3000 : 30_000,
      headers: {
        'X-Build-Number': 'cms',
        ...(token ? {Authorization: `Bearer ${token}`} : undefined),
      },
    });
    axiosRetry(this._client, {
      retries: 2,
      retryDelay: axiosRetry.exponentialDelay,
      shouldResetTimeout: true,
      retryCondition: (e) => {
        if (e.message === 'Network Error' || e.code === 'ECONNABORTED') {
          return true;
        }

        return isNetworkOrIdempotentRequestError(e);
      },
    });
  }

  get = <T>(url: string) => this._request<T>('GET', url);
  post = <T>(url: string, data?: unknown) =>
    this._request<T>('POST', url, data);
  put = <T>(url: string, data?: unknown) => this._request<T>('PUT', url, data);
  patch = <T>(url: string, data?: unknown) =>
    this._request<T>('PATCH', url, data);
  delete = <T>(url: string) => this._request<T>('DELETE', url);

  private _request<Response>(
    method: Method,
    url: string,
    data: unknown = undefined,
  ): AxiosPromise<Response> {
    return this._client({method, url, data}).catch((e) => {
      console.log(e);
      switch (e.response?.status) {
        case 401:
          throw new UnauthorizedApiRequest(e);
        case 404:
          throw new ResourceNotFound(e);
        default:
          if (e.message === 'Network Error' || e.code === 'ECONNABORTED') {
            throw new NetworkError(e);
          } else {
            throw new ApiException(e);
          }
      }
    });
  }
}
