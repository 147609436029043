export const accessibilityLabels = {
  QUIZ: {
    QUICK_ACTIONS: 'Quick Actions',
    VIEW_QUESTION: 'View Question',
  },
  TPP: {
    CLOSE: 'Close TPP',
    PREV: 'TPP previous slide',
    NEXT: 'TPP next slide',
  },
};
