import * as React from 'react';
import {
  Box,
  CircularProgress,
  Grow,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import {SearchResult} from 'components/organisms/QuizDataGrid';
import {strings} from 'settings';

interface SearchBoxProps {
  searchBoxOpen: boolean;
  onChange: (e) => void;
  onKeyDown: (e) => void;
  onBlur: (e) => void;
  onLoadingStatusChange: (e) => void;
  onCaseSensitivityStatusChange: () => void;
  onModalClose: () => void;
  onOpenFilter: () => void;
  searchbox: React.RefObject<HTMLInputElement>;
  caseSensitivityStatus: boolean;
  isLoading: boolean;
  activeSearchValue: number;
  searchResults: Array<SearchResult>;
  errorState: string;
}

export const SearchBox = (props: SearchBoxProps) => {
  return (
    <Grow
      in={props.searchBoxOpen}
      style={{transformOrigin: '0 0 0'}}
      exit={true}>
      <TextField
        hiddenLabel
        id="outlined-basic"
        variant="filled"
        inputRef={props.searchbox}
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
        onBlur={props.onBlur}
        helperText={props.errorState}
        InputProps={{
          endAdornment: (
            <>
              {!props.isLoading && (
                <Tooltip
                  title={
                    props.caseSensitivityStatus
                      ? 'Ignore case (match all results)'
                      : 'Activate case sensitivity'
                  }>
                  <IconButton
                    sx={{mr: 2}}
                    aria-label="case-sensitivity-toggle"
                    onClick={() => {
                      if (props.searchbox?.current?.value?.length) {
                        props.onLoadingStatusChange(true);
                      }
                      props.onCaseSensitivityStatusChange();
                    }}
                    onBlur={(e) => {
                      if (
                        !e.relatedTarget &&
                        !props.searchbox?.current?.value?.length
                      ) {
                        props.onModalClose();
                      }
                    }}
                    color={
                      props.caseSensitivityStatus ? 'secondary' : 'default'
                    }>
                    <span style={{fontSize: '14px'}}>aA</span>
                  </IconButton>
                </Tooltip>
              )}
              {props.isLoading && (
                <Box sx={{display: 'flex'}}>
                  <CircularProgress
                    size={25}
                    data-testid="search-loading-animation"
                  />
                </Box>
              )}
              {!props.isLoading && props.searchbox?.current?.value && (
                <>
                  <InputAdornment
                    data-testid="result-counter"
                    position="start">{`${props.activeSearchValue} of ${props.searchResults.length}`}</InputAdornment>
                  {props.searchResults.length > 0 && (
                    <IconButton
                      aria-label="advanced-options-button"
                      onClick={() => {
                        props.onOpenFilter();
                      }}>
                      <MoreVertIcon />
                    </IconButton>
                  )}
                </>
              )}
              <IconButton
                aria-label="close-searchbox"
                onClick={() => {
                  props.onModalClose();
                }}>
                <CloseIcon />
              </IconButton>
            </>
          ),
        }}
        placeholder={strings.QUIZ_SEARCH.PLACEHOLDER}
        sx={{
          minWidth: 290,
          zIndex: 20,
          position: 'absolute',
          top: 0,
          right: '24px',
          backgroundColor: 'white',
          border: '1px solid gray',
        }}
      />
    </Grow>
  );
};
