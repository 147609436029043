import {QuestionField} from '../types';

const columnNames = (isDialog: boolean): {} => {
  return {
    select: 1,
    count: 2,
    status: 3,
    trainerStatus: 4,
    category: 5,
    targetVocab: 6,
    title: isDialog ? 6 : 7,
    question: isDialog ? 7 : 8,
    answer1: isDialog ? 8 : 9,
    answer2: isDialog ? 9 : 10,
    answer3: isDialog ? 10 : 11,
    answer4: isDialog ? 11 : 12,
    answer: isDialog ? 12 : 13,
    hint: isDialog ? 13 : 14,
    correctMsg: isDialog ? 14 : 15,
    incorrectMsg: isDialog ? 15 : 16,
  };
};

export const getColumnNr = (
  columnName: QuestionField | 'select',
  quizCategory,
) => {
  return columnNames(quizCategory === 'Dialog')[columnName] || 0;
};
