interface Config {
  environment: string;
  api: string;
  sentry: {
    dsn: string;
    sampleRate: number;
    release?: string;
  };
}

const config: Config = {
  environment: process.env.REACT_APP_ENV || 'development',
  api:
    process.env.REACT_APP_ENV === 'production'
      ? 'production'
      : process.env.REACT_APP_API || 'development',
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN || '',
    sampleRate: Number(process.env.REACT_APP_SENTRY_SAMPLE_RATE) || 1,
    release: process.env.REACT_APP_SENTRY_RELEASE,
  },
};

export default config;
