import {
  Backdrop,
  SpeedDial as MuiSpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from '@mui/material';
import React, {useState, ReactElement} from 'react';

interface SpeedDialProps {
  actions: Array<{icon: ReactElement; name: string; action: () => void}>;
  ariaLabel?: string;
}

export const SpeedDial = ({actions, ariaLabel}: SpeedDialProps) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Backdrop
        open={open}
        sx={{
          backdropFilter: 'blur(2px)',
          backgroundColor: 'rgba(111, 126, 140, 0.2)',
          zIndex: 1100,
        }}
      />
      <MuiSpeedDial
        ariaLabel={ariaLabel}
        sx={{position: 'fixed', bottom: 32, right: 32, zIndex: 1100}}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}>
        {open &&
          actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={() => {
                handleClose();
                action.action();
              }}
            />
          ))}
      </MuiSpeedDial>
    </>
  );
};
