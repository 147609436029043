/* eslint-disable react-hooks/exhaustive-deps */
import {
  Backdrop,
  Box,
  Button,
  Chip,
  Fade,
  Grid,
  Modal,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, {useState, useEffect, createRef, RefObject} from 'react';
import {SearchModalProps, SearchModalResultSections} from './';
import {getContentResultFormat} from './SearchModal.service';
import SearchIcon from '@mui/icons-material/Search';
import {styled, alpha} from '@mui/material/styles';
import {ShortcutLabel} from 'components/molecules/ShortcutLabel';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import CollectionsBookmarkOutlinedIcon from '@mui/icons-material/CollectionsBookmarkOutlined';
import FeaturedVideoOutlinedIcon from '@mui/icons-material/FeaturedVideoOutlined';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import {useNavigate, useLocation} from 'react-router-dom';
import {useStores} from 'store/root-store';
import {useStateWithGetter} from 'hooks/useStateWithGetter';
import {SearchModalSkeleton} from './SearchModalSkeleton';
import {UnauthorizedApiRequest} from 'settings/exceptions';
import * as Sentry from '@sentry/react';

export const SearchModal = ({title}: SearchModalProps) => {
  const modalTransitionTimeout = 500;
  const searchStringInputRef: RefObject<HTMLInputElement> = createRef();
  const _navigate = useNavigate();
  const _location = useLocation();

  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>();
  const [currentSearchString, setCurrentSearchString] = useState('');
  const [lastResultSearchString, setLastResultSearchString] = useState('');
  const [contentData, setContentData] = useState();

  const {authStore} = useStores();

  const [activeResultIndex, setActiveResultIndex, getActiveResultIndex] =
    useStateWithGetter(0);
  const [numberOfResults, setNumberOfResults, getNumberOfResults] =
    useStateWithGetter(0);
  const [results, setResults, getResults] = useStateWithGetter<
    Array<SearchModalResultSections>
  >([]);

  const _handleModalClose = () => {
    setModalOpen(false);

    setTimeout(() => {
      setCurrentSearchString('');
      setActiveResultIndex(0);
    }, modalTransitionTimeout);
  };

  const _handleModalOpen = () => {
    setModalOpen(true);
  };

  const _handleSettingResults = (results: Array<SearchModalResultSections>) => {
    let counter = 0;

    results.forEach((section) => {
      section.results.forEach(() => (counter = counter + 1));
    });

    setNumberOfResults(counter);
    setResults(results);
  };

  const _searchStringInputBlur = () => {
    if (searchStringInputRef.current) {
      searchStringInputRef.current.focus();
    }
  };

  const _searchStringInputChange = (event) => {
    setCurrentSearchString(event.target.value);
  };

  const _resultItemSubmit = () => {
    let counter = 0;
    const currentResults = getResults();
    const currentResultIndex = getActiveResultIndex();

    currentResults.forEach((section) => {
      section.results.forEach((result) => {
        if (currentResultIndex === counter) {
          if (result.url) {
            setLastResultSearchString(result.titleCode || '');
            _handleModalClose();
            _navigate('/app/' + authStore.language + '/' + result.url, {
              replace: true,
            });
          } else {
            setCurrentSearchString(result.titleCode || '');
            setActiveResultIndex(0);
          }
        }

        counter = counter + 1;
      });
    });
  };

  const _keyDownListener = (event) => {
    if (event.keyCode === 75 && event.metaKey) {
      // CMD + K
      // TODO: works on mac, check for windows! (metakex)
      _handleModalOpen();
    }

    if (!modalOpen) {
      return;
    }

    if (event.keyCode === 40) {
      // arrowDown
      setActiveResultIndex((activeResultIndex) => {
        let newVal = activeResultIndex + 1;

        if (newVal >= getNumberOfResults()) {
          newVal = 0;
        }

        return newVal;
      });
    }

    if (event.keyCode === 38) {
      // arrowUp
      event.preventDefault();

      setActiveResultIndex((activeResultIndex) => {
        let newVal = activeResultIndex - 1;

        if (newVal < 0) {
          newVal = getNumberOfResults() - 1;
        }

        return newVal;
      });
    }

    if (event.keyCode === 13) {
      // enter
      _resultItemSubmit();
    }
  };

  useEffect(() => {
    if (contentData) {
      setLoading(true);

      const results = getContentResultFormat(currentSearchString, contentData);
      _handleSettingResults(results);

      setLoading(false);
    }
  }, [currentSearchString]);

  const _fetchContentData = async () => {
    setLoading(true);

    try {
      return await authStore.apiGetSearchContent();
    } catch (e) {
      if (e instanceof UnauthorizedApiRequest) {
        authStore.logout(_navigate, _location);
      } else {
        Sentry.captureException(e);
      }

      return {error: e};
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (modalOpen) {
      _fetchContentData().then((contentData) => {
        if (contentData.error) {
          setLoading(false);
          setError(true);
        }

        if (isMounted && !contentData.error) {
          setError(false);
          setContentData(contentData);

          let results: Array<SearchModalResultSections> = [];
          results = getContentResultFormat('', contentData);

          if (lastResultSearchString) {
            const preselect = lastResultSearchString.substring(0, 5);

            setCurrentSearchString(preselect);
            results = getContentResultFormat(preselect, contentData);
          }

          _handleSettingResults(results);
          setLoading(false);
        }
      });
    }

    document.addEventListener('keydown', _keyDownListener, false);

    return () => {
      document.removeEventListener('keydown', _keyDownListener, false);
      isMounted = false;
    };
  }, [modalOpen]);

  const _renderSearchStringInput = () => {
    return (
      <Box p={3} sx={searchStringInputStyle}>
        <TextField
          autoFocus
          value={currentSearchString}
          onChange={_searchStringInputChange}
          onBlur={_searchStringInputBlur}
          inputRef={searchStringInputRef}
          label="Search via title code format"
          sx={{width: '100%'}}
        />
      </Box>
    );
  };

  const _renderError = () => {
    return (
      <Stack alignItems="center" py={4}>
        <ReportGmailerrorredIcon sx={{width: '100px', height: '100px'}} />
        <Typography my={2} variant="h3">
          Oh no, a problem
        </Typography>
        <Typography mb={4} fontSize="14px" textAlign="center">
          There was a problem trying to search for all modules, lessons and
          quizzes.
          <br />
          Try to reload the page.
        </Typography>
        <Button
          onClick={() => {
            window.location.reload();
          }}
          variant="outlined">
          Reload
        </Button>
      </Stack>
    );
  };

  const _renderResults = () => {
    let count = -1;

    return (
      <Box p={3} pt={1}>
        {loading && <SearchModalSkeleton />}
        {error && _renderError()}
        {!loading && !error && (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center">
              <Chip
                label={`found ${numberOfResults} results`}
                size="small"
                sx={{mr: 'auto'}}
              />
              <Stack direction="row" spacing={1}>
                <Tooltip title="Navigate through the results with the Arrow Down Key">
                  <Box>
                    <ShortcutLabel dark={true} icon={<ArrowDropDownIcon />} />
                  </Box>
                </Tooltip>
                <Tooltip title="Navigate through the results with the Arrow Up Key">
                  <Box>
                    <ShortcutLabel dark={true} icon={<ArrowDropUpIcon />} />
                  </Box>
                </Tooltip>
                <Tooltip title="Choose an item with the Enter button">
                  <Box>
                    <ShortcutLabel
                      dark={true}
                      icon={
                        <KeyboardReturnIcon sx={{width: '24px', px: '4px'}} />
                      }
                    />
                  </Box>
                </Tooltip>
              </Stack>
            </Stack>
            {results &&
              results.map((section, i) => {
                return (
                  <Box key={i}>
                    <Typography
                      sx={{
                        mt: 3,
                        mb: 1,
                        fontSize: '13px',
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        color: 'rgb(62, 80, 96)',
                      }}>
                      {section.headline}
                    </Typography>
                    <Grid container>
                      {section.results.map((result, resIndex) => {
                        count = count + 1;
                        let thisCounter = count;
                        return (
                          <Grid
                            item
                            xs={12}
                            key={`search-result-item-${count}`}>
                            <Box
                              sx={_resultItemStyle(
                                activeResultIndex === count,
                                resIndex === section.results.length - 1,
                                resIndex === 0,
                              )}
                              data-testid="search-result-item"
                              data-result-active={activeResultIndex === count}
                              onMouseEnter={() =>
                                setActiveResultIndex(thisCounter)
                              }
                              onClick={() => _resultItemSubmit()}>
                              {_getResultItemIcon(result.type)}
                              <Stack direction="column">
                                <Typography
                                  fontSize={'14px'}
                                  lineHeight={'14px'}>
                                  {result.title}
                                </Typography>
                                <Typography
                                  fontSize={'10px'}
                                  lineHeight={'10px'}>
                                  {result.titleCode}
                                </Typography>
                              </Stack>
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                );
              })}
            {results.length === 0 && (
              <Stack alignItems="center" py={4}>
                <SentimentDissatisfiedIcon
                  sx={{width: '100px', height: '100px'}}
                />
                <Typography my={2} variant="h3">
                  Oh noooo!
                </Typography>
                <Typography mb={4} fontSize="14px" textAlign="center">
                  We could not find any results to your search term.
                  <br />
                  Try another title code.
                </Typography>
                <Button
                  onClick={() => {
                    setCurrentSearchString('');
                  }}
                  variant="outlined">
                  Reset search
                </Button>
              </Stack>
            )}
          </>
        )}
      </Box>
    );
  };

  const _getResultItemIcon = (type: string) => {
    const svgStyle = {
      fill: 'rgb(62, 80, 96)',
      mr: 2,
    };
    switch (type) {
      case 'module':
        return <CollectionsBookmarkOutlinedIcon sx={svgStyle} />;
      case 'lesson':
        return <LibraryBooksOutlinedIcon sx={svgStyle} />;
      case 'quiz':
        return <QuizOutlinedIcon sx={svgStyle} />;
      case 'question':
        return <FeaturedVideoOutlinedIcon sx={svgStyle} />;
    }
  };

  return (
    <div>
      <Search id="search-modal-trigger" onClick={_handleModalOpen}>
        <SearchIconWrapper>
          <SearchIcon sx={{fill: 'white'}} />
        </SearchIconWrapper>
        <SearchTitle>{title}</SearchTitle>
        <ShortcutLabelWrapper>
          <ShortcutLabel meta={true} shortcut={'K'} />
        </ShortcutLabelWrapper>
      </Search>

      <Modal
        open={modalOpen}
        onClose={_handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: modalTransitionTimeout,
          sx: {
            backdropFilter: 'blur(2px)',
            backgroundColor: 'rgba(111, 126, 140, 0.2)',
          },
        }}
        aria-labelledby="search-modal-trigger"
        aria-describedby="search-modal-trigger"
        data-testid="search-modal">
        <Fade in={modalOpen}>
          <Stack sx={modalStyle}>
            {_renderSearchStringInput()}
            {_renderResults()}
          </Stack>
        </Fade>
      </Modal>
    </div>
  );
};

const searchStringInputStyle = {
  position: 'sticky' as 'sticky',
  top: 0,
  backgroundColor: 'white',
  borderBottom: '1px solid #E0E0E0',
  zIndex: 2,
};

const modalStyle = {
  position: 'fixed' as 'fixed',
  top: '80px',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '90%',
  maxWidth: 700,
  maxHeight: 'calc(100% - 160px)',
  overflow: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: '0px 0px 40px 10px rgb(0 0 0 / 15%)',
};

const _resultItemStyle = (active: boolean, last: boolean, first: boolean) => {
  return {
    backgroundColor: active ? alpha('#ffa338', 0.15) : 'white',
    borderRadius: '8px',
    padding: '16px 24px',
    border: active ? '1px solid #ffa338' : '1px solid transparent',
    position: 'relative' as 'relative',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&::after': {
      content: active || last ? '' : '""',
      position: 'absolute',
      bottom: '-3px',
      left: '5px',
      right: '5px',
      height: '1px',
      backgroundColor: '#E0E0E0',
      zIndex: 1,
      pointerEvents: 'none',
    },
    '&::before': {
      content: active && !first ? '""' : '',
      position: 'absolute',
      top: '-1px',
      left: '5px',
      right: '5px',
      height: '1px',
      backgroundColor: '#ffa338',
      zIndex: 1,
      pointerEvents: 'none',
    },
  };
};

const Search = styled('div')(({theme}) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.08),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.15),
  },
  cursor: 'text',
  marginRight: '16px',
}));

const SearchIconWrapper = styled('div')(({theme}) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const SearchTitle = styled('div')(({theme}) => ({
  color: 'white',
  padding: theme.spacing(1, 1, 1, 0),
  paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  paddingRight: `calc(1em + ${theme.spacing(4)})`,
  width: '350px',
  opacity: 0.8,
  fontFamily: 'Roboto',
}));

const ShortcutLabelWrapper = styled('div')({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  right: '8px',
});
