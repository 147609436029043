import escapeRegExp from 'lodash/escapeRegExp';
import uniqueId from 'lodash/uniqueId';

export const isShortCode = (input: string): boolean => {
  return /\[([a-z-]+)\s.*?]/gims.test(input);
};

export const getShortcodeProperty = (
  property: string,
  shortcode: string,
  optional = false,
) => {
  const quoteType = escapeRegExp(getQuoteTypes(property, shortcode));
  const regex = new RegExp(`${property}=${quoteType}(.*?)${quoteType}`);
  const found = shortcode.match(regex);

  if (found && found.length > 0 && found[1].length > 0) {
    return found[1];
  }

  // workaround for values with same quotes as attribute tag
  if (found && found.length > 0 && found[0].includes('""')) {
    const regexDoubleQuote = new RegExp(
      `${property}=${quoteType}${quoteType}(.*?)${quoteType}${quoteType}`,
    );
    const foundDoubleQuote = shortcode.match(regexDoubleQuote);

    if (
      foundDoubleQuote &&
      foundDoubleQuote.length > 0 &&
      foundDoubleQuote[1].length > 0 &&
      foundDoubleQuote[1].charAt(0) !== ' '
    ) {
      return foundDoubleQuote[1];
    }
  }

  return optional ? '' : '!!';
};

export const getQuoteTypes = (matchBy: string, value: string) => {
  const matches = value.match(new RegExp(`${matchBy}=(.)`));

  if (!matches || !matches[1]) {
    return '';
  }

  return matches[1];
};

export const getDialogsShortcodeProperties = (dialogs: string) => {
  const foundShortcodes = dialogs.match(/\[lv-dialog.+?]/g);

  if (!foundShortcodes) {
    return [];
  }

  return foundShortcodes.map((shortcode) => {
    return {
      id: Number(getShortcodeProperty('id', shortcode, true)),
      subtitle: getShortcodeProperty('subtitle', shortcode, true),
      person: getShortcodeProperty('person', shortcode, true) as 'a' | 'b',
      key: uniqueId('dialog-') as string,
    };
  });
};
