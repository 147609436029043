import {asanaTasks, asanaTaskCreated, asanaTaskFailed} from './asana';
import {errors} from './errors';
import * as getQuestionCategories from './questionCategories.json';
import {getSearchContent} from './searchContent';
import {lesson} from './lesson';
import {login} from './login';
import {
  questionEditSuccess,
  questionsOrderSuccess,
  deleteQuestionSuccess,
} from './questions';
import {
  quizDialog,
  quizDiscover,
  quizTrainer,
  quizDynamic,
  quizEmpty,
  quizTpp,
  quizUnknownFormat,
} from './quiz';
import {
  teleprompter,
  teleprompterDialog,
  teleprompterPersonB,
} from './teleprompter';

const responses = {
  asanaTasks,
  asanaTaskCreated,
  asanaTaskFailed,
  errors,
  getQuestionCategories,
  getSearchContent,
  lesson,
  login,
  questionEditSuccess,
  questionsOrderSuccess,
  deleteQuestionSuccess,
  quizDialog,
  quizDiscover,
  quizDynamic,
  quizEmpty,
  quizTrainer,
  quizTpp,
  quizUnknownFormat,
  teleprompter,
  teleprompterDialog,
  teleprompterPersonB,
};
export default responses;
