import {ConnectDropTarget, ConnectDragSource} from 'react-dnd';

export interface ReorderItemsModalProps {
  items: Array<ReorderItem>;
  onSubmit: (reorderItems: Array<ReorderItem>) => void;
  onClose: () => void;
}

export interface ReorderItem {
  id: number;
  title: string;
  content?: string;
  count: string;
  category?: string;
}

export interface ReorderItemProps {
  id: any;
  title: string;
  content?: string;
  count?: string;
  category?: string;
  index: number;
  firstItem?: boolean;
  lastItem?: boolean;
  reorderItem: (dragIndex: number, hoverIndex: number) => void;
  moveItem: (direction: 'up' | 'down') => void;

  isDragging: boolean;
  connectDragSource: ConnectDragSource;
  connectDropTarget: ConnectDropTarget;
}

interface ReorderItemInstance {
  getNode: () => HTMLDivElement | null;
}

export const ItemTypes = {
  REORDERITEM: 'reorderitem',
};

export interface ReorderItemDragObject {
  id: string;
  index: number;
}
