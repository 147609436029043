// import {SideBar} from 'components/organisms/SideBar';
import {TopBar} from 'components/organisms/TopBar';
import {Outlet} from 'react-router-dom';
import {SnackbarProvider} from 'notistack';
import {styled} from '@mui/material';

export const AppScreen = () => {
  return (
    <>
      <SnackbarProvider dense>
        <TopBar />
        {/* <SideBar /> */}
        <StyledMainFrame>
          <Outlet />
        </StyledMainFrame>
      </SnackbarProvider>
    </>
  );
};

const StyledMainFrame = styled('div')({
  // TODO: has to be enabled once we start rendering the <Sidebar /> again
  // paddingLeft: '150px',
});
