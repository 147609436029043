import {apiRoutes} from 'api/apiRoutes';
import {convertQuizQuestions} from 'api/apiHelpers';
import {JsonApiService} from 'api/JsonApiService';
import {types, Instance} from 'mobx-state-tree';
import {
  GetQuizResponse,
  NewAsanaTaskPayload,
  AsanaTask,
  GetLessonResponse,
  Languages,
  GetTeleprompterResponse,
  TeleprompterMode,
} from 'types/api';
import {NavigateFunction, Location} from 'react-router-dom';
import config from 'settings/config';

export const AuthStoreModel = types
  .model('AuthStore')
  .props({
    token: types.maybeNull(types.string),
    status: types.optional(
      types.enumeration(['idle', 'pending', 'done', 'error']),
      'idle',
    ),
    language: types.optional(
      types.enumeration<Languages>('SignLanguage', ['asl', 'bsl', 'oegs']),
      'asl',
    ),
  })

  .views((self) => ({
    get api() {
      return new JsonApiService(
        config.api === 'production' || config.api === 'development'
          ? `https://api-${self.language}.lingvano.com/api/`
          : config.api,
        self.token,
      );
    },
  }))

  .actions((self) => ({
    setToken(token: string | null) {
      self.token = token;
    },
    setStatus(value: 'idle' | 'pending' | 'done' | 'error') {
      self.status = value;
    },
    setLanguage(language: Languages) {
      self.language = language;
    },
  }))

  .actions((self) => ({
    async apiGetQuiz(quizId: string): Promise<GetQuizResponse> {
      const response = await self.api.get<{response: GetQuizResponse}>(
        apiRoutes.quiz + quizId + '?editorView=true',
      );

      return {
        title: response.data.response.title,
        lessonId: response.data.response.lessonId,
        category: response.data.response.category,
        url: response.data.response.url,
        questions: convertQuizQuestions(response.data.response.questions),
      };
    },
    async apiGetTeleprompter(data: {
      mode: TeleprompterMode;
      chapterId: number;
    }): Promise<GetTeleprompterResponse> {
      const response = await self.api.get<GetTeleprompterResponse>(
        `${apiRoutes.teleprompter}?chapterIds=${data.chapterId}&mode=${data.mode}`,
      );

      return response.data;
    },
    async apiPostQuestions(questions, quizId: string): Promise<any> {
      const response = await self.api.post<{
        success: boolean;
        response: {editedQuestions: Array<number>};
      }>(`${apiRoutes.quiz}${quizId}/${apiRoutes.questions}`, {questions});

      return {
        success: true,
        editedQuestions: response.data.response.editedQuestions,
      };
    },
    async apiPostQuestionsOrder(newOrder, quizId: string): Promise<any> {
      const response = await self.api.post<{
        success: boolean;
        response: {editedQuestions: Array<number>};
      }>(
        `${apiRoutes.quiz}${quizId}/${apiRoutes.questions}${apiRoutes.order}`,
        {
          order: newOrder,
        },
      );

      return {
        success: true,
        editedQuestions: response.data.response.editedQuestions,
      };
    },
    async apiDeleteQuestion(quizId: string, questionId: number): Promise<any> {
      const response = await self.api.delete<{
        success: boolean;
        response: {deletedQuestion: number};
      }>(`${apiRoutes.quiz}${quizId}/${apiRoutes.questions}${questionId}`);

      return {
        success: true,
        deletedQuestion: response.data.response.deletedQuestion,
      };
    },
    async apiPostAutofillVideos(
      quizId: string,
    ): Promise<{editedQuestions: Array<number>}> {
      const response = await self.api.post<{
        success: boolean;
        response: {editedQuestions: Array<number>};
      }>(`${apiRoutes.quiz}${quizId}/${apiRoutes.autofillVideos}`);

      return response.data.response;
    },
    async apiGetLesson(lessonId: number): Promise<GetLessonResponse> {
      const response = await self.api.get<{response: GetLessonResponse}>(
        apiRoutes.lesson + lessonId + '?editorView=true',
      );

      const data = response.data.response;
      return {
        id: data.id,
        title: data.title,
        subtitle: data.subtitle,
      };
    },
    async apiGetSearchContent(): Promise<any> {
      const response = await self.api.get<{response: any}>(
        apiRoutes.searchContent,
      );

      return response.data.response;
    },
    async apiCreateAsanaTask(data: NewAsanaTaskPayload) {
      const response = await self.api.post<{
        success: true;
        response: AsanaTask;
      }>(`${apiRoutes.asana}/tasks`, data);

      return response.data.response;
    },
    async apiGetAsanaTasks(quizId: string): Promise<Array<AsanaTask>> {
      const response = await self.api.get<{
        success: boolean;
        response: Array<AsanaTask>;
      }>(`${apiRoutes.asana}/tasks/${quizId}`);

      return response.data.response;
    },
  }))

  .actions((self) => ({
    logout(navigate: NavigateFunction, originUrl?: Location) {
      self.setToken(null);
      navigate('/login', {state: {from: originUrl}});
    },
  }))

  .actions((self) => ({
    doLogin: async (email: string, password: string) => {
      const response = await self.api.post<{response: {token: string}}>(
        apiRoutes.login,
        {
          email,
          password,
        },
      );

      self.setToken(response.data.response.token);
    },
  }));

export interface AuthStore extends Instance<typeof AuthStoreModel> {}
