import {styled} from '@mui/material/styles';
import {Switch, Tooltip} from '@mui/material';
import {ChangeEvent} from 'react';

interface Props {
  checked: boolean;
  onStateChange: (e: ChangeEvent<HTMLInputElement>, checked) => void;
  title: string;
  testId?: string;
  disabled?: boolean;
}

export const SwitchButton = (props: Props) => {
  return (
    <StyledSwitchWrapper data-testid={props.testId || ''}>
      <Tooltip title={props.title}>
        <Switch
          disabled={props.disabled}
          size="small"
          checked={props.checked}
          onChange={props.onStateChange}
          inputProps={{'aria-label': 'controlled'}}
        />
      </Tooltip>
    </StyledSwitchWrapper>
  );
};

const StyledSwitchWrapper = styled('div')({
  marginRight: '-8px',
});
