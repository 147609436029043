import {apiRoutes} from 'api/apiRoutes';
import {sleep} from 'helper/sleep';
import cloneDeep from 'lodash/cloneDeep';
import responses from './responses';
import {rest, RestRequest} from 'msw';
import config from 'settings/config';

const baseUrl =
  config.api === 'production' || config.api === 'development'
    ? 'https://api-:platform.lingvano.com/api/'
    : config.api;

export const database = {
  questions: [...responses.quizDynamic.response.questions] as Array<any>,
};

export const handlers = [
  rest.get('/favicon.ico', (req) => req.passthrough()),
  rest.post(baseUrl + apiRoutes.login, async (req, res, ctx) => {
    const body: any = await req.json();
    if (body.password === 'wrongPassword') {
      return res(ctx.status(401), ctx.json(responses.login.fail));
    }
    return res(ctx.status(200), ctx.json(responses.login.success));
  }),

  rest.get(baseUrl + apiRoutes.searchContent, (req, res, ctx) => {
    if (!_isAuthorized(req)) {
      return res(ctx.status(401), ctx.json(responses.errors.unauthorized));
    }

    let response = cloneDeep(responses.getSearchContent);
    if (req.params.platform !== 'asl') {
      response = JSON.parse(
        JSON.stringify(responses.getSearchContent).replace(
          /Module/g,
          `Module ${req.params.platform}`,
        ),
      );
    }

    return res(ctx.status(200), ctx.json(response));
  }),

  rest.get(`${baseUrl}${apiRoutes.quiz}:id`, (req, res, ctx) => {
    if (!_isAuthorized(req)) {
      return res(ctx.status(401), ctx.json(responses.errors.unauthorized));
    }

    if (req.params.id === '404') {
      return res(ctx.status(404), ctx.json(responses.errors.notFound));
    }

    if (
      req.params.id === 'dialog' ||
      req.params.id === '8095' ||
      req.params.id === '43744'
    ) {
      return res(ctx.status(200), ctx.json(responses.quizDialog));
    }

    if (req.params.id === 'empty' || req.params.id === '8097') {
      return res(ctx.status(200), ctx.json(responses.quizEmpty));
    }

    if (req.params.id === '8999') {
      return res(ctx.status(200), ctx.json(responses.quizTpp));
    }

    if (req.params.id === 'dynamic') {
      const _updatedDynamicQuiz = _getUpdatedDynamicQuiz(database.questions);
      return res(ctx.status(200), ctx.json(_updatedDynamicQuiz));
    }

    if (req.params.id === 'unknownFormat') {
      return res(ctx.status(200), ctx.json(responses.quizUnknownFormat));
    }

    if (req.params.id === 'vocab_trainer_asl') {
      return res(ctx.status(200), ctx.json(responses.quizTrainer));
    }

    return res(ctx.status(200), ctx.json(responses.quizDiscover));
  }),

  rest.get(`${baseUrl}${apiRoutes.teleprompter}`, (req, res, ctx) => {
    if (!_isAuthorized(req)) {
      return res(ctx.status(401), ctx.json(responses.errors.unauthorized));
    }

    if (req.url.searchParams.get('chapterIds') === 'unknown') {
      return res(ctx.status(404), ctx.json(responses.errors.notFound));
    }

    if (req.url.searchParams.get('mode') === 'dialog') {
      return res(ctx.status(200), ctx.json(responses.teleprompterDialog));
    } else if (req.url.searchParams.get('mode') === 'person-b') {
      return res(ctx.status(200), ctx.json(responses.teleprompterPersonB));
    } else {
      return res(ctx.status(200), ctx.json(responses.teleprompter));
    }
  }),

  rest.post(
    `${baseUrl}${apiRoutes.quiz}:id/${apiRoutes.questions}`,
    async (req, res, ctx) => {
      if (!_isAuthorized(req)) {
        return res(ctx.status(401), ctx.json(responses.errors.unauthorized));
      }

      const uniqueCounts: Array<string> = [];

      const body: any = await req.json();
      body.questions.forEach((question) => {
        if (uniqueCounts.includes(question.count)) {
          throw new Error('Duplicate count');
        }
        uniqueCounts.push(question.count);
      });

      return res(ctx.status(200), ctx.json(responses.questionEditSuccess));
    },
  ),

  rest.post(
    `${baseUrl}${apiRoutes.quiz}:id/${apiRoutes.autofillVideos}`,
    (req, res, ctx) => {
      if (!_isAuthorized(req)) {
        return res(ctx.status(401), ctx.json(responses.errors.unauthorized));
      }

      database.questions[1] = {
        ...responses.quizDynamic.response.questions[1],
        question: '[qvideo id="1234" solution="HELLO"]',
      };

      return res(
        ctx.status(200),
        ctx.json({success: true, editedQuestions: [1234]}),
      );
    },
  ),

  rest.post(
    baseUrl + apiRoutes.quiz + ':id/' + apiRoutes.questions + apiRoutes.order,
    (req, res, ctx) => {
      if (!_isAuthorized(req)) {
        return res(ctx.status(401), ctx.json(responses.errors.unauthorized));
      }
      return res(ctx.status(200), ctx.json(responses.questionsOrderSuccess));
    },
  ),

  rest.delete(
    baseUrl + apiRoutes.quiz + ':id/' + apiRoutes.questions + ':questionId',
    (req, res, ctx) => {
      if (!_isAuthorized(req)) {
        return res(ctx.status(401), ctx.json(responses.errors.unauthorized));
      }
      return res(ctx.status(200), ctx.json(responses.deleteQuestionSuccess));
    },
  ),

  rest.get(`${baseUrl}${apiRoutes.lesson}:id`, (req, res, ctx) => {
    if (!_isAuthorized(req)) {
      return res(ctx.status(401), ctx.json(responses.errors.unauthorized));
    }

    return res(ctx.status(200), ctx.json(responses.lesson));
  }),

  rest.post(`${baseUrl}${apiRoutes.asana}/tasks`, async (req, res, ctx) => {
    if (!_isAuthorized(req)) {
      return res(ctx.status(401), ctx.json(responses.errors.unauthorized));
    }

    const body: any = await req.json();
    let response = res(ctx.status(200), ctx.json(responses.asanaTaskCreated));
    if (body.title.toLowerCase().includes('fail')) {
      response = res(ctx.status(400), ctx.json(responses.asanaTaskFailed));
    }

    // simulate delay in the Asana API to see loader
    if (body.title.toLowerCase().includes('delay')) {
      await sleep(3000);
    }

    return response;
  }),

  rest.get(`${baseUrl}${apiRoutes.asana}/tasks/:quizId`, (req, res, ctx) => {
    if (!_isAuthorized(req)) {
      return res(ctx.status(401), ctx.json(responses.errors.unauthorized));
    }

    const response = cloneDeep(responses.asanaTasks);
    response.response = response.response.filter(
      (t) => t.quizId === req.params.quizId,
    );
    return res(ctx.status(200), ctx.json(response));
  }),
];

const _getUpdatedDynamicQuiz = (updatedQuestions) => {
  return {
    success: true,
    response: {
      id: responses.quizDynamic.response.id,
      title: responses.quizDynamic.response.title,
      url: responses.quizDynamic.response.url,
      category: responses.quizDynamic.response.category,
      questions: updatedQuestions,
    },
  };
};

const _getToken = (req: RestRequest) => {
  return (req.headers.get('Authorization') ?? '').replace(/^Bearer\s+/, '');
};

const _isAuthorized = (req: RestRequest) => {
  if (_getToken(req) === 'invalid') {
    return false;
  }
  return _getToken(req) !== '';
};
