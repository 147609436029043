import {Box, Skeleton} from '@mui/material';

export const LessonSkeleton = () => {
  const skeletonStyle = {
    transform: 'scale(1)',
    marginBottom: '8px',
    marginTop: '8px',
  };
  return (
    <Box maxWidth="md" sx={{marginY: 4, marginX: 'auto'}}>
      <Skeleton
        variant="text"
        animation="wave"
        width="80%"
        height={32}
        sx={skeletonStyle}
      />
      <Skeleton
        variant="text"
        animation="wave"
        width="60%"
        height={16}
        sx={skeletonStyle}
      />

      <Box height={32} />

      <Skeleton
        variant="text"
        animation="wave"
        width="50%"
        height={192}
        sx={skeletonStyle}
      />
    </Box>
  );
};
