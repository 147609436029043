export const asanaUsers = {
  success: true,
  response: {
    users: [
      {
        gid: '1160894679374311',
        name: 'Elizabeth',
      },
      {
        gid: '1200891286612484',
        name: 'Dejan',
      },
      {
        gid: '1203655548620918',
        name: 'Johanna',
      },
      {
        gid: '1158172384677794',
        name: 'Rafaela',
      },
      {
        gid: '1149555044647056',
        name: 'Matthias',
      },
      {
        gid: '1149556515643882',
        name: 'Nico',
      },
      {
        gid: '1192094581392400',
        name: 'Milena',
      },
      {
        gid: '1201599597875903',
        name: 'Daniel',
      },
      {
        gid: '1201991891898617',
        name: 'Mišo',
      },
      {
        gid: '1201092825983158',
        name: 'Christof',
      },
      {
        gid: '1202363174631986',
        name: 'Niklas',
      },
      {
        gid: '1203655416283418',
        name: 'Lisa',
      },
      {
        gid: '1202217874507492',
        name: 'Genie',
      },
      {
        gid: '1200270734628366',
        name: 'Rosie',
      },
    ],
  },
};
