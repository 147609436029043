export const asanaTaskCreated = {
  success: true,
  response: {
    id: '0123456789',
    assignee: 'Miso Belica',
    title: 'Mock title',
    description: 'This task was created by Mock from CMS.',
    dueDate: '2100-04-01',
    quizId: 8074,
    questionId: 1,
    column: 'question',
  },
};

export const asanaTaskFailed = {
  success: false,
  response: {},
};

export const asanaTasks = {
  success: true,
  response: [
    {
      id: '1202051426397063',
      quizId: 'filled',
      questionId: 65433,
      column: 'targetVocab',
      assigneeId: '123',
      title: 'Title of mock task from Asana',
      description: '',
      dueDate: null,
    },
    {
      id: '1201758824487411',
      quizId: 'filled',
      questionId: 65433,
      column: 'targetVocab',
      assigneeId: '123',
      title: '2nd title of mock task from Asana',
      description: '',
      dueDate: null,
    },
    {
      id: '1202007074614069',
      quizId: 'filled',
      questionId: 65435,
      column: 'question',
      assigneeId: '123',
      title: 'Please check this cell for bugs',
      description: '',
      dueDate: null,
    },
  ],
};
