import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import {Box, Modal, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import {styled} from '@mui/material/styles';
import {accessibilityLabels, strings} from 'settings';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import React from 'react';
import {useSnackbar} from 'notistack';
import debounce from 'lodash/debounce';

interface TeleprompterModalProps {
  isOpen: boolean;
  onClose: () => void;
  slides: Array<string>;
}

export const TeleprompterModal = (props: TeleprompterModalProps) => {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [fontSize, setFontSize] = React.useState(80);
  const {enqueueSnackbar} = useSnackbar();

  const _onCopyText = () => {
    navigator.clipboard.writeText(props.slides[currentIndex].trim());

    enqueueSnackbar(`Copied: "${props.slides[currentIndex]}"`, {
      variant: 'info',
      autoHideDuration: 1500,
    });
  };

  const _changeSlideOnArrowPress = (event) => {
    // arrowRight
    if (event.keyCode === 39) {
      if (currentIndex === props.slides.length - 1) {
        return;
      }
      setCurrentIndex(currentIndex + 1);
    }

    // arrowLeft
    if (event.keyCode === 37) {
      if (currentIndex === 0) {
        return;
      }
      setCurrentIndex(currentIndex - 1);
    }
  };

  React.useEffect(() => {
    setCurrentIndex(0);
  }, [props.slides]);

  React.useEffect(() => {
    if (!props.slides[currentIndex]) {
      return;
    }

    const currentWordCount = props.slides[currentIndex].length;
    setFontSize(_getScaledFontSize(currentWordCount));
  }, [currentIndex, props.slides]);

  React.useEffect(() => {
    document.addEventListener('keydown', _changeSlideOnArrowPress, false);

    return () => {
      document.removeEventListener('keydown', _changeSlideOnArrowPress, false);
    };
  }, [currentIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!props.isOpen) {
    return null;
  }

  return (
    <Modal open={props.isOpen} onClose={props.onClose}>
      <Box
        style={{
          width: '100vw',
          height: '100vh',
          background: '#000',
          display: 'flex',
          justifyContent: 'center',
        }}>
        <Button
          variant="outlined"
          endIcon={<ContentCopyIcon />}
          sx={{position: 'absolute', top: 10, right: 72}}
          onClick={debounce(_onCopyText, 180)}>
          {strings.TPP.COPY}
        </Button>
        <IconButton
          color={'primary'}
          aria-label={accessibilityLabels.TPP.CLOSE}
          onClick={props.onClose}
          sx={{background: '#fff', position: 'absolute', top: 8, right: 8}}>
          <CloseIcon />
        </IconButton>
        <Typography
          sx={{
            alignSelf: 'center',
            paddingY: 4,
            paddingX: 8,
            textAlign: 'center',
            lineHeight: 1.1,
          }}
          fontSize={fontSize + 'vw'}
          color={
            /^DB/.test(props.slides[currentIndex]) ||
            /B:/.test(props.slides[currentIndex])
              ? '#faff00'
              : '#fff'
          }
          onClick={debounce(_onCopyText, 180)}>
          {props.slides[currentIndex]}
        </Typography>
        <StyledIconButton
          disabled={currentIndex === 0}
          aria-label={accessibilityLabels.TPP.PREV}
          onClick={() => setCurrentIndex(currentIndex - 1)}
          style={{right: 'auto', left: '8px'}}>
          <ArrowBackIosIcon />
        </StyledIconButton>
        <StyledIconButton
          disabled={currentIndex === props.slides.length - 1}
          aria-label={accessibilityLabels.TPP.NEXT}
          onClick={() => setCurrentIndex(currentIndex + 1)}
          style={{right: '8px', left: 'auto'}}>
          <ArrowForwardIosIcon />
        </StyledIconButton>
        <Typography
          sx={{fontSize: '2vw', position: 'absolute', left: 8, top: 8}}
          color={'#fff'}>
          {currentIndex + 1} / {props.slides.length}
        </Typography>
      </Box>
    </Modal>
  );
};

const StyledIconButton = styled(IconButton)({
  background: '#fff',
  position: 'absolute',
  top: '50%',
  marginTop: '-15px',

  '&:hover': {
    backgroundColor: 'rgb(246 249 252 / 60%)',
  },
});

const _getScaledFontSize = (charactersCount: number) => {
  if (charactersCount > 120) {
    return 5.5;
  } else if (charactersCount > 65) {
    return 7.5;
  } else if (charactersCount > 50) {
    return 8;
  } else if (charactersCount > 35) {
    return 9;
  }

  return 12;
};
