export const strings = {
  ASANA: {
    CREATE: 'Create Task',
    ASSIGNEE: 'Assignee',
    TITLE: 'Task Title',
    DESCRIPTION: 'Description',
    DUE_DATE: 'Due date',
    NEW_TASK_SUCCESS: 'Asana task was successfully created',
    NEW_TASK_EDIT: 'Edit task on Asana',
    NEW_TASK_FAIL: 'Creating Asana task failed',
    INCOMPLETE_TASKS_TITLE: 'Incomplete Asana tasks related to this cell',
  },
  SEARCH_AND_REPLACE: {
    TITLE: 'Search and Replace',
    REPLACE: 'Replace all',
    OLD_VALUE: 'Old value',
    NEW_VALUE: 'New value',
    ERROR: 'Please remove special characters',
  },
  ANSWER_1_TRUE:
    'This question category requires answer1 "True" or "Yes" and answer2 "False" or "No"',
  CLOZE_ANSWER_FORMAT: 'Answer needs to be "{TYPE THIS} HELLO"',
  FIELD_REQUIRED: 'You need to fill this field!',
  MC_ANSWER_FORMAT: 'Answer needs to be "|1||2", "|1||2||3" or |1||2||3||4"',
  VIDEO_SHORTCODE_REQUIRED:
    'This question category requires this field to contain a video!',
  IMAGE_SHORTCODE_REQUIRED:
    'This question category requires this field to contain a image!',
  QUIZ_OPEN: 'Open Quiz',
  QUIZ_AUTOFILL_VIDEOS: {
    ENABLED: 'Autofill video IDs',
    DISABLED: 'Please save all open changes before autofilling video IDs',
  },
  QUIZ_EXPORT_TPP: 'Export TPP',
  QUIZ_COPY_URL: 'Copy current URL',
  QUIZ_ADD_QUESTION: 'Add question',
  QUIZ_ADD_FIRST_QUESTION: 'Add first question',
  QUIZ_ADD_QUESTION_BLOCK: 'Add question block',
  QUIZ_ADD_QUESTION_BLOCK_TITLE: 'Add a new question block',
  QUIZ_QUESTION_BLOCK_MODAL_SELECT: 'Question block type',
  QUIZ_QUESTION_BLOCK_MODAL_SELECT_ERROR: 'Please select a question block type',
  QUIZ_QUESTION_BLOCK_MODAL_INFO:
    'Select a question block type first and then fill all needed target vocabs.',
  QUIZ_LOADING: 'Quiz is loading ...',
  QUIZ_FAILED: 'Something went wrong while loading the quiz',
  QUIZ_SAVE_FAILED: 'Something went wrong while saving the quiz',
  QUIZ_ERROR_RELOAD: 'Contact technical support and try to reload!',
  QUIZ_ERROR_RELOAD_BUTTON: 'Reload',
  QUIZ_MODAL_CATEGORY: 'Question category',
  QUIZ_MODAL_NO_CATEGORY: 'Please select a category',
  QUIZ_MODAL_TARGET_VOCAB: 'Target vocab',
  QUIZ_MODAL_TARGET_VOCAB_REQUIRED: 'Please add a target vocab',
  QUIZ_MODAL_TARGET_VOCAB_PLACEHOLDER: 'HELLO, CIAO e.g. or HELLO',
  QUIZ_MODAL_NEW_QUESTION: 'New Question',
  QUIZ_MODAL_TITLE: 'Add a new question',
  QUIZ_NO_QUESTIONS:
    'It looks like this quiz has no questions so far. Want to add a new one?',
  QUIZ_OPEN_QUESTION_DISABLED: 'Save the Quiz to view this question!',
  QUIZ_OPEN_QUESTION: 'Open Question',
  VIDEO_REQUIRED: 'This field requires a video [qvideo id="" solution=""]',
  QUIZ_SAVE: 'Save',
  QUIZ_CHANGE: 'Change',
  QUIZ_CANCEL: 'Cancel',
  QUIZ_REVERT: 'Revert',
  QUIZ_REVERT_INFO: "This will revert all changes that haven't been saved yet!",
  QUIZ_CHANGES_HEADLINE: 'Check changes',
  QUIZ_CHANGES_SAVE_DISABLED:
    'Your Quiz has still some errors. Check all questions and update the fields with an error.',
  QUIZ_CHANGES_ERROR_NOTE: 'Check these questions and fields:',
  QUIZ_CHANGES:
    'Before you proceed to save the updated quiz please check all your changes.',
  QUIZ_CHANGES_NOTICE:
    'All changes you save will be instantly available to all our users!',
  QUIZ_ERRORS: {
    UNKOWN_QUESTION_FORMAT: {
      INFO: 'Please create an Asana task for the developer team, so they can add this question format.',
    },
  },
  QUIZ_SEARCH: {
    PLACEHOLDER: 'Search text',
  },
  ORDER_MODAL_HEADLINE: 'Change order',
  ORDER_MODAL_NOTICE:
    'All changes you make to the quizzes order will be stored immediately as you click the save button',
  ORDER_MODAL_DISABLED:
    'Please save all open changes before changing the order!',
  DELETE: 'Delete',
  DELETE_QUESTION_MODAL_HEADLINE: 'Delete question',
  DELETE_QUESTION_MODAL_DISABLED:
    'Please save all open changes before deleting a question!',
  DELETE_QUESTION_MODAL_CHECK: 'You are deleting the following question:',
  DELETE_QUESTION_MODAL_NOTICE:
    'Are you sure you want to proceed? Deleting this question cannot be undone and will be saved immediately!',
  PERSON: 'PERSON',
  TPP: {
    GENERATE: 'Generate TPP presentation',
    LABEL: 'Generate TPP for:',
    GENERATE_DIALOG: 'Dialog (Person A and B)',
    GENERATE_PERSONA: 'Quizzes (Person A)',
    GENERATE_PERSONB: 'Quizzes (Person B)',
    LOADING: 'TPP is being generated ...',
    OPEN: 'Open TPP',
    COPY: 'Copy',
  },
  VIDEO_MODAL_HEADLINE: 'Video Details',
  VIDEO_MODAL_TARGET_VOCAB_HINT: 'This field is controlled by the target vocab',
  VIDEO_MODAL_LINK_TOOLTIP: 'Open Search in CMS',
  VIDEO_MODAL_COPY_TOOLTIP: 'Copy to clipboard',
  DIALOG_MODAL_HEADLINE: 'Edit Dialog',
  DIALOG_MODAL_ADD_DIALOG: 'Add Dialog',
  DIALOG_MODAL_DELETE_DIALOG: 'Delete Dialog',
  DIALOG_MODAL_CREATE_DIALOG: 'Create',
};
