import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import {
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  Stack,
  SvgIcon,
  Tooltip,
} from '@mui/material';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import {AccountCircle, Logout} from '@mui/icons-material';
import {useStores} from 'store/root-store';
import {SearchModal} from '../SearchModal';
import {Languages} from 'types/api';
import {observer} from 'mobx-react-lite';
import {ReactComponent as FlagAsl} from 'assets/languages/flag-asl.svg';
import {ReactComponent as FlagBsl} from 'assets/languages/flag-bsl.svg';
import {ReactComponent as FlagOegs} from 'assets/languages/flag-oegs.svg';
import {NavLink} from 'components/molecules/NavLink';
import {useNavigate} from 'react-router-dom';

export const TopBar = observer(() => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const {authStore} = useStores();
  const _navigate = useNavigate();

  const _openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const _handleClose = () => {
    setAnchorEl(null);
  };
  const _handleLogout = () => {
    authStore.setToken(null);
    _navigate('/login', {replace: true});
  };

  const _onSelectChange = (event: SelectChangeEvent) => {
    authStore.setLanguage(event.target.value as Languages);
    _navigate('/app', {replace: true});
  };

  const _getFlag = (language: Languages) => {
    if (language === 'bsl') {
      return <FlagBsl />;
    } else if (language === 'oegs') {
      return <FlagOegs />;
    } else {
      return <FlagAsl />;
    }
  };

  const _renderLanguageOptions = () => {
    return (['asl', 'bsl', 'oegs'] as Array<Languages>).map((language) => {
      return (
        <MenuItem value={language} key={language}>
          <div style={{display: 'flex'}}>
            <SvgIcon sx={{width: '24px', height: '24px', mr: 1}}>
              {_getFlag(language)}
            </SvgIcon>
            {'  '}
            <span>{language.toUpperCase().replace('OE', 'Ö')}</span>
          </div>
        </MenuItem>
      );
    });
  };

  return (
    <AppBar position="sticky" color="primary">
      <Stack
        direction="row"
        width="100%"
        padding="0 15px"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
        pr={{xs: 1, xl: 2}}
        minHeight={52}
        ml={'auto'}>
        <Box display="flex" gap={1} width={'500px'} marginRight={'10vw'}>
          <NavLink
            href={`/app/${authStore.language}/quizzes/vocab_trainer_${authStore.language}`}
            text="Vocab Trainer"
          />
          <NavLink
            href={`/app/${authStore.language}/quizzes/numbers_trainer_${authStore.language}`}
            text="Number Trainer"
          />
          <NavLink
            href={`/app/${authStore.language}/quizzes/fingerspelling_trainer_${authStore.language}`}
            text="Fingerspelling Trainer"
          />
        </Box>
        <SearchModal title={'Search Quizzes and Questions'} />
        <FormControl variant="standard" sx={{minWidth: 120}}>
          <Select value={authStore.language} onChange={_onSelectChange}>
            {_renderLanguageOptions()}
          </Select>
        </FormControl>
        <Tooltip title="Account settings">
          <IconButton
            data-testid="open-topbar-menu"
            size="small"
            onClick={_openMenu}>
            <AccountCircle
              sx={{color: 'white', height: '36px', width: '36px'}}
            />
          </IconButton>
        </Tooltip>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{horizontal: 'right', vertical: 'top'}}
          anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={_handleClose}>
          <MenuItem onClick={_handleLogout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Stack>
    </AppBar>
  );
});
