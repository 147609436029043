import {styled} from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import {IconButton, Tooltip} from '@mui/material';

interface DeleteButtonProps {
  onTriggerDelete: () => void;
  title?: string;
}

export const DeleteButton = (props: DeleteButtonProps) => {
  return (
    <StyledDeleteWrapper>
      <Tooltip title={props.title ? props.title : 'Delete question'}>
        <IconButton aria-label="delete" onClick={props.onTriggerDelete}>
          <DeleteIcon fontSize="small" htmlColor="#DEDEDE" />
        </IconButton>
      </Tooltip>
    </StyledDeleteWrapper>
  );
};

const StyledDeleteWrapper = styled('div')({
  marginRight: '-8px',
  button: {
    '&:hover': {
      '*': {
        fill: '#CD3D32',
      },
    },
  },
});
