import {Instance, types} from 'mobx-state-tree';
import {AuthStoreModel} from 'store/auth-store/';
import {QuizStoreModel} from 'store/quiz-store/';

export const RootStoreModel = types.model('RootStore').props({
  authStore: types.optional(AuthStoreModel, {}),
  quizStore: types.optional(QuizStoreModel, {}),
});

export interface RootStore extends Instance<typeof RootStoreModel> {}
