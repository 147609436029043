export const apiRoutes = {
  asana: 'asana',
  autofillVideos: 'autofillVideos',
  lesson: 'lessons/',
  login: 'auth/jwt',
  order: 'order/',
  questions: 'questions/',
  quiz: 'quizzes/',
  searchContent: 'search/content',
  teleprompter: 'teleprompter',
};
