import React from 'react';
import {styled} from '@mui/material/styles';
import {Stack, Typography} from '@mui/material';
import {DataGridFieldChipProps} from './';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export const DataGridFieldChip = (props: DataGridFieldChipProps) => {
  return (
    <StyledFieldChip
      onClick={props.onClick}
      color={
        props.backgroundColor ? props.backgroundColor : 'rgba(0, 0, 0, 0.08)'
      }
      data-testid={props.testId}>
      <Stack
        alignItems="center"
        direction="row"
        spacing={0.5}
        height="100%"
        sx={{whiteSpace: 'nowrap'}}>
        {props.icon}
        {props.fields.map((field, i) => {
          if (!field.value) {
            return null;
          }

          return (
            <StyledFieldChipValue key={i}>
              <Typography
                sx={{
                  margin: 0,
                  fontSize: '8px',
                  textTransform: 'uppercase',
                  lineHeight: 1,
                  color: '#616161',
                }}>
                {field.title}:
              </Typography>
              {(field.value === '!!' && (
                <ErrorOutlineIcon
                  sx={{height: '14px', width: '14px', fill: '#B00020'}}
                />
              )) || (
                <Typography
                  sx={{
                    margin: 0,
                    fontSize: '14px',
                    lineHeight: 1,
                    fontWeight: field.style === 'bold' ? 'bold' : 'normal',
                    fontStyle: field.style === 'italic' ? 'italic' : 'normal',
                  }}>
                  {field.value}
                </Typography>
              )}
            </StyledFieldChipValue>
          );
        })}
      </Stack>
    </StyledFieldChip>
  );
};

const StyledFieldChip = styled('div')(({color}) => ({
  height: '32px',
  background: color,
  borderRadius: '8px',
  paddingRight: '4px',
  margin: '1px 0',
}));

const StyledFieldChipValue = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  border: '1px solid #ccc',
  borderRadius: '4px',
  padding: '2px 4px',
});
