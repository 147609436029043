import React from 'react';

export const useStateWithGetter = <T,>(
  initialState: T,
): [T, React.Dispatch<React.SetStateAction<T>>, () => T] => {
  const [state, setState] = React.useState(initialState);

  const stateRef = React.useRef(state);

  React.useEffect(() => {
    stateRef.current = state;
  });

  const getState = React.useCallback(() => stateRef.current, []);

  return [state, setState, getState];
};
