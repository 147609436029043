import {UnknownQuestionFormat} from './exceptions';
import {QuestionField, QuestionType, QuestionCategory} from 'types';
import {strings} from './strings';

interface PatternValidation {
  regex: string;
  message: string;
  flag?: string;
}

interface ValidationRule {
  field: QuestionField;
  pattern?: PatternValidation;
  custom?: 'no-video' | 'no-image';
}

interface AutoFillQuestion {
  category: QuestionCategory;
  helperText: string;
  type: QuestionType;
  defaultValues: {
    [key: string]: string;
  };
  enabledFields: Array<QuestionField>;
  optionalFields?: Array<QuestionField>;
  validationRules?: Array<ValidationRule>;
}

type AutoFillQuestions = Array<AutoFillQuestion>;

export const autofillQuestions: AutoFillQuestions = [
  {
    category: '1a',
    helperText: 'vocab/sentence',
    type: 'content',
    defaultValues: {
      title: '{$1}',
      question: '[qvideo id="" description="{$1}"]',
    },
    enabledFields: ['answer', 'question', 'targetVocab'],
    optionalFields: ['answer'],
  },
  {
    category: '1c',
    helperText: 'content info',
    type: 'content',
    defaultValues: {
      title: 'The importance of ...',
      question: '[qvideo id="" solution="{$1}"]',
    },
    enabledFields: ['answer', 'targetVocab', 'title', 'question'],
    optionalFields: ['answer', 'question', 'title', 'targetVocab'],
  },
  {
    category: '2a',
    helperText: 'yes/no - true/false',
    type: 'single',
    defaultValues: {
      title: 'True or False:',
      question: '[qvideo id="" solution="{$1}"]',
      answer1: 'True',
      answer2: 'False',
      answer: '1',
    },
    enabledFields: [
      'targetVocab',
      'title',
      'question',
      'answer1',
      'answer2',
      'correctMsg',
      'incorrectMsg',
    ],
    optionalFields: ['question', 'correctMsg', 'incorrectMsg'],
    validationRules: [
      {
        field: 'answer1',
        pattern: {
          regex: '^True$|^Yes$|^False$|^No$|^Falsch$|^Richtig$',
          message: strings.ANSWER_1_TRUE,
          flag: 'i',
        },
      },
      {
        field: 'answer2',
        pattern: {
          regex: '^True$|^Yes$|^False$|^No$|^Falsch$|^Richtig$',
          message: strings.ANSWER_1_TRUE,
          flag: 'i',
        },
      },
    ],
  },
  {
    category: '2b',
    helperText: 'choose answer - sign question = sign answer',
    type: 'single',
    defaultValues: {
      title: 'Choose a suitable answer!',
      question: '[qvideo id="" solution="{$1}"]',
      answer1: '[qvideo id="" solution="{$2}"]',
      answer2: '[qvideo id="" solution="{$3}"]',
      answer: '1',
    },
    enabledFields: [
      'targetVocab',
      'question',
      'answer1',
      'answer2',
      'correctMsg',
      'incorrectMsg',
    ],
    optionalFields: ['correctMsg', 'incorrectMsg'],
  },
  {
    category: '2c',
    helperText: 'correct sentence -  long text in GLOSSEN',
    type: 'single',
    defaultValues: {
      title: 'Choose the correct sentence!',
      question: '[qvideo id="" solution="{$1}"]',
      answer1: '{$1}',
      answer2: 'GLOSS',
      answer: '1',
    },
    enabledFields: [
      'targetVocab',
      'question',
      'answer1',
      'answer2',
      'answer3',
      'answer4',
      'correctMsg',
      'incorrectMsg',
    ],
    optionalFields: ['answer3', 'answer4', 'correctMsg', 'incorrectMsg'],
  },
  {
    category: '2d',
    helperText: 'correct sign - 2/4 GLOSS (Dialog)',
    type: 'single',
    defaultValues: {
      title: 'Choose the correct sign!',
      question: '[qvideo id="" solution="{$1}"]',
      answer1: '{$1}',
      answer2: 'GLOSS',
      answer: '1',
    },
    enabledFields: [
      'targetVocab',
      'question',
      'answer1',
      'answer2',
      'answer3',
      'answer4',
    ],
    optionalFields: ['answer3', 'answer4'],
  },
  {
    category: '2f',
    helperText: 'sentence - pictures',
    type: 'single',
    defaultValues: {
      title: 'Choose the correct picture!',
      question: '[qvideo id="" solution="{$1}"]',
      answer1: '[img id=""]',
      answer2: '[img id=""]',
      answer: '1',
    },
    enabledFields: [
      'targetVocab',
      'question',
      'answer1',
      'answer2',
      'correctMsg',
      'incorrectMsg',
    ],
    optionalFields: ['correctMsg', 'incorrectMsg'],
  },
  {
    category: '2g',
    helperText: 'sign - pictures',
    type: 'single',
    defaultValues: {
      title: 'Choose the correct picture!',
      question: '[qvideo id="" solution="{$1}"]',
      answer1: '[img id="" description="{$1}"]',
      answer2: '[img id=""]',
      answer: '1',
    },
    enabledFields: [
      'targetVocab',
      'question',
      'answer1',
      'answer2',
      'answer3',
      'answer4',
      'correctMsg',
      'incorrectMsg',
    ],
    optionalFields: ['correctMsg', 'incorrectMsg', 'answer3', 'answer4'],
  },
  {
    category: '2j',
    helperText: 'picture - video descriptions',
    type: 'single',
    defaultValues: {
      title: 'Choose the correct video!',
      question: '[img id="" description="{$1}"]',
      answer1: '[qvideo id="" solution="{$1}"]',
      answer2: '[qvideo id="" solution="{$2}"]',
      answer: '1',
    },
    enabledFields: [
      'targetVocab',
      'question',
      'answer1',
      'answer2',
      'correctMsg',
      'incorrectMsg',
    ],
    optionalFields: ['correctMsg', 'incorrectMsg'],
  },
  {
    category: '2l',
    helperText: 'choose video sign',
    type: 'single',
    defaultValues: {
      title: 'Choose the sign {$1}!',
      answer1: '[qvideo id="" solution="{$1}"]',
      answer2: '[qvideo id="" solution="{$2}"]',
      answer: '1',
    },
    enabledFields: [
      'targetVocab',
      'answer1',
      'answer2',
      'correctMsg',
      'incorrectMsg',
    ],
    optionalFields: ['correctMsg', 'incorrectMsg'],
  },
  {
    category: '2m',
    helperText: 'sc correct statement in ENGLISH',
    type: 'single',
    defaultValues: {
      title: 'Choose the correct statement!',
      question: '[qvideo id="" solution="{$1}"]',
      answer1: 'textblock',
      answer2: 'textblock',
      answer: '1',
      correctMsg: '"English."',
      incorrectMsg: '"English."',
    },
    enabledFields: [
      'question',
      'targetVocab',
      'title',
      'answer1',
      'answer2',
      'answer3',
      'answer4',
      'correctMsg',
      'incorrectMsg',
    ],
    optionalFields: [
      'question',
      'answer3',
      'answer4',
      'correctMsg',
      'incorrectMsg',
    ],
  },
  {
    category: '2n',
    helperText: 'choose video sentence',
    type: 'single',
    defaultValues: {
      title: 'Choose the sentence {$1}!',
      answer1: '[qvideo id="" solution="{$1}"]',
      answer2: '[qvideo id="" solution="{$2}"]',
      answer: '1',
      correctMsg: '"English."',
      incorrectMsg: '"English."',
    },
    enabledFields: [
      'targetVocab',
      'answer1',
      'answer2',
      'correctMsg',
      'incorrectMsg',
    ],
    optionalFields: ['correctMsg', 'incorrectMsg'],
  },
  {
    category: '2o',
    helperText: 'image - signed sentence',
    type: 'single',
    defaultValues: {
      title: 'Which video matches the image?',
      question: '[img id="" description="{$1}"]',
      answer1: '[qvideo id="" solution="{$1}"]',
      answer2: '[qvideo id="" solution=""]',
      answer: '1',
      correctMsg: '"English."',
      incorrectMsg: '"English."',
    },
    enabledFields: [
      'targetVocab',
      'question',
      'answer1',
      'answer2',
      'correctMsg',
      'incorrectMsg',
    ],
    optionalFields: ['correctMsg', 'incorrectMsg'],
  },
  {
    category: '2p',
    helperText: 'signed sentence - image',
    type: 'single',
    defaultValues: {
      title: 'Which image matches the video statement?',
      question: '[qvideo id="" solution="{$1}"]',
      answer1: '[img id="" solution=""]',
      answer2: '[img id="" solution=""]',
      answer: '1',
      correctMsg: '"English."',
      incorrectMsg: '"English."',
    },
    enabledFields: [
      'targetVocab',
      'question',
      'answer1',
      'answer2',
      'answer3',
      'answer4',
      'correctMsg',
      'incorrectMsg',
    ],
    optionalFields: ['correctMsg', 'incorrectMsg', 'answer3', 'answer4'],
  },
  {
    category: '2q',
    type: 'single',
    helperText: 'choose video sign grammar',
    defaultValues: {
      answer1: '[qvideo id="" description="{$1}"]',
      answer2: '[qvideo id="" description=""]',
      answer: '1',
    },
    enabledFields: [
      'targetVocab',
      'title',
      'answer1',
      'answer2',
      'correctMsg',
      'incorrectMsg',
    ],
    optionalFields: ['correctMsg', 'incorrectMsg'],
  },
  {
    category: '3a',
    helperText: 'signs GLOSS in video',
    type: 'multiple',
    defaultValues: {
      title: 'Which signs are in the video?',
      question: '[qvideo id="" solution="{$1}"]',
      answer1: 'GLOSS',
      answer2: 'GLOSS',
      answer3: 'GLOSS',
      answer4: 'GLOSS',
      answer: '|1||2||3',
      correctMsg: '"English."',
      incorrectMsg: '"English."',
    },
    enabledFields: [
      'targetVocab',
      'question',
      'answer1',
      'answer2',
      'answer3',
      'answer4',
      'answer',
      'correctMsg',
      'incorrectMsg',
    ],
    optionalFields: ['correctMsg', 'incorrectMsg'],
  },
  {
    category: '3b',
    helperText: 'sign - pictures mc',
    type: 'multiple',
    defaultValues: {
      title: 'Which signs are in the video?',
      question: '[qvideo id="" solution="{$1}"]',
      answer1: '[img id=""]',
      answer2: '[img id=""]',
      answer3: '[img id=""]',
      answer4: '[img id=""]',
      answer: '|1||2',
      correctMsg: '"English."',
      incorrectMsg: '"English."',
    },
    enabledFields: [
      'targetVocab',
      'question',
      'answer1',
      'answer2',
      'answer3',
      'answer4',
      'answer',
      'correctMsg',
      'incorrectMsg',
    ],
    optionalFields: ['correctMsg', 'incorrectMsg'],
  },
  {
    category: '3c',
    helperText: 'mc correct statements in ENGLISH',
    type: 'multiple',
    defaultValues: {
      title: 'Choose the correct statements!',
      question: '[qvideo id="" solution="{$1}"]',
      answer1: 'textblock',
      answer2: 'textblock',
      answer: '|1||2||3',
      correctMsg: '"English."',
      incorrectMsg: '"English."',
    },
    enabledFields: [
      'targetVocab',
      'title',
      'question',
      'answer1',
      'answer2',
      'answer3',
      'answer4',
      'answer',
      'correctMsg',
      'incorrectMsg',
    ],
    optionalFields: [
      'question',
      'correctMsg',
      'incorrectMsg',
      'answer3',
      'answer4',
    ],
  },
  {
    category: '3d',
    helperText: 'multiple sign meaning',
    type: 'multiple',
    defaultValues: {
      title: "What's the meaning of this sign?",
      question: '[qvideo id="" solution="{$1}"]',
      answer1: ' {$1} ',
      answer2: 'GLOSS',
      answer3: 'GLOSS',
      answer4: 'GLOSS',
      answer: '|1||2||3',
      correctMsg: '"English."',
      incorrectMsg: '"English."',
    },
    enabledFields: [
      'targetVocab',
      'question',
      'answer1',
      'answer2',
      'answer3',
      'answer4',
      'answer',
      'correctMsg',
      'incorrectMsg',
    ],
    optionalFields: ['correctMsg', 'incorrectMsg'],
  },
  {
    category: '4a',
    helperText: 'type sign',
    type: 'cloze_answer',
    defaultValues: {
      title: 'Type the sign shown!',
      question: '[qvideo id="" solution="{$1}"]',
      answer: '{{$1}}',
    },
    enabledFields: ['targetVocab', 'question', 'answer'],
  },
  {
    category: '4b',
    helperText: 'fill GLOSS gap',
    type: 'cloze_answer',
    defaultValues: {
      title: 'Fill in the gap!',
      question: '[qvideo id="" solution="{$1}"]',
      answer: ' {$1} ',
      correctMsg: '"English."',
      incorrectMsg: '"English."',
    },
    enabledFields: [
      'targetVocab',
      'question',
      'answer',
      'correctMsg',
      'incorrectMsg',
    ],
    optionalFields: ['correctMsg', 'incorrectMsg'],
  },
  {
    category: '4c',
    helperText: 'fill correct number',
    type: 'cloze_answer',
    defaultValues: {
      title: 'Write down the correct number!',
      question: '[qvideo id="" solution="{$1}"]',
      answer: '{{$1}}',
    },
    enabledFields: ['targetVocab', 'correctMsg', 'incorrectMsg'],
    optionalFields: ['correctMsg', 'incorrectMsg'],
  },
  {
    category: '4d',
    helperText: 'fill GLOSS gap',
    type: 'cloze_answer',
    defaultValues: {
      title: 'Fill in the gap with the right word!',
      question: '[qvideo id="" solution="{$1}"]',
      answer: ' {$1} ',
      falseAnswers: 'GLOSS, GLOSS',
      correctMsg: '"English."',
      incorrectMsg: '"English."',
    },
    enabledFields: [
      'targetVocab',
      'question',
      'answer',
      'falseAnswers',
      'correctMsg',
      'incorrectMsg',
    ],
    optionalFields: ['correctMsg', 'incorrectMsg'],
  },
  {
    category: '4e',
    helperText: 'GLOSS blocks right order',
    type: 'cloze_answer',
    defaultValues: {
      title: 'Put the sentence in the right order!',
      question: '[qvideo id="" solution="{$1}"]',
      correctMsg: '"English."',
      incorrectMsg: '"English."',
    },
    enabledFields: [
      'targetVocab',
      'question',
      'answer',
      'falseAnswers',
      'correctMsg',
      'incorrectMsg',
    ],
    optionalFields: ['question', 'falseAnswers', 'correctMsg', 'incorrectMsg'],
  },
  {
    category: '4f',
    helperText: 'GLOSS block finish sentence',
    type: 'cloze_answer',
    defaultValues: {
      title: 'Finish the sentence!',
      question: '[qvideo id="" solution="{$1}"]',
      answer: ' {$1} ',
      correctMsg: '"English."',
      incorrectMsg: '"English."',
    },
    enabledFields: [
      'targetVocab',
      'question',
      'answer',
      'falseAnswers',
      'correctMsg',
      'incorrectMsg',
    ],
    optionalFields: ['falseAnswers', 'correctMsg', 'incorrectMsg'],
  },
  {
    category: '4g',
    helperText: 'Fill gap multiple GLOSS blocks',
    type: 'cloze_answer',
    defaultValues: {
      title: 'Fill in the gaps with the right words!',
      question: '[qvideo id="" solution="{$1}"]',
      answer: ' {$1} ',
      correctMsg: '"English."',
      incorrectMsg: '"English."',
    },
    enabledFields: [
      'targetVocab',
      'question',
      'answer',
      'falseAnswers',
      'correctMsg',
      'incorrectMsg',
    ],
    optionalFields: ['falseAnswers'],
  },
  {
    category: '4i',
    helperText: 'start the sentence',
    type: 'cloze_answer',
    defaultValues: {
      title: 'Start the sentence!',
      question: '[qvideo id="" solution="{$1}"]',
      correctMsg: '"English."',
      incorrectMsg: '"English."',
    },
    enabledFields: [
      'targetVocab',
      'question',
      'answer',
      'falseAnswers',
      'correctMsg',
      'incorrectMsg',
    ],
    optionalFields: ['falseAnswers', 'correctMsg', 'incorrectMsg'],
  },
];

export const getAutofillTemplateQuestion = (
  category: QuestionCategory,
): AutoFillQuestion => {
  const autoFilledQuestion = autofillQuestions.find(
    (question) => question.category === category,
  );

  if (!autoFilledQuestion) {
    throw new UnknownQuestionFormat(category);
  }

  return autoFilledQuestion;
};
