import {CaptureConsole, ExtraErrorData} from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import axios from 'axios';
import {config} from '../settings';

export function initializeSentry(): void {
  Sentry.init({
    dsn: config.sentry.dsn,
    integrations: [
      new Integrations.BrowserTracing(),
      new CaptureConsole({levels: ['error']}),
      new ExtraErrorData({depth: 5}),
    ],
    tracesSampleRate: config.sentry.sampleRate,
    environment: config.environment,
    release: config.sentry.release || undefined,
    initialScope: {tags: {sign_language: 'ASL'}}, // we support only ASL currently
    beforeSend(event, hint) {
      const e = hint?.originalException;
      // https://github.com/axios/axios/issues/2387
      if (e instanceof Error && axios.isAxiosError(e)) {
        const axios_call = `${e.config.method.toUpperCase()} ${
          e.config.baseURL ?? ''
        }${e.config.url}`;
        e.message = `${axios_call}: ${e.message}`;
        event.exception.values[0].type = 'AxiosError';
        event.exception.values[0].value = e.message;
        event.tags = {...event.tags, axios_call};
        // https://docs.sentry.io/platforms/node/guides/express/usage/sdk-fingerprinting/
        event.fingerprint = [
          '{{ default }}',
          axios_call.replace(/\d+/g, '#'),
          String(e.response?.status),
        ];
      }

      return event;
    },
  });
}
