import TextField, {TextFieldProps} from '@mui/material/TextField';
import './videoIdInput.css';

export const VideoIdInput = (props: TextFieldProps) => (
  <TextField
    onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
    type="number"
    label={'ID'}
    data-videoidinput
    {...props}
  />
);
