import {GetQuizResponseQuestion} from 'types';

export const convertQuizQuestions = (questions: Array<GetQuizResponseQuestion>) => {
  return questions.map((question, i) => {
    question.count = (i + 1).toString();

    if (Array.isArray(question.hint)) {
      question.hint = question.hint.join(', ');
    }

    return question;
  });
};
