import * as React from 'react';
import {Routes, Route, useLocation, Navigate} from 'react-router-dom';

import {AppScreen} from 'screens/AppScreen';
import {LessonScreen} from 'screens/LessonScreen';
import {LoginScreen} from 'screens/LoginScreen';
import {QuizDetailScreen} from 'screens/QuizDetailScreen';
import {WelcomeScreen} from 'screens/WelcomeScreen';
import {useStores} from 'store/root-store';
import {Languages} from 'types/api';

export default function App() {
  const location = useLocation();

  React.useEffect(() => {
    if (!/quizzes/.test(location.pathname)) {
      document.title = 'Lingvano CMS';
    }
  }, [location.pathname]);

  const {authStore} = useStores();

  const language = _getLanguageFromUrlString(location.pathname);
  if (language) {
    authStore.setLanguage(language);
  } else if (/lessons|quizzes/.test(location.pathname)) {
    return (
      <Navigate to={`${location.pathname.replace('/app/', '/app/asl/')}`} />
    );
  }

  return (
    <Routes>
      <Route path="*" element={<Navigate to="/app/welcome" />} />
      <Route path="/login" element={<LoginScreen />} />
      <Route
        path="/app"
        element={
          <RequireAuth>
            <AppScreen />
          </RequireAuth>
        }>
        <Route path="" element={<Navigate to="/app/welcome" />} />
        <Route path="*" element={<Navigate to="/app/welcome" />} />{' '}
        {/*TODO: change path="*" Route with 404PageComponent */}
        <Route path="welcome" element={<WelcomeScreen />} />
        <Route path=":language/lessons/:lessonId" element={<LessonScreen />} />
        <Route
          path=":language/quizzes/:quizId"
          element={<QuizDetailScreen />}
        />
      </Route>
    </Routes>
  );
}

function RequireAuth({children}: {children: React.ReactElement}) {
  const {authStore} = useStores();
  const location = useLocation();

  if (!authStore.token) {
    // Redirect to /login page, and save the current location for redirect
    return <Navigate to="/login" state={{from: location}} />;
  }
  return children;
}

const _matchPlatform = new RegExp(/\/(asl|bsl|oegs)\//);
const _getLanguageFromUrlString = (path: string) => {
  const platformPrefix = path.match(_matchPlatform);
  if (platformPrefix && platformPrefix[1]) {
    return platformPrefix[1] as Languages;
  }
};
