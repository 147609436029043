import React from 'react';
import {
  Backdrop,
  Box,
  Button,
  IconButton,
  Modal as MuiModal,
  Stack,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import {strings} from 'settings';
import {LoadingButton} from '@mui/lab';

type ModalSize = 'default' | 'small' | 'maxHeight';

interface ModalProps {
  size: ModalSize;
  testID: string;
  children: React.ReactNode;
  title: string;
  icon: React.ReactElement;
  submitDisabled?: boolean;
  submitText?: string;
  loading?: boolean;
  readOnly?: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

export const Modal = (props: ModalProps) => {
  const width: number = props.size === 'small' ? 500 : 1400;
  const height: string = props.size === 'maxHeight' ? '98vh' : '90vh';

  return (
    <MuiModal
      open={true}
      onClose={props.onClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        sx: {
          backdropFilter: 'blur(2px)',
          backgroundColor: 'rgba(111, 126, 140, 0.2)',
        },
      }}>
      <Box
        data-testid={props.testID}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: width,
          maxWidth: '90%',
          maxHeight: height,
          overflow: 'auto',
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
        }}>
        <StyledHeader>
          <StyledHeaderText>
            {props.icon ? (
              <StyledHeaderIcon>{props.icon}</StyledHeaderIcon>
            ) : null}
            {props.title}
          </StyledHeaderText>
          <IconButton
            aria-label="close-x-symbol"
            size="small"
            onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </StyledHeader>
        <StyledModalContent>{props.children}</StyledModalContent>
        {!props.readOnly && (
          <StyledFooter>
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button
                variant="outlined"
                startIcon={<CancelIcon />}
                onClick={props.onClose}>
                {strings.QUIZ_CANCEL}
              </Button>
              {props.loading === undefined ? (
                <Button
                  variant="contained"
                  startIcon={<SaveIcon />}
                  onClick={props.onSubmit}
                  disabled={props.submitDisabled}
                  aria-label="save-button">
                  {props.submitText ? props.submitText : strings.QUIZ_SAVE}
                </Button>
              ) : (
                <LoadingButton
                  loading={props.loading}
                  disabled={props.submitDisabled}
                  variant="contained"
                  startIcon={<SaveIcon />}
                  onClick={props.onSubmit}>
                  {props.submitText ? props.submitText : strings.QUIZ_SAVE}
                </LoadingButton>
              )}
            </Stack>
          </StyledFooter>
        )}
      </Box>
    </MuiModal>
  );
};

const StyledHeader = styled('div')({
  padding: '16px 24px',
  background: 'white',
  position: 'sticky',
  top: '0',
  zIndex: '100',
  display: 'flex',
  justifyContent: 'space-between',

  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: '24px',
    right: '24px',
    height: '1px',
    background: '#C6C6C6',
  },
});

const StyledHeaderIcon = styled('div')({
  marginRight: '16px',
  width: '32px',
  height: '32px',

  svg: {
    width: '100%',
    height: '100%',
  },
});

const StyledHeaderText = styled('div')({
  display: 'flex',
  alignItems: 'center',
  fontSize: '24px',
  fontWeight: 'bold',
  color: '#757575',
});

const StyledModalContent = styled('div')({
  padding: '24px 32px',
});

const StyledFooter = styled('div')({
  position: 'sticky',
  bottom: '0',
  zIndex: '100',
  background: 'white',
  padding: '16px 24px',

  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: '24px',
    right: '24px',
    height: '1px',
    background: '#C6C6C6',
  },
});
