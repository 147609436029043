import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  Alert,
  Autocomplete,
  Button,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import {asanaUsers} from 'api/mock/responses/asanaUsers';
import {Modal} from 'components/molecules/Modal';
import {strings} from 'settings';
import {Observer} from 'mobx-react';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import EditIcon from '@mui/icons-material/Edit';

interface TaskModalData {
  assignee: {
    gid: `${number}`;
    name: string;
  };
  title: string;
  description: string;
  dueDate: string;
}

interface Props {
  onCreate: (data: TaskModalData) => void;
  onClose: () => void;
  createdTask?: {
    id: string;
    name: string;
  };
}

export const AsanaTaskModal = (props: Props) => {
  const [error, setError] = React.useState('');
  const {control, handleSubmit, formState} = useForm({
    mode: 'onChange',
    defaultValues: {
      assignee: null,
      title: '',
      description: '',
      dueDate: '',
    },
  });

  const _renderTextInput = (
    name: 'assignee' | 'title' | 'description' | 'dueDate',
    label: string,
    placeholder: string,
    props: Record<string, any> = {},
    required = true,
  ) => (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required && strings.FIELD_REQUIRED,
      }}
      render={({field, fieldState}) => (
        <TextField
          label={label}
          type="text"
          value={field.value}
          placeholder={placeholder}
          error={fieldState.invalid}
          helperText={fieldState.error?.message}
          sx={{mt: 2}}
          onChange={field.onChange}
          {...props}
        />
      )}
    />
  );

  return (
    <Modal
      onSubmit={handleSubmit(async (data) => {
        try {
          await props.onCreate(data);
        } catch (e) {
          setError(`${strings.ASANA.NEW_TASK_FAIL}: ${e}`);
          Sentry.captureException(e, {extra: {data}});
        }
      })}
      onClose={props.onClose}
      size="default"
      testID="asana-task-modal"
      title={strings.ASANA.CREATE}
      icon={<AddCircleOutlineIcon />}
      submitText={strings.ASANA.CREATE}
      submitDisabled={Boolean(props.createdTask)}
      loading={formState.isSubmitting}>
      <Observer>
        {() => {
          return (
            <StyledWrapper>
              <StyledContentWrapper>
                {error && <Alert severity="error">{error}</Alert>}
                {props.createdTask ? (
                  <>
                    <Typography my={2} variant="h3">
                      {strings.ASANA.NEW_TASK_SUCCESS}
                    </Typography>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://app.asana.com/0/1202034206683323/${props.createdTask.id}`}>
                      <Typography py={1} my={1}>
                        {props.createdTask.name}
                      </Typography>
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://app.asana.com/0/1202034206683323/${props.createdTask.id}`}>
                      <Button variant="contained" startIcon={<EditIcon />}>
                        {strings.ASANA.NEW_TASK_EDIT}
                      </Button>
                    </a>
                  </>
                ) : (
                  <FormControl sx={{width: '100%'}}>
                    <Controller
                      name="assignee"
                      control={control}
                      rules={{required: strings.FIELD_REQUIRED}}
                      render={({field, fieldState}) => (
                        <Autocomplete
                          options={asanaUsers.response.users}
                          getOptionLabel={(u) => u.name}
                          isOptionEqualToValue={(option, value) => {
                            return option.gid === value.gid;
                          }}
                          onChange={(_, value) => field.onChange(value)}
                          value={field.value}
                          renderInput={(params) => (
                            <TextField
                              label={strings.ASANA.ASSIGNEE}
                              type="text"
                              placeholder="Name of the colleague"
                              error={fieldState.invalid}
                              helperText={fieldState.error?.message}
                              {...params}
                            />
                          )}
                        />
                      )}
                    />
                    {_renderTextInput(
                      'title',
                      strings.ASANA.TITLE,
                      'Title of the task in Asana',
                    )}
                    {_renderTextInput(
                      'description',
                      strings.ASANA.DESCRIPTION,
                      'Describe the task for your colleague. We automatically add the quiz url & cell position',
                      {multiline: true, minRows: 3},
                      false,
                    )}
                    {_renderTextInput(
                      'dueDate',
                      strings.ASANA.DUE_DATE,
                      '',
                      {
                        type: 'date',
                        sx: {mt: 2, maxWidth: '250px'},
                        InputLabelProps: {shrink: true},
                      },
                      false,
                    )}
                  </FormControl>
                )}
              </StyledContentWrapper>
            </StyledWrapper>
          );
        }}
      </Observer>
    </Modal>
  );
};

const StyledWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const StyledContentWrapper = styled('div')({
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
});
