import {strings} from './strings';
import {AxiosError} from 'axios';

class AppException extends Error {}

export class UnknownQuestionFormat extends AppException {
  constructor(category: string) {
    super(
      `The question format ${category} was not found in the autofill config.
      
      ${strings.QUIZ_ERRORS.UNKOWN_QUESTION_FORMAT.INFO}`,
    );
  }
}
export class ApiException extends AppException {
  public endpoint: string;
  public requestPayload: unknown;
  public responseStatus: number | undefined;
  public responsePayload: Record<string, any>;
  public responseHeaders: Record<string, string>;

  constructor(public e: AxiosError) {
    const method = e.config.method?.toUpperCase?.() ?? '???';
    const url = `${e.config.baseURL ?? ''}${e.config.url}`;
    const endpoint = `${method} ${url}`;

    super(`Failed API call "${endpoint}". Got: ${e.message}`);
    this.endpoint = endpoint;
    this.requestPayload = e.request.body ?? e.config.data;
    this.responseStatus = e.response?.status;
    this.responsePayload = (e.response?.data ?? {}) as Record<string, unknown>;
    this.responseHeaders = e.response?.headers ?? {};
  }
}

export class NetworkError extends ApiException {}
export class UnauthorizedApiRequest extends ApiException {}
export class ResourceNotFound extends ApiException {}
