import MoveDownIcon from '@mui/icons-material/MoveDown';
import {Box, IconButton, Link, Tooltip, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import {DataGridFormattedField} from 'components/molecules/DataGridFormattedField';
import {DeleteButton} from 'components/molecules/DeleteButton';
import {ReplaceModal} from 'components/molecules/ReplaceModal';
import {SearchBox} from 'components/molecules/SearchBox';
import {SwitchButton} from 'components/molecules/SwitchButton';
import {ViewButton} from 'components/molecules/ViewButton';
import {escapeString} from 'helper/escapeString';
import {getColumnNr} from 'helper/getColumnNumber';
import {isInViewport} from 'helper/isInViewport';
import {sleep} from 'helper/sleep';
import {usePrevious} from 'hooks/usePrevious';
import debounce from 'lodash/debounce';
import escapeRegExp from 'lodash/escapeRegExp';
import isEqual from 'lodash/isEqual';
import {useSnackbar} from 'notistack';
import React from 'react';
import DataGrid, {SelectColumn} from 'react-data-grid';
import {useParams} from 'react-router-dom';
import {QuestionStatus, QuestionTrainerStatus} from 'screens/QuizDetailScreen';
import {getAutofillTemplateQuestion, strings} from 'settings';
import {theme} from 'settings/theme';
import {useStores} from 'store/root-store';
import {QuestionField} from 'types';
import {parseJsonSafely} from '../../../helper/helpers';
import {AsanaTaskModal} from '../AsanaTaskModal';
import {DialogModal} from '../DialogModal';
import {Column, QuizDataGridProps, SearchResult, SelectedCell} from './';
import './QuizDataGrid.style.css';
import {OpenInNew} from '@mui/icons-material';

export const QuizDataGrid = (props: QuizDataGridProps) => {
  const {enqueueSnackbar} = useSnackbar();
  const {authStore} = useStores();
  const params = useParams();
  const {asanaTasks} = props;
  const [selectedAsanaTasks, setSelectedAsanaTasks] = React.useState(null);
  const [asanaTaskData, setAsanaTaskData] = React.useState({
    questionId: undefined,
    column: undefined,
    task: undefined,
  });
  const [searchBoxOpen, setSearchBoxOpen] = React.useState(false);
  const [caseSensitivityStatus, setCaseSensitivityStatus] =
    React.useState(false);
  const [searchResults, setSearchResults] = React.useState<Array<SearchResult>>(
    [],
  );
  const [searchText, setSearchText] = React.useState('');
  const [activeSearchValue, setActiveSearchValue] = React.useState(1);
  const [isLoadingSearch, setIsLoadingSearch] = React.useState(false);
  const [advancedOptionsModal, setAdvancedOptionsModal] = React.useState(false);
  const [prevSelect, setPrevSelect] = React.useState(undefined);
  const [errorState, setErrorState] = React.useState('');

  const [rows, setRows] = React.useState([]);

  const [selectedCellArea, setSelectedCellArea] = React.useState([]);

  const datagrid = React.useRef(null);
  const searchbox = React.useRef<HTMLInputElement>(null);
  const hasSelectedCellsRef = React.useRef(null);
  const currentRowsRef = React.useRef(null);
  const currentSelectedRowsRef = React.useRef(null);

  const prevSelectedCellArea = usePrevious({
    selectedCellArea,
    setSelectedCellArea,
  });

  React.useEffect(() => {
    document.addEventListener('keydown', _globalKeyListener, false);

    const datagridCopy = datagrid;

    if (datagridCopy.current) {
      datagridCopy.current.element.addEventListener(
        'click',
        _metaKeyListener,
        false,
      );

      document.addEventListener('click', handleClickOutside);
    }

    if (props.initialScroll) {
      setTimeout(() => {
        datagrid.current.element.scrollTo({top: props.initialScroll});
      }, 0);
    }

    return () => {
      document.removeEventListener('keydown', _globalKeyListener, false);
      if (datagridCopy?.current) {
        datagridCopy.current.element.removeEventListener(
          'click',
          _metaKeyListener,
          false,
        );
        document.removeEventListener('click', handleClickOutside);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setRows(Object.assign(props.rows));
    currentRowsRef.current = Object.assign(props.rows);
  }, [props.rows]);

  React.useEffect(() => {
    currentSelectedRowsRef.current = Object.assign(props.selectedRows);
  }, [props.selectedRows]);

  React.useEffect(() => {
    hasSelectedCellsRef.current = selectedCellArea;

    if (
      !prevSelectedCellArea?.selectedCellArea.length &&
      !selectedCellArea.length
    ) {
      return;
    }

    if (
      prevSelectedCellArea &&
      prevSelectedCellArea.selectedCellArea !== selectedCellArea
    ) {
      if (
        !selectedCellArea.length &&
        prevSelectedCellArea.selectedCellArea.length
      ) {
        prevSelectedCellArea.selectedCellArea.forEach((cell) => {
          if (cell.cellElement.classList.contains('search-area-selected')) {
            cell.cellElement.classList.remove('search-area-selected');
          }
        });
      }
    }

    if (
      !prevSelectedCellArea?.selectedCellArea ||
      prevSelectedCellArea.selectedCellArea.length < selectedCellArea.length
    ) {
      const changedElement = selectedCellArea.filter((cell) => {
        return !prevSelectedCellArea.selectedCellArea.includes(cell);
      })[0];
      changedElement.cellElement.classList.add('search-area-selected');
    } else if (
      prevSelectedCellArea?.selectedCellArea.length > selectedCellArea.length
    ) {
      const changedElement = prevSelectedCellArea.selectedCellArea.filter(
        (cell) => {
          return !selectedCellArea.includes(cell);
        },
      )[0];

      changedElement.cellElement.classList.remove('search-area-selected');
    }
  }, [prevSelectedCellArea, selectedCellArea]);

  React.useEffect(() => {
    if (searchResults.length > 0) {
      _scrollAndHighlightCell(searchResults[0].row, searchResults[0].col);
      setActiveSearchValue(1);
    } else {
      _unselectPrevSelect();
      setActiveSearchValue(0);
    }
    setIsLoadingSearch(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults]);

  React.useEffect(() => {
    if (activeSearchValue > 0 && searchResults.length) {
      _scrollAndHighlightCell(
        searchResults[activeSearchValue - 1].row,
        searchResults[activeSearchValue - 1].col,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSearchValue]);

  React.useEffect(() => {
    if (searchText?.length) {
      _handleSearchTextChange(
        searchText,
        caseSensitivityStatus,
        selectedCellArea,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseSensitivityStatus]);

  const _onCopy = (copyProps) => {
    const success = _handleCopy(copyProps);

    if (currentSelectedRowsRef.current.size > 0) {
      return;
    }

    if (success) {
      enqueueSnackbar('Copied from cell!', {
        variant: 'info',
        autoHideDuration: 1500,
      });
    }
  };

  const _globalKeyListener = (event) => {
    if (event.keyCode === 70 && event.metaKey) {
      // CMD + F
      event.preventDefault();
      setSearchBoxOpen(true);
      if (searchbox.current) {
        searchbox.current.focus();
      }
    }

    if (event.keyCode === 67 && event.metaKey) {
      // CMD + C

      if (hasSelectedCellsRef.current?.length) {
        event.preventDefault();

        const selectedCells = hasSelectedCellsRef.current.map((cell) => ({
          sourceRow: currentRowsRef.current[cell.row - 2],
          sourceColumnKey:
            Column[cell.col + (props.quizData.category === 'Dialog' ? -1 : -2)],
        }));

        _onCopy(selectedCells);
      }
    }

    if (event.keyCode === 86 && event.metaKey) {
      // CMD + V

      if (window.isSecureContext) {
        navigator.clipboard.readText().then((copiedData: string) => {
          if (copiedData.startsWith('lingvanocms')) {
            return;
          }
          const parsedData = parseJsonSafely(copiedData);
          if (Array.isArray(parsedData) && parsedData.length > 0) {
            event.preventDefault();
            _onPaste(
              parsedData.map((cell) => ({
                sourceRow: cell.sourceRow,
                sourceColumnKey: cell.sourceColumnKey,
                targetRow:
                  currentRowsRef.current[
                    (document.activeElement.parentElement as any).ariaRowIndex -
                      2
                  ],
                targetColumnKey:
                  Column[
                    Number((document.activeElement as any).ariaColIndex) +
                      Number(Column[cell.sourceColumnKey]) -
                      Number(Column[parsedData[0].sourceColumnKey]) +
                      (props.quizData.category === 'Dialog' ? -1 : -2)
                  ],
              })),
            );
          }
        });
      }
    }

    if (event.keyCode === 27) {
      // esc
      event.preventDefault();
      _resetSearchValue();
    }
  };

  const _enterKeyListener = (event) => {
    if (event.keyCode === 13) {
      // enter
      event.preventDefault();
      if (searchResults.length > 0) {
        if (activeSearchValue < searchResults.length) {
          setActiveSearchValue((prevValue) => (prevValue += 1));
        } else {
          setActiveSearchValue(1);
        }
      } else {
        setActiveSearchValue(0);
      }
    }
  };

  const _metaKeyListener = (e) => {
    if (!e.metaKey) {
      if (hasSelectedCellsRef.current?.length) {
        setSelectedCellArea([]);
      }
      return;
    }
    try {
      (document.activeElement as HTMLElement).blur();
    } catch (e) {
      // when active Element is not HTML ELement it throws error
      console.log(e);
    }
    _searchForCellAndRow(e.target);
  };

  const handleClickOutside = (e) => {
    if (e.target.tagName === 'HTML') {
      setSelectedCellArea([]);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const _handleSearchTextChange = React.useCallback(
    debounce(
      (
        searchText: string,
        caseSensitive: boolean,
        cellSelect: Array<SelectedCell>,
      ) => {
        setSearchText(searchText);
        _getSearchResults(searchText, caseSensitive, cellSelect);
      },
      props.rows.length > 40 ? 2000 : 400,
    ),
    [],
  );

  const _handleSearchBoxBlur = (e) => {
    if (!e.target.value && !e.relatedTarget) {
      setCaseSensitivityStatus(false);
      setSearchBoxOpen(false);
    }
  };

  const _resetSearchValue = () => {
    _handleSearchTextChange.cancel();
    _unselectPrevSelect();
    if (searchbox.current) {
      searchbox.current.value = '';
    }
    setSearchText('');
    setSearchResults([]);
    setCaseSensitivityStatus(false);
    setSearchBoxOpen(false);
  };

  const _onPaste = (onPasteProps) => {
    const paste = _handlePaste(onPasteProps);

    if (paste.success) {
      enqueueSnackbar('Pasted into cell!', {
        variant: 'success',
        autoHideDuration: 1500,
      });
    }

    // props are being updated manually to handle multiple cell pasting
    let newRows = [];
    setRows((oldValue) => {
      newRows = oldValue;
      newRows[paste.response['count'] - 1] = paste.response;
      return newRows;
    });
    props.onRowsChange(newRows, undefined);

    return paste.response;
  };

  const _unselectPrevSelect = () => {
    if (prevSelect?.classList) {
      prevSelect.classList.remove('currently-selected');
    }
  };

  const _scrollAndHighlightCell = (row, col) => {
    const _highlightCell = (row, col) => {
      const cell = document.querySelector(
        `[aria-rowindex="${row}"] [aria-colindex="${col}"]`,
      );
      _unselectPrevSelect();
      setPrevSelect(cell);
      if (cell) {
        cell.classList.add('currently-selected');
      }
    };

    const cell = document.querySelector(
      `[aria-rowindex="${row}"] [aria-colindex="${col}"]`,
    );
    if (!cell || !isInViewport(cell)) {
      datagrid.current.scrollToRow(row - 2);
      setTimeout(() => {
        datagrid.current.element.scrollTo({
          left: searchResults[activeSearchValue - 1]?.offsetLeft,
        });
        // small timeout is needed for element to be rendered before it can be highlighted
        setTimeout(() => {
          _highlightCell(row, col);
        }, 100);
      }, 500);
    } else {
      _highlightCell(row, col);
    }
  };

  const _handleCopy = (
    sourceCells:
      | Array<{sourceRow; sourceColumnKey}>
      | {sourceRow; sourceColumnKey},
  ): boolean => {
    // TODO: check if selectedRows is set, if set -> return false;

    const incompatibleColumns = ['count', 'category'];

    let sourceCellsArray = [];

    if (Array.isArray(sourceCells)) {
      sourceCellsArray = [...sourceCells];
    } else {
      sourceCellsArray = [sourceCells];
    }

    const copiedCells = [];

    let enqueuedSnackbar = false;

    sourceCellsArray.forEach((cell) => {
      const {sourceColumnKey} = cell;

      if (incompatibleColumns.includes(sourceColumnKey)) {
        return;
      }

      if (
        copiedCells.length &&
        copiedCells[0]['sourceRow']['count'] !== cell['sourceRow']['count']
      ) {
        if (!enqueuedSnackbar) {
          enqueueSnackbar(
            `Only cells from row ${copiedCells[0]['sourceRow']['count']} have been copied`,
            {
              variant: 'info',
              autoHideDuration: 3000,
            },
          );
          enqueuedSnackbar = true;
        }

        return;
      }

      copiedCells.push(cell);
    });

    if (window.isSecureContext) {
      navigator.clipboard.writeText(JSON.stringify(copiedCells));
    }

    return Boolean(copiedCells.length);
  };

  const _getSearchResults = (
    text: string,
    caseSensitive: boolean,
    selectedCells: Array<SelectedCell>,
  ) => {
    // return if text has [,],{,},< or > inside of it
    if (text.match(/[[\]{}<>]/)) {
      setSearchResults([]);
      setErrorState(strings.SEARCH_AND_REPLACE.ERROR);
      return;
    }
    setErrorState('');
    if (!text || !props.rows) {
      setSearchResults([]);
      return;
    }

    const searchResults: Array<SearchResult> = [];

    if (selectedCells?.length) {
      selectedCells.forEach((cell) => {
        const regex = new RegExp(
          escapeRegExp(text),
          caseSensitive ? 'g' : 'gi',
        );
        const foundOccurrances =
          props.rows[cell.row - 2][
            Column[cell.col + (props.quizData.category === 'Dialog' ? -1 : -2)]
          ].match(regex);

        if (foundOccurrances) {
          foundOccurrances.forEach(() => {
            searchResults.push({
              row: cell.row,
              col: cell.col,
              offsetLeft: 0,
            });
          });
        }
      });
    } else {
      props.rows.forEach((row, rowIndex) => {
        let offsetLeft = 0;
        Object.entries(row).forEach(([key, value]) => {
          if (
            !['errors', 'id', 'falseAnswers'].includes(key) &&
            typeof value === 'string'
          ) {
            if (
              (!getAutofillTemplateQuestion(
                row.category,
              )?.enabledFields?.includes(key as QuestionField) &&
                ![
                  Column[0],
                  Column[1],
                  Column[2],
                  Column[3],
                  Column[4],
                  Column[12],
                ].includes(key) &&
                value) ||
              (key === 'targetVocab' && row['targetVocab'] === '#disabled#')
            ) {
              return;
            }

            offsetLeft += columns.find((col) => col.key === key)?.width || 0;

            const regex = new RegExp(
              escapeRegExp(text),
              caseSensitive ? 'g' : 'gi',
            );
            const foundOccurrances = value.match(regex);
            if (foundOccurrances) {
              foundOccurrances.forEach(() => {
                searchResults.push({
                  row: rowIndex + 2,
                  col: getColumnNr(
                    key as QuestionField,
                    props.quizData.category,
                  ),
                  offsetLeft: offsetLeft,
                });
              });
            }
          }
        });
      });
    }
    setSearchResults(searchResults);
  };

  const _searchForCellAndRow = (element) => {
    if (!element || element.dataset.fieldStyle === 'autogenerated') {
      return;
    }
    if (element.classList.contains('rdg-cell')) {
      const newObject: SelectedCell = {
        cellElement: element,
        row: Number(element.parentElement.ariaRowIndex),
        col: Number(element.ariaColIndex),
      };

      setSelectedCellArea((prevSelected) => {
        if (
          !prevSelected.some((el) => el.cellElement === newObject.cellElement)
        ) {
          return [...prevSelected, newObject];
        } else {
          return [...prevSelected].filter((el) => {
            return el.cellElement !== newObject.cellElement;
          });
        }
      });
    } else {
      _searchForCellAndRow(element.parentElement);
    }
  };

  const _replaceSearchedText = (newText) => {
    _unselectPrevSelect();

    setAdvancedOptionsModal(false);

    if (selectedCellArea?.length) {
      const propsRows = [...props.rows];
      searchResults.forEach((result) => {
        propsRows[result.row - 2][
          Column[result.col + (props.quizData.category === 'Dialog' ? -1 : -2)]
        ] = propsRows[result.row - 2][
          Column[result.col + (props.quizData.category === 'Dialog' ? -1 : -2)]
        ].replaceAll(
          new RegExp(
            escapeRegExp(searchText),
            caseSensitivityStatus ? 'g' : 'gi',
          ),
          escapeString(newText),
        );
      });

      props.rows.forEach((row, index) => {
        props.rows[index] = propsRows[index];
      });
    } else {
      const stringifiedReplaceText = JSON.stringify(props.rows).replaceAll(
        new RegExp(
          escapeRegExp(searchText),
          caseSensitivityStatus ? 'g' : 'gi',
        ),
        escapeString(newText),
      );

      const newRows = JSON.parse(stringifiedReplaceText);
      props.rows.forEach((row, index) => {
        props.rows[index] = newRows[index];
      });
    }

    _resetSearchValue();

    props.onRowsChange(props.rows, undefined);
  };

  const _customFormatter = (
    customFormatterProps,
    initialRows,
    quizCategory?,
  ) => {
    const autoFillQuestion = getAutofillTemplateQuestion(
      customFormatterProps.row.category,
    );

    let value = customFormatterProps.row[customFormatterProps.column.key];
    let autogeneratedField = false;

    const changedFlag = _compareToInitialValue(
      customFormatterProps.row,
      customFormatterProps.column.key,
      initialRows,
    );

    _generateParentClassesForStateStyling();

    const handleRightClick = (e) => {
      e.preventDefault();
      setAsanaTaskData({
        questionId: customFormatterProps.row.id,
        column: customFormatterProps.column.key,
        task: undefined,
      });
    };

    if (
      (autoFillQuestion &&
        autoFillQuestion.enabledFields &&
        !autoFillQuestion.enabledFields.includes(
          customFormatterProps.column.key,
        )) ||
      (customFormatterProps.column.key === 'targetVocab' &&
        customFormatterProps.row['targetVocab'] === '#disabled#')
    ) {
      if (customFormatterProps.column.key === 'falseAnswers') {
        value = customFormatterProps.row['hint'];
      }

      if (value && customFormatterProps.column.key !== 'targetVocab') {
        autogeneratedField = true;
      }

      if (!autogeneratedField) {
        return (
          <StyledDisabledView
            onContextMenu={handleRightClick}
            data-field-style="disabled"
          />
        );
      }
    }

    if (customFormatterProps.column.key === 'falseAnswers') {
      value = customFormatterProps.row['hint'];
    }

    if (
      quizCategory === 'Dialog' &&
      customFormatterProps.column.key === 'title'
    ) {
      autogeneratedField = false;
    }

    return (
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
        onContextMenu={handleRightClick}>
        {asanaTasks[customFormatterProps.row.id]?.[
          customFormatterProps.column.key
        ] && (
          <Tooltip
            open={
              selectedAsanaTasks ===
              asanaTasks[customFormatterProps.row.id][
                customFormatterProps.column.key
              ]
            }
            onClose={() => setSelectedAsanaTasks(null)}
            title={
              <Typography sx={{p: 2}}>
                {asanaTasks[customFormatterProps.row.id][
                  customFormatterProps.column.key
                ].map((task) => (
                  <React.Fragment key={task.id}>
                    <a
                      style={{color: 'white'}}
                      target="_blank"
                      rel="noreferrer"
                      href={`https://app.asana.com/0/1156685824301635/${task.id}/f`}>
                      {task.title}
                    </a>
                    <br />
                  </React.Fragment>
                ))}
              </Typography>
            }>
            <button
              data-testid={`badge-asana-tasks-${customFormatterProps.row.id}-${customFormatterProps.column.key}`}
              className="rdg-cell--asana-tasks"
              onClick={(evt) =>
                setSelectedAsanaTasks(
                  asanaTasks[customFormatterProps.row.id][
                    customFormatterProps.column.key
                  ],
                )
              }>
              {
                asanaTasks[customFormatterProps.row.id][
                  customFormatterProps.column.key
                ].length
              }
            </button>
          </Tooltip>
        )}
        <DataGridFormattedField
          field={customFormatterProps.column.key}
          errors={customFormatterProps.row.errors || []}
          value={value}
          additionalInformation={
            (customFormatterProps.column.key.includes('answer')
              ? customFormatterProps.row.answers
              : customFormatterProps.row.questionParts) || []
          }
          autogenerated={autogeneratedField}
          changed={changedFlag}
          autofillQuestion={
            autoFillQuestion.defaultValues[customFormatterProps.column.key] ||
            ''
          }
          questionIndex={Number(customFormatterProps.row.count) - 1}
          questionCategory={customFormatterProps.row.category}
          readOnly={props.quizData.readOnly}
        />
      </div>
    );
  };
  const columns = _getColumns(props, _customFormatter);

  if (props.rows && props.rows.length) {
    return (
      <>
        {asanaTaskData.questionId && (
          <AsanaTaskModal
            onCreate={async (data) => {
              const createdTask = await authStore.apiCreateAsanaTask({
                quizId: params.quizId,
                questionId: asanaTaskData.questionId,
                column: asanaTaskData.column,
                assigneeId: data.assignee.gid,
                title: data.title,
                description: data.description,
                dueDate: data.dueDate,
              });

              setAsanaTaskData((prev) => ({
                ...prev,
                task: {
                  id: createdTask.id,
                  name: createdTask.title,
                },
              }));
            }}
            onClose={() =>
              setAsanaTaskData({
                questionId: undefined,
                column: undefined,
                task: undefined,
              })
            }
            createdTask={asanaTaskData.task}
          />
        )}
        {advancedOptionsModal && (
          <ReplaceModal
            onSubmit={(newValue) => {
              _replaceSearchedText(newValue);
            }}
            onClose={() => {
              setAdvancedOptionsModal(false);
            }}
            oldValue={searchText}
          />
        )}
        <DataGrid
          data-testid="data-grid"
          ref={datagrid}
          className="rdg-light"
          style={{
            fontFamily: 'Roboto,Arial,sans-serif',
            minHeight: `${_getDataGridMinHeight(props.rows)}px`,
          }}
          columns={columns}
          onRowsChange={props.onRowsChange}
          rows={rows}
          defaultColumnOptions={{
            resizable: true,
          }}
          rowClass={(row) => {
            const classes = [];

            if (row.errors?.length) {
              classes.push('error-row');
            }

            if (row.status === QuestionStatus.DRAFT) {
              classes.push('draft-row');
            }

            return classes.join(' ');
          }}
          onScroll={(e) => props.onScroll(e)}
          rowHeight={(row) => _getRowHeight(row, props.quizData.category)}
          onCopy={_onCopy}
          rowKeyGetter={_rowKeyGetter}
          selectedRows={props.selectedRows}
          onSelectedRowsChange={props.onSetSelectedRows}
        />
        <SearchBox
          searchBoxOpen={searchBoxOpen}
          onChange={(e) => {
            setIsLoadingSearch(true);
            _unselectPrevSelect();
            _handleSearchTextChange(
              e.target.value,
              caseSensitivityStatus,
              selectedCellArea,
            );
          }}
          onKeyDown={(e) => {
            _enterKeyListener(e);
          }}
          onBlur={(e) => _handleSearchBoxBlur(e)}
          searchbox={searchbox}
          onLoadingStatusChange={(isLoading) => {
            setIsLoadingSearch(isLoading);
          }}
          onCaseSensitivityStatusChange={() => {
            setCaseSensitivityStatus((prev) => !prev);
          }}
          onModalClose={() => {
            _resetSearchValue();
          }}
          errorState={errorState}
          caseSensitivityStatus={caseSensitivityStatus}
          isLoading={isLoadingSearch}
          activeSearchValue={activeSearchValue}
          searchResults={searchResults}
          onOpenFilter={() => setAdvancedOptionsModal(true)}
        />
      </>
    );
  }

  return <></>;
};

const _rowKeyGetter = (row) => {
  return row.count;
};

const _getColumns = (
  quizProps: QuizDataGridProps,
  _customFormatter,
): Array<any> => {
  const editorOptions = {
    onCellKeyDown: (e) => {
      if (e.keyCode !== 13) {
        e.preventDefault();
      }
    },
  };

  let columns = [
    SelectColumn,
    {
      key: 'count',
      name: 'Nr.',
      frozen: true,
      width: 120,
      editorOptions: {...editorOptions},
      headerRenderer(headerProps) {
        return _customHeader(headerProps, quizProps);
      },
      formatter(formatterProps) {
        return _countCellFormatter(formatterProps, quizProps);
      },
    },
    {
      key: 'status',
      name: 'Status',
      frozen: true,
      width: 10,
      formatter(formatterProps) {
        return _statusCellFormatter(formatterProps, quizProps);
      },
    },
    {
      key: 'trainerStatus',
      name: 'V. Trainer',
      frozen: true,
      width: 10,
      formatter(formatterProps) {
        return (
          <SwitchButton
            disabled={quizProps.quizData.readOnly}
            checked={
              formatterProps.row.trainerStatus === QuestionTrainerStatus.VOCAB
            }
            title={
              formatterProps.row.trainerStatus === QuestionTrainerStatus.VOCAB
                ? 'Set off Trainer'
                : 'Set on Trainer'
            }
            onStateChange={(e) => {
              formatterProps.onRowChange({
                ...formatterProps.row,
                trainerStatus: e.target.checked
                  ? QuestionTrainerStatus.VOCAB
                  : QuestionTrainerStatus.NO_TRAINER,
              });
            }}
            testId="vocab-trainer-toggle"
          />
        );
      },
    },
    {
      key: 'category',
      name: 'Category',
      frozen: true,
      width: 10,
    },
    {
      key: 'targetVocab',
      name: 'Target Vocab',
      minWidth: 300,
      editorOptions: {...editorOptions},
      editable: !quizProps.quizData.readOnly,
      editor(editorProps) {
        return _customEditor(editorProps);
      },
      formatter(formatterProps) {
        if (quizProps.quizData.title.includes('vocab_trainer')) {
          return (
            <Link
              href={`${formatterProps.row.lessonId}`}
              underline="none"
              color="#000000"
              flex="1"
              target="_blank"
              sx={{display: 'flex', justifyContent: 'space-between'}}>
              {formatterProps.row[formatterProps.column.key]}
              <Tooltip title="Dashboard Source">
                <OpenInNew htmlColor="#dedede" fontSize="inherit" />
              </Tooltip>
            </Link>
          );
        }
        return _customFormatter(formatterProps, quizProps.initialRows);
      },
      frozen: true,
      width: 200,
    },
    {
      key: 'title',
      name: 'Title',
      width: 200,
      editorOptions: {...editorOptions},
      formatter(formatterProps) {
        return _customFormatter(
          formatterProps,
          quizProps.initialRows,
          quizProps.quizData.category,
        );
      },
      editor(editorProps) {
        return _customEditor(editorProps, quizProps.quizData.category);
      },
    },
    {
      key: 'question',
      name: 'Question / Content',
      minWidth: 250,
      editorOptions: {...editorOptions},
      formatter(formatterProps) {
        return _customFormatter(
          formatterProps,
          quizProps.initialRows,
          quizProps.quizData.category,
        );
      },
      editable: !quizProps.quizData.readOnly,
      editor(editorProps) {
        return _customEditor(editorProps, quizProps.quizData.category);
      },
    },
    {
      key: 'answer1',
      name: 'Answer 1',
      width: 200,
      editorOptions: {...editorOptions},
      formatter(formatterProps) {
        return _customFormatter(formatterProps, quizProps.initialRows);
      },
      editor(editorProps) {
        return _customEditor(editorProps);
      },
    },
    {
      key: 'answer2',
      name: 'Answer 2',
      width: 200,
      editorOptions: {...editorOptions},
      formatter(formatterProps) {
        return _customFormatter(formatterProps, quizProps.initialRows);
      },
      editor(editorProps) {
        return _customEditor(editorProps);
      },
    },
    {
      key: 'answer3',
      name: 'Answer 3',
      width: 200,
      editorOptions: {...editorOptions},
      formatter(formatterProps) {
        return _customFormatter(formatterProps, quizProps.initialRows);
      },
      editor(editorProps) {
        return _customEditor(editorProps);
      },
    },
    {
      key: 'answer4',
      name: 'Answer 4',
      width: 200,
      editorOptions: {...editorOptions},
      formatter(formatterProps) {
        return _customFormatter(formatterProps, quizProps.initialRows);
      },
      editor(editorProps) {
        return _customEditor(editorProps);
      },
    },
    {
      key: 'answer',
      name: 'Answer',
      width: 10,
      editorOptions: {...editorOptions},
      formatter(formatterProps) {
        return _customFormatter(formatterProps, quizProps.initialRows);
      },
      editor(editorProps) {
        return _customEditor(editorProps);
      },
    },
    {
      key: 'falseAnswers',
      name: 'Hint',
      width: 10,
      editorOptions: {...editorOptions},
      formatter(formatterProps) {
        return _customFormatter(formatterProps, quizProps.initialRows);
      },
      editor(editorProps) {
        return _customEditor(editorProps);
      },
    },
    {
      key: 'correctMsg',
      name: 'Correct Message',
      width: 150,
      editorOptions: {...editorOptions},
      formatter(formatterProps) {
        return _customFormatter(formatterProps, quizProps.initialRows);
      },
      editor(editorProps) {
        return _customEditor(editorProps);
      },
    },
    {
      key: 'incorrectMsg',
      name: 'Incorrect Message',
      width: 150,
      editorOptions: {...editorOptions},
      formatter(formatterProps) {
        return _customFormatter(formatterProps, quizProps.initialRows);
      },
      editor(editorProps) {
        return _customEditor(editorProps);
      },
    },
  ];

  if (quizProps.quizData.category === 'Dialog') {
    columns = columns.filter((column) => {
      if (column.key !== 'targetVocab') {
        return column;
      }

      return false;
    });
  }

  if (quizProps.quizData.title.includes('vocab_trainer')) {
    columns = columns.filter((column) => {
      return ['count', 'status', 'targetVocab', 'question'].includes(
        column.key,
      );
    });
  }

  if (quizProps.quizData.title.includes('trainer')) {
    columns = columns.filter((column) => column.key !== 'trainerStatus');
  }

  return columns;
};

const _handlePaste = (
  dataCells:
    | Array<{sourceColumnKey; sourceRow; targetColumnKey; targetRow}>
    | {sourceColumnKey; sourceRow; targetColumnKey; targetRow},
): any => {
  const incompatibleColumns = ['count', 'category'];

  let dataCellsArray = [];

  if (Array.isArray(dataCells)) {
    dataCellsArray = [...dataCells];
  } else {
    dataCellsArray = [dataCells];
  }

  let newResponse = {...dataCellsArray[0].targetRow};

  dataCellsArray.forEach((cell) => {
    const {sourceColumnKey, targetColumnKey, targetRow} = cell;

    const autoFillQuestion = targetRow?.category
      ? getAutofillTemplateQuestion(targetRow.category)
      : undefined;

    if (
      sourceColumnKey === 'avatar' ||
      ['id', 'avatar'].includes(targetColumnKey) ||
      ((incompatibleColumns.includes(targetColumnKey) ||
        incompatibleColumns.includes(sourceColumnKey)) &&
        sourceColumnKey !== targetColumnKey) ||
      (autoFillQuestion &&
        autoFillQuestion.enabledFields &&
        !autoFillQuestion.enabledFields.includes(targetColumnKey))
    ) {
      return;
    }
    newResponse[targetColumnKey] = cell.sourceRow[sourceColumnKey];
  });

  return {
    response: newResponse,
    success: true,
  };
};

const _getRowHeight = (item, quizCategory): number => {
  if (quizCategory === 'Dialog') {
    const matches = item.row.question.match(/\[lv-dialog.+?\]/g);

    const items = matches ? matches.length : 1;

    return 34 * items + 16;
  }
  const contentLength = !item.row.question ? 0 : item.row.question.length;

  return _calcRowHeight(contentLength);
};

const _calcRowHeight = (length: number): number => {
  const paddingVertical = 16;
  const defaultHeight = 34 + paddingVertical;

  if (length === 0) {
    return defaultHeight;
  }

  if (length > 150) {
    return 96 + paddingVertical;
  }

  if (length > 50) {
    return 64 + paddingVertical;
  }

  return defaultHeight;
};

const _getDataGridMinHeight = (rows): number => {
  const currentOffset =
    window.innerWidth <= theme.breakpoints.values.xl ? 202 : 220;
  const _gridMaxHeight = window.innerHeight - currentOffset;
  let _gridContentHeight = 0;

  rows.forEach((row) => {
    _gridContentHeight =
      _gridContentHeight +
      _calcRowHeight(!row.question ? 0 : row.question.length);
  });

  _gridContentHeight = _gridContentHeight + 35; // grid header height

  return _gridContentHeight > _gridMaxHeight
    ? _gridMaxHeight
    : _gridContentHeight;
};

const _customHeader = (customHeaderProps, quizProps: QuizDataGridProps) => {
  return (
    <>
      {quizProps.rows.length > 1 && (
        <Tooltip title="Reorder Questions">
          <Box sx={{cursor: 'pointer'}} onClick={quizProps.onTriggerReorder}>
            {customHeaderProps.column.name}
            {!quizProps.quizData.readOnly && (
              <IconButton
                data-testid="reorder-quiz"
                aria-label="reorder"
                size="small">
                <MoveDownIcon htmlColor="#000" fontSize="small" />
              </IconButton>
            )}
          </Box>
        </Tooltip>
      )}
      {quizProps.rows.length <= 1 && customHeaderProps.column.name}
    </>
  );
};

const _countCellFormatter = (
  cellFormatterProps,
  gridProps: QuizDataGridProps,
) => {
  let canOpenQuestion = true;

  if (cellFormatterProps.row.id === undefined) {
    canOpenQuestion = false;
  }

  const initialRow = gridProps.initialRows.find(
    (row) => row.id === cellFormatterProps.row.id,
  );

  if (initialRow && !isEqual(initialRow, cellFormatterProps.row)) {
    canOpenQuestion = false;
  }

  return (
    <StyledQuestionCountWrapper>
      <StyledQuestionCount>
        {cellFormatterProps.row[cellFormatterProps.column.key]}
      </StyledQuestionCount>
      {!gridProps.quizData.readOnly && (
        <DeleteButton
          onTriggerDelete={() => {
            gridProps.onTriggerDelete(cellFormatterProps.row);
          }}
        />
      )}

      <ViewButton
        active={canOpenQuestion}
        onClick={() => {
          window.open(
            gridProps.quizData.url.replace(/\/$/, '') +
              '?question=' +
              cellFormatterProps.row.id,
            '_blank',
          );
        }}
      />
    </StyledQuestionCountWrapper>
  );
};

const _statusCellFormatter = (props, gridProps) => {
  return (
    <SwitchButton
      disabled={gridProps.quizData.readOnly}
      checked={props.row.status === QuestionStatus.PUBLISHED}
      title={
        props.row.status === QuestionStatus.PUBLISHED
          ? 'Set to draft'
          : 'Publish question'
      }
      onStateChange={(e) => {
        props.onRowChange({
          ...props.row,
          status: e.target.checked
            ? QuestionStatus.PUBLISHED
            : QuestionStatus.DRAFT,
        });
      }}
      testId="question-status"
    />
  );
};

const _customEditor = (props, quizCategory?) => {
  const autoFillQuestion = getAutofillTemplateQuestion(props.row.category);

  if (
    (autoFillQuestion &&
      autoFillQuestion.enabledFields &&
      !autoFillQuestion.enabledFields.includes(props.column.key)) ||
    (props.column.key === 'targetVocab' &&
      props.row['targetVocab'] === '#disabled#')
  ) {
    if (quizCategory !== 'Dialog' && props.column.key !== 'question') {
      return null;
    }
  }

  _generateParentClassesForStateStyling();

  if (quizCategory === 'Dialog' && props.column.key === 'question') {
    return (
      <DialogModal
        editor={props}
        onSubmit={() => props.onClose(true)}
        onClose={() => props.onClose()}
      />
    );
  }

  return (
    <Box className="rdg-cell--textarea-wrapper" data-field-style="activeEditor">
      <textarea
        onBlur={() => props.onClose(true)}
        autoFocus
        ref={_removeLineBreak}
        className="rdg-cell--textarea"
        defaultValue={
          props.row[props.column.key]
            ? props.row[props.column.key].trim()
            : undefined
        }
        onChange={(e) => {
          const inputValue = e.target.value;

          props.onRowChange({
            ...props.row,
            [props.column.key]: inputValue ? inputValue.trim() : inputValue,
          });
        }}
      />
    </Box>
  );
};

const _removeLineBreak = (input: HTMLTextAreaElement | null) => {
  if (!input) {
    return;
  }

  setTimeout(() => {
    const val = input.value;
    input.value = val.replace('\n', '');
  }, 0);
};

const _compareToInitialValue = (currentRow, key, initialRows): boolean => {
  const initialValue = initialRows.find((item) => item.id === currentRow['id']);

  if (initialValue && currentRow[key] !== initialValue[key]) {
    return true;
  }

  return false;
};

const _resetRowClasses = () => {
  const allRows = document.querySelectorAll('.rdg-row--active-editor');

  allRows.forEach((row) => {
    row.classList.remove('rdg-row--active-editor');
  });
};

const _generateParentClassesForStateStyling = async () => {
  await sleep(100);

  _resetRowClasses();

  const resetNotChangedField = document.querySelectorAll(
    '.rdg-cell--custom-changed',
  );

  resetNotChangedField.forEach((item) => {
    item.classList.remove('rdg-cell--custom-changed');
  });

  const styledFields = document.querySelectorAll('[data-field-style]');

  styledFields.forEach((item) => {
    const cell = item.closest('.rdg-cell');
    switch (item.getAttribute('data-field-style')) {
      case 'disabled':
        cell.classList.add('rdg-cell--custom-disabled');
        break;
      case 'error':
        cell.classList.add('rdg-cell--custom-error');
        break;
      case 'autogenerated':
        cell.classList.add('rdg-cell--custom-autogenerated');
        break;
      case 'changed':
        cell.classList.add('rdg-cell--custom-changed');
        break;
      case 'activeEditor':
        cell.classList.add('rdg-cell--active-editor');
        cell.closest('.rdg-row').classList.add('rdg-row--active-editor');
        break;
    }
  });
};

const StyledQuestionCountWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledQuestionCount = styled('div')({
  width: '32px',
  height: '32px',
  background: '#DEDEDE',
  borderRadius: '50%',
  color: 'white',
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledDisabledView = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0,0,0,0.12)',
  pointerEvents: 'none',
});
