import {Paper, Stack, TextField} from '@mui/material';
import {styled} from '@mui/material/styles';
import {DeleteButton} from 'components/molecules/DeleteButton';
import {PersonSwitcher} from 'components/molecules/PersonSwitcher';
import {ReorderButtons} from 'components/molecules/ReorderButtons';
import {VideoIdInput} from 'components/molecules/VideoIdInput';
import {XYCoord} from 'dnd-core';
import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import {
  ConnectDragSource,
  ConnectDropTarget,
  DragSource,
  DragSourceConnector,
  DragSourceMonitor,
  DropTarget,
  DropTargetConnector,
  DropTargetMonitor,
} from 'react-dnd';
import {strings} from 'settings';
import {useStores} from 'store/root-store';
import {Languages} from 'types/api';
import {DialogForm} from './DialogModal';

const ItemTypes = {
  DIALOGITEM: 'dialogitem',
};

interface DialogItemDragObject {
  id: string;
  index: number;
}

interface DialogItemProps {
  dialog: DialogForm;
  index: number;
  id: number;
  lastItem: boolean;
  reorderItem: (dragIndex: number, hoverIndex: number) => void;
  generateDialogContent: () => void;
  deleteDialog: (toDelete: DialogForm) => void;
  moveDialog: (toMove: DialogForm, direction: 'up' | 'down') => void;

  isDragging: boolean;
  connectDragSource: ConnectDragSource;
  connectDropTarget: ConnectDropTarget;
}

interface DialogItemInstance {
  getNode: () => HTMLDivElement | null;
}

const _renderVideo = (
  id: number,
  language: Languages,
  highlightVideo: boolean = false,
) => {
  if (!id) {
    return null;
  }

  return (
    <video
      width="218"
      height="163"
      controls
      muted
      key={id}
      autoPlay
      loop
      style={{
        border: highlightVideo ? '2px solid #FFA338' : 'none',
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
      }}>
      <source
        src={`https://videos-${language}.lingvano.com/${id}-480p.mp4`}
        type="video/mp4"
        data-testid={'video-modal-element'}
      />
      Your browser does not support to preview the video.
    </video>
  );
};

const DialogItem = React.forwardRef<HTMLDivElement, DialogItemProps>(
  function DialogItem(
    {
      dialog,
      index,
      lastItem,
      generateDialogContent,
      deleteDialog,
      moveDialog,
      isDragging,
      connectDragSource,
      connectDropTarget,
    },
    ref,
  ) {
    const {authStore} = useStores();
    const [thisDialog, setThisDialog] = React.useState(
      cloneDeep(dialog) as DialogForm,
    );

    const elementRef = React.useRef(null);
    connectDragSource(elementRef);
    connectDropTarget(elementRef);

    const opacity = isDragging ? 0 : 1;
    React.useImperativeHandle<any, DialogItemInstance>(ref, () => ({
      getNode: () => elementRef.current,
    }));

    const movedDialog = (direction: 'up' | 'down') => {
      moveDialog(dialog, direction);
    };

    const _avoidDragging = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
    };

    return (
      <StyledWrapper
        elevation={3}
        sx={{
          ...(thisDialog.person === 'b'
            ? {ml: 4, backgroundColor: 'rgba(255,163,56,0.04)'}
            : {mr: 4}),
          transition: 'all 300ms ease',
        }}>
        <Stack
          ref={elementRef}
          style={{opacity}}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{minHeight: 100}}
          data-testid="dv-modal-video">
          <ReorderButtons
            upDisabled={index === 0}
            downDisabled={lastItem}
            moveDialog={movedDialog}
          />
          <StyledDeleteButtonWrapper data-testid="dv-modal-video-delete">
            <DeleteButton
              title={strings.DIALOG_MODAL_DELETE_DIALOG}
              onTriggerDelete={() => {
                deleteDialog(dialog);
              }}
            />
          </StyledDeleteButtonWrapper>
          <StyledIdInputWrapper>
            <VideoIdInput
              draggable
              onDragStart={_avoidDragging}
              placeholder={'insert ID'}
              value={thisDialog.id}
              onChange={(e) => {
                setThisDialog({...thisDialog, id: Number(e.target.value)});
                dialog.id = Number(e.target.value);
              }}
              onBlur={() => {
                generateDialogContent();
              }}
              sx={{width: '100px'}}
            />
          </StyledIdInputWrapper>
          <StyledSubtitleInputWrapper>
            <TextField
              draggable
              onDragStart={_avoidDragging}
              label={'Subtitle'}
              value={thisDialog.subtitle}
              type="text"
              onChange={(e) => {
                setThisDialog({...thisDialog, subtitle: e.target.value});
              }}
              onBlur={() => {
                dialog.subtitle = thisDialog.subtitle;

                generateDialogContent();
              }}
              sx={{width: '100%'}}
            />
          </StyledSubtitleInputWrapper>
          <PersonSwitcher
            person={thisDialog.person}
            onChange={(newValue) => {
              setThisDialog({...thisDialog, person: newValue});
              dialog.person = newValue;

              generateDialogContent();
            }}
          />
          {dialog.id ? (
            <StyledVideoPersonWrapper>
              {_renderVideo(
                dialog.id,
                authStore.language,
                dialog.person === 'b',
              )}
            </StyledVideoPersonWrapper>
          ) : (
            <StyledEmptyVideo />
          )}
        </Stack>
      </StyledWrapper>
    );
  },
);

export default DropTarget(
  ItemTypes.DIALOGITEM,
  {
    hover(
      props: DialogItemProps,
      monitor: DropTargetMonitor,
      component: DialogItemInstance,
    ) {
      if (!component) {
        return null;
      }
      // node = HTML Div element from imperative API
      const node = component.getNode();
      if (!node) {
        return null;
      }

      const dragIndex = monitor.getItem<DialogItemDragObject>().index;
      const hoverIndex = props.index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = node.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      props.reorderItem(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      monitor.getItem<DialogItemDragObject>().index = hoverIndex;
    },
  },
  (connect: DropTargetConnector) => ({
    connectDropTarget: connect.dropTarget(),
  }),
)(
  DragSource(
    ItemTypes.DIALOGITEM,
    {
      beginDrag: (props: DialogItemProps) => ({
        id: props.id,
        index: props.index,
      }),
    },
    (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    }),
  )(DialogItem),
);

const StyledWrapper = styled(Paper)({
  padding: '0 0 0 16px',
  margin: '16px 0',
  overflow: 'hidden',
  cursor: 'move',
});

const StyledIdInputWrapper = styled('div')({
  margin: '0 8px',
});

const StyledSubtitleInputWrapper = styled('div')({
  margin: '0 8px',
  flexGrow: '1',
});

const StyledVideoPersonWrapper = styled('div')({
  width: '216px',
  height: '163px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: '16px',
});

const StyledEmptyVideo = styled('div')({
  width: '16px',
  height: '2px',
});

const StyledDeleteButtonWrapper = styled('div')({
  width: '40px',
  height: '40px',
  position: 'relative',
});
