import MoveDownIcon from '@mui/icons-material/MoveDown';
import {Typography} from '@mui/material';
import {Modal} from 'components/molecules/Modal';
import {strings} from 'settings';
import update from 'immutability-helper';
import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {ReorderItem, ReorderItemsModalProps} from '.';
import ReorderItemView from './ReorderItemView';

export const ReorderItemsModal = (props: ReorderItemsModalProps) => {
  const [reorderItems, setReorderItems] = React.useState<Array<ReorderItem>>(
    props.items,
  );
  const [initialReorderItems] = React.useState<Array<ReorderItem>>(
    cloneDeep(props.items),
  );
  const [disableSaving, setDisableSaving] = React.useState<boolean>(true);

  React.useEffect(() => {
    _checkSaveState();
  }, [reorderItems]); // eslint-disable-line react-hooks/exhaustive-deps

  const _handleSubmit = () => {
    props.onSubmit(reorderItems);
  };

  const _renderDragAndDrop = () => {
    return (
      <DndProvider backend={HTML5Backend}>
        <div data-testid="reorder-list" style={style}>
          {reorderItems.map((thisReorderItem, i) => (
            <ReorderItemView
              key={thisReorderItem.id}
              index={i}
              id={thisReorderItem.id}
              title={thisReorderItem.title}
              count={thisReorderItem.count}
              category={thisReorderItem.category}
              content={thisReorderItem.content}
              reorderItem={reorderItem}
              moveItem={(direction) => {
                moveItem(thisReorderItem.id, direction);
              }}
              firstItem={reorderItems[0].id === thisReorderItem.id}
              lastItem={reorderItems?.at?.(-1)?.id === thisReorderItem.id}
            />
          ))}
        </div>
      </DndProvider>
    );
  };

  const moveItem = (id: number, direction: 'up' | 'down'): void => {
    const dragItemsIndex = reorderItems.findIndex((item) => item.id === id);
    const dragItem = reorderItems[dragItemsIndex];

    setReorderItems(
      update(reorderItems, {
        $splice: [
          [dragItemsIndex, 1],
          [
            direction === 'up' ? dragItemsIndex - 1 : dragItemsIndex + 1,
            0,
            dragItem,
          ],
        ],
      }),
    );
  };

  const reorderItem = (dragIndex: number, hoverIndex: number) => {
    const dragItem = reorderItems[dragIndex];
    setReorderItems(
      update(reorderItems, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragItem],
        ],
      }),
    );
  };

  const _checkSaveState = () => {
    if (JSON.stringify(initialReorderItems) === JSON.stringify(reorderItems)) {
      setDisableSaving(true);
      return;
    }

    setDisableSaving(false);
  };

  return (
    <Modal
      onSubmit={_handleSubmit}
      onClose={props.onClose}
      size="maxHeight"
      testID="reorder-modal"
      title={strings.ORDER_MODAL_HEADLINE}
      icon={<MoveDownIcon />}
      submitDisabled={disableSaving}>
      {props.items.length > 0 && (
        <>
          <Typography sx={{mb: 2}}>{strings.ORDER_MODAL_NOTICE}</Typography>
          {_renderDragAndDrop()}
        </>
      )}
      {!props.items.length && (
        <Typography sx={{mb: 2}} variant="h4">
          {strings.ORDER_MODAL_DISABLED}
        </Typography>
      )}
    </Modal>
  );
};

const style = {
  width: '100%',
};
