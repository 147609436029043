export const questionEditSuccess = {
  success: true,
  code: 200,
  response: {
    editedQuestions: [1],
  },
};

export const questionsOrderSuccess = {
  success: true,
  code: 200,
  response: {
    editedQuestions: [1, 2, 3],
  },
};

export const deleteQuestionSuccess = {
  success: true,
  code: 200,
  response: {
    deletedQuestions: 1,
  },
};
