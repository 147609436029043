import {createTheme} from '@mui/material/styles';

// typescript way to get new color
// PaletteColorOptions from @mui/material/styles
// declare module '@mui/material/styles' {
//   interface Palette {
//     myAwesomeColor: PaletteColorOptions;
//   }
//   interface PaletteOptions {
//     myAwesomeColor: PaletteColorOptions;
//   }
// }
// declare module '@mui/material/Button' {
//   interface ButtonPropsColorOverrides {
//     myAwesomeColor: true;
//   }
// }

const {palette} = createTheme();

export const theme = createTheme({
  palette: {
    primary: palette.augmentColor({color: {main: '#ffa338'}}),
    secondary: palette.augmentColor({color: {main: '#CD3D32'}}),
    error: palette.augmentColor({color: {main: '#9F1A16'}}),
    warning: palette.augmentColor({color: {main: '#ffa338'}}),
    success: palette.augmentColor({color: {main: '#90ba5b'}}),
    info: palette.augmentColor({color: {main: '#b3adab'}}),
  },
  typography: {
    h1: {
      fontSize: '2.5rem',
    },
    h2: {
      fontSize: '1.75rem',
    },
    h3: {
      fontSize: '1.8rem',
    },
    h4: {
      fontSize: '1.3rem',
    },
  },
  spacing: 8,
});

theme.typography.h2 = {
  ...theme.typography.h2,
  [theme.breakpoints.up('xl')]: {
    fontSize: '2rem',
  },
};
