import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {Modal} from 'components/molecules/Modal';
import {getQuestionBlockByTitle, strings, questionBlocks} from 'settings';
import {extractIdFromPlaceholder} from 'helper';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {QuizCategory} from 'screens/QuizDetailScreen';

interface TargetVocabInputs {
  id: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  description?: string;
}

interface AddQuestionsBlockModalProps {
  quizCategory: QuizCategory;
  onSubmit: <T>(data: T) => void;
  onClose: () => void;
}

export const AddQuestionsBlockModal = (props: AddQuestionsBlockModalProps) => {
  const [questionBlockType, setQuestionBlockType] = React.useState('');
  const [targetVocabInputs, setTargetVocabInputs] = React.useState<
    Array<TargetVocabInputs>
  >([]);

  const emptyForm = {
    questionBlockType: '',
    targetVocab1: '',
    targetVocab2: '',
    targetVocab3: '',
    targetVocab4: '',
    targetVocab5: '',
    targetVocab6: '',
    targetVocab7: '',
    targetVocab8: '',
  };

  const {handleSubmit, reset, control} = useForm({
    defaultValues: emptyForm,
  });

  const _onSubmit = (data) => {
    props.onSubmit(data);
    reset(emptyForm);
    props.onClose();
  };

  const _onClose = () => {
    reset(emptyForm);
    props.onClose();
  };

  const _setQuestionBlockType = (type: string) => {
    reset({...emptyForm, questionBlockType: type});

    const thisQuestionBlock = getQuestionBlockByTitle(type);

    let thisTargetVocabs: Array<TargetVocabInputs> = [];

    if (thisQuestionBlock) {
      thisQuestionBlock.questions.forEach((question) => {
        const id = extractIdFromPlaceholder(
          question.targetVocab,
        ) as TargetVocabInputs['id'];

        const alreadyAdded = thisTargetVocabs.find(
          (targetVocab) => targetVocab.id === id,
        );

        if (alreadyAdded) {
          const oldDescription = alreadyAdded.description;
          const newDescription = question.description;

          if (!oldDescription && newDescription) {
            alreadyAdded.description = newDescription;
          }

          if (
            oldDescription &&
            newDescription &&
            !oldDescription.includes(newDescription)
          ) {
            alreadyAdded.description += ', ' + question.description;
          }

          return;
        }

        thisTargetVocabs.push({id: id, description: question.description});
      });
    }

    setTargetVocabInputs(thisTargetVocabs);
    setQuestionBlockType(type);
  };

  const _renderTargetVocabs = () => {
    const thisQuestionBlock = getQuestionBlockByTitle(questionBlockType);

    return (
      <>
        {thisQuestionBlock?.description ? (
          <Typography>{thisQuestionBlock.description}</Typography>
        ) : null}

        {targetVocabInputs.map((targetVocab) => {
          const thisName = `targetVocab${targetVocab.id}` as const;

          return (
            <FormControl key={targetVocab.id}>
              <Controller
                name={thisName}
                control={control}
                rules={{
                  required: strings.QUIZ_MODAL_TARGET_VOCAB_REQUIRED,
                }}
                render={({field: {onChange, value}, fieldState}) => (
                  <>
                    <TextField
                      label={
                        strings.QUIZ_MODAL_TARGET_VOCAB + ' ' + targetVocab.id
                      }
                      value={value}
                      helperText={targetVocab.description}
                      data-testid={'qb-target-vocab-input'}
                      placeholder={
                        strings.QUIZ_MODAL_TARGET_VOCAB_PLACEHOLDER +
                        ' ' +
                        targetVocab.id
                      }
                      onChange={onChange}
                    />
                    {fieldState.error && (
                      <FormHelperText error={true}>
                        {fieldState.error.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </FormControl>
          );
        })}
      </>
    );
  };

  const _getAutocompleteOptions = () => {
    return questionBlocks
      .filter((block) => {
        if (!block.quizCategoryAvailability) {
          return true;
        }

        return block.quizCategoryAvailability.includes(props.quizCategory);
      })
      .map((block) => {
        return {
          label: block.title,
          id: block.title,
        };
      });
  };

  return (
    <Modal
      onSubmit={handleSubmit(_onSubmit)}
      onClose={_onClose}
      size="small"
      testID="qb-modal"
      title={strings.QUIZ_ADD_QUESTION_BLOCK_TITLE}
      icon={<AddCircleOutlineIcon />}
      submitText={strings.QUIZ_ADD_QUESTION_BLOCK}>
      <Stack spacing={2}>
        <FormControl>
          <Controller
            control={control}
            name="questionBlockType"
            rules={{
              required: strings.QUIZ_QUESTION_BLOCK_MODAL_SELECT_ERROR,
            }}
            render={({field: {onChange}, fieldState}) => (
              <>
                <Autocomplete
                  options={_getAutocompleteOptions()}
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value.id;
                  }}
                  onChange={(_, value) => {
                    if (typeof value !== 'string' && value?.id) {
                      _setQuestionBlockType(value.id);
                    }

                    onChange(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      label={strings.QUIZ_QUESTION_BLOCK_MODAL_SELECT}
                      type="text"
                      inputProps={{'data-testid': 'quiz-category-select'}}
                      {...params}
                    />
                  )}
                />
                {fieldState.error && (
                  <FormHelperText error={true}>
                    {fieldState.error.message}
                  </FormHelperText>
                )}
              </>
            )}
          />
        </FormControl>

        {questionBlockType === '' ? (
          <Typography>{strings.QUIZ_QUESTION_BLOCK_MODAL_INFO}</Typography>
        ) : (
          _renderTargetVocabs()
        )}
      </Stack>
    </Modal>
  );
};
