import {SearchModalContent, SearchModalResultSections} from '.';

export const getContentResultFormat = (
  inputString: string,
  content: Array<SearchModalContent>,
): Array<SearchModalResultSections> => {
  let results = [];

  const foundChildren: SearchModalResultSections = {
    headline: '',
    results: [],
  };

  let inputSplit = [];
  if (inputString) {
    inputSplit = inputString.match(/([^_]+)/g);
  }

  let matchModule = inputSplit[0];
  const matchLesson = inputSplit[1];
  let matchQuiz = '';
  // let matchQuestion = '';

  if (inputSplit[2]) {
    const quizSplit = inputSplit[2].match(/([^-]+)/g);

    matchQuiz = quizSplit[0];
    // matchQuestion = quizSplit[1];
  }

  if (matchModule && matchModule.length === 1 && matchModule !== '0') {
    return [];
  }

  content.forEach((module) => {
    if (!matchModule || matchModule.length === 1) {
      if (!foundChildren.headline) {
        foundChildren.headline = 'Modules';
      }

      foundChildren.results.push({
        title: module.title,
        titleCode: module.code,
        value: module.code,
        type: module.type,
      });
    } else {
      if (module.code.includes(matchModule)) {
        module.children.forEach((lesson) => {
          if (!matchLesson || matchLesson.length === 1) {
            if (!foundChildren.headline) {
              foundChildren.headline = 'Lessons';
            }

            foundChildren.results.push({
              title: lesson.title,
              titleCode: module.code + '_' + lesson.code,
              value: lesson.code,
              type: lesson.type,
            });
          } else {
            if (lesson.code.includes(matchLesson)) {
              results.push({
                headline: 'Lesson',
                results: [
                  {
                    title: lesson.title,
                    titleCode: module.code + '_' + lesson.code,
                    url: 'lessons/' + lesson.id,
                    type: lesson.type,
                  },
                ],
              });

              lesson.children.forEach((quiz) => {
                if (!matchQuiz) {
                  if (!foundChildren.headline) {
                    foundChildren.headline = 'Quizzes';
                  }

                  const _quizTitleCode =
                    module.code + '_' + lesson.code + '_' + quiz.code;

                  foundChildren.results.push({
                    title: _quizTitleCode + '-' + quiz.title,
                    titleCode: _quizTitleCode,
                    url: 'quizzes/' + quiz.id,
                    type: quiz.type,
                  });
                } else {
                  if (quiz.code === matchQuiz) {
                    const _quizTitleCode =
                      module.code + '_' + lesson.code + '_' + quiz.code;

                    results.push({
                      headline: 'Quiz',
                      results: [
                        {
                          title: _quizTitleCode + '-' + quiz.title,
                          titleCode: _quizTitleCode,
                          url: 'quizzes/' + quiz.id,
                          type: quiz.type,
                        },
                      ],
                    });
                    // QUESTION
                    // quiz.children.forEach((question) => {
                    //   if (!matchQuestion) {
                    //     if (!foundChildren.headline) {
                    //       foundChildren.headline = 'Questions';
                    //     }
                    //     foundChildren.results.push({
                    //       title: question.title,
                    //       titleCode:
                    //         module.code +
                    //         '_' +
                    //         lesson.code +
                    //         '_' +
                    //         quiz.code +
                    //         '-' +
                    //         question.code,
                    //       url: 'quizzes/question/' + question.code, // update test
                    //       type: question.type,
                    //     });
                    //   } else {
                    //     if (question.code === matchQuestion) {
                    //       if (!foundChildren.headline) {
                    //         foundChildren.headline = 'Question';
                    //       }
                    //       foundChildren.results.push({
                    //         title: question.title,
                    //         titleCode:
                    //           module.code +
                    //           '_' +
                    //           lesson.code +
                    //           '_' +
                    //           quiz.code +
                    //           '-' +
                    //           question.code,
                    //         url: 'quizzes/question/' + question.code, // update test
                    //         type: question.type,
                    //       });
                    //       // reset results because we dont need quiz-detail link if there is a exact question
                    //       results = [];
                    //     }
                    //   }
                    // });
                  }
                }
              });
            }
          }
        });
      }
    }
  });

  if (foundChildren.results && foundChildren.results.length) {
    results.push(foundChildren);
  }

  return results;
};
