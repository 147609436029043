import {QuestionCategory} from 'types';
import {QuizCategory} from 'screens/QuizDetailScreen';

interface QuestionBlockItem {
  category: QuestionCategory;
  targetVocab: string;
  description?: string;
}

interface QuestionBlock {
  title: string;
  quizCategoryAvailability?: Array<QuizCategory>;
  description: string;
  questions: Array<QuestionBlockItem>;
}

type QuestionBlocks = Array<QuestionBlock>;

export const questionBlocks: QuestionBlocks = [
  {
    title: 'Discover',
    description:
      '"Discover" block contains 6 questions – 1a | 1a | 2d | 2l | | 3a | 4d',
    questions: [
      {category: '1a', targetVocab: `${1}`},
      {category: '1a', targetVocab: `${2}`},
      {category: '2d', targetVocab: `${1}`},
      {category: '2l', targetVocab: `${2}`},
      {category: '3a', targetVocab: `${1}`},
      {category: '4d', targetVocab: `${2}`},
    ],
  },
  {
    title: 'Discover 2',
    quizCategoryAvailability: [
      'Develop',
      'Discover',
      'Explore',
      'Iconic',
      'Quiz',
    ],
    description:
      '"Discover 2" block contains 6 questions – 1a | 1a | 2l | 2d | 2n | 4d',
    questions: [
      {category: '1a', targetVocab: `${1}`},
      {category: '1a', targetVocab: `${2}`},
      {category: '2l', targetVocab: `${1}`},
      {category: '2d', targetVocab: `${2}`},
      {category: '2n', targetVocab: `${1}`},
      {category: '4d', targetVocab: `${2}`},
    ],
  },
  {
    title: 'Develop/Explore',
    quizCategoryAvailability: [
      'Develop',
      'Discover',
      'Explore',
      'Iconic',
      'Quiz',
    ],
    description:
      '"Develop or Explore" block contains 12 questions – 1a | 1a | 1c | 1a | 2d | 2l | 2d | 2l | 4d | 2n | 2c | 3a ',
    questions: [
      {category: '1a', targetVocab: `${1}`},
      {category: '1a', targetVocab: `${2}`},
      {category: '1c', targetVocab: `${3}`},
      {category: '1a', targetVocab: `${4}`},
      {category: '2d', targetVocab: `${1}`},
      {category: '2l', targetVocab: `${3}`},
      {category: '2d', targetVocab: `${2}`},
      {category: '2l', targetVocab: `${4}`},
      {category: '4d', targetVocab: `${3}`},
      {category: '2n', targetVocab: `${2}`},
      {category: '2c', targetVocab: `${4}`},
      {category: '3a', targetVocab: `${1}`},
    ],
  },
  {
    title: 'Iconic',
    quizCategoryAvailability: [
      'Develop',
      'Discover',
      'Explore',
      'Iconic',
      'Quiz',
      'Milestone',
    ],
    description: '"Iconic" block contains 5 questions – 2j | 2g | 2j | 2g | 3b',
    questions: [
      {category: '2j', targetVocab: `${1}`},
      {category: '2g', targetVocab: `${2}`},
      {category: '2j', targetVocab: `${3}`},
      {category: '2g', targetVocab: `${4}`},
      {category: '3b', targetVocab: `${5}`},
    ],
  },
  {
    title: 'Training/Milestone 1',
    quizCategoryAvailability: [
      'Develop',
      'Discover',
      'Explore',
      'Iconic',
      'Milestone',
      'Review',
      'Quiz',
      'Training',
    ],
    description:
      '"Training/Milestone 1" block contains 4 questions – 4d | 4e | 4f | 2n',
    questions: [
      {category: '4d', targetVocab: `${1}`},
      {category: '4e', targetVocab: `${2}`},
      {category: '4f', targetVocab: `${3}`},
      {category: '2n', targetVocab: `${4}`},
    ],
  },
  {
    title: 'Training/Milestone 2',
    quizCategoryAvailability: [
      'Develop',
      'Discover',
      'Explore',
      'Iconic',
      'Milestone',
      'Review',
      'Quiz',
      'Training',
    ],
    description:
      '"Training/Milestone 2" block contains 8 questions – 4i | 3a | 2n | 4g',
    questions: [
      {category: '4i', targetVocab: `${1}`},
      {category: '3a', targetVocab: `${2}`},
      {category: '2n', targetVocab: `${3}`},
      {category: '4g', targetVocab: `${4}`},
    ],
  },
  {
    title: 'Review',
    quizCategoryAvailability: ['Iconic', 'Milestone', 'Review', 'Quiz'],
    description:
      '"Review" block contains 8 questions – 4a | 4a | 4b | 4a | 4f | 2n | 4e | 3a',
    questions: [
      {category: '4a', targetVocab: `${1}`},
      {category: '4a', targetVocab: `${2}`},
      {category: '4b', targetVocab: `${3}`},
      {category: '4a', targetVocab: `${4}`},
      {category: '4f', targetVocab: `${3}`},
      {category: '2n', targetVocab: `${2}`},
      {category: '4e', targetVocab: `${4}`},
      {category: '3a', targetVocab: `${1}`},
    ],
  },
  {
    title: 'Review-2',
    quizCategoryAvailability: ['Iconic', 'Milestone', 'Review', 'Quiz'],
    description:
      '"Review-2" block contains 8 questions – 4a | 4b | 4a | 4a | 4i | 2c | 2b | 4g',
    questions: [
      {category: '4a', targetVocab: `${1}`},
      {category: '4b', targetVocab: `${2}`},
      {category: '4a', targetVocab: `${3}`},
      {category: '4a', targetVocab: `${4}`},
      {category: '4i', targetVocab: `${1}`},
      {category: '2c', targetVocab: `${4}`},
      {category: '2b', targetVocab: `${3}`},
      {category: '4g', targetVocab: `${2}`},
    ],
  },
];

export const getQuestionBlockByTitle = (title: string) => {
  return questionBlocks.find((block) => block.title === title);
};
