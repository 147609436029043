import React, {ReactElement} from 'react';
import {Typography} from '@mui/material';
import {alpha} from '@mui/material/styles';
import {theme} from 'settings/theme';

interface ShortcutLabelProps {
  shortcut?: string;
  meta?: boolean;
  icon?: ReactElement;
  dark?: boolean;
}

export const ShortcutLabel = (props: ShortcutLabelProps) => {
  return (
    <div
      style={{
        padding: props.icon ? '0' : theme.spacing(0, 1),
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: props.dark
          ? '#EBEBEB'
          : alpha(theme.palette.common.white, 0.5),
        borderRadius: theme.shape.borderRadius,
        color: alpha(theme.palette.common.black, 0.5),
      }}>
      {props.shortcut && (
        <Typography sx={{fontSize: '14px'}}>
          {props.meta && /Mac/.test(window.navigator.userAgent) ? '⌘' : 'crtl'}
          {props.shortcut}
        </Typography>
      )}
      {props.icon}
    </div>
  );
};
