import React from 'react';
import {Typography} from '@mui/material';
import {strings} from 'settings';
import {QuestionPreviewItem} from 'components/molecules/QuestionPreviewItem';
import {Modal} from 'components/molecules/Modal';
import DeleteIcon from '@mui/icons-material/Delete';
import {DataGridQuestion} from '../../../screens/QuizDetailScreen/index';

interface DeleteQuestionModalProps {
  question: DataGridQuestion;
  onClose: () => void;
  onDelete: () => void;
}

export const DeleteQuestionModal = (props: DeleteQuestionModalProps) => {
  return (
    <Modal
      onSubmit={props.onDelete}
      onClose={props.onClose}
      size="default"
      testID="delete-question-modal"
      title={strings.DELETE_QUESTION_MODAL_HEADLINE}
      submitText={strings.DELETE}
      icon={<DeleteIcon />}
      submitDisabled={!props.question}>
      {!props.question && (
        <Typography>{strings.DELETE_QUESTION_MODAL_DISABLED}</Typography>
      )}
      {props.question && (
        <>
          <Typography mb={3}>{strings.DELETE_QUESTION_MODAL_CHECK}</Typography>
          <QuestionPreviewItem
            count={props.question.count}
            title={props.question.title || ''}
            content={props.question.question || ''}
            category={props.question.category}
            draggable={false}
          />
          <Typography mt={2}>{strings.DELETE_QUESTION_MODAL_NOTICE}</Typography>
        </>
      )}
    </Modal>
  );
};
