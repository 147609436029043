import {Stack} from '@mui/material';
import {styled, alpha} from '@mui/material/styles';
import {ReorderButtons} from '../ReorderButtons';
import {QuestionPreviewItemProps} from './';

export const QuestionPreviewItem = ({
  count,
  title,
  category,
  content,
  draggable,
  moveItem,
  firstItem,
  lastItem,
}: QuestionPreviewItemProps) => {
  return (
    <StyledReorderItem draggable={draggable}>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}>
        {moveItem && (
          <ReorderButtons
            upDisabled={Boolean(firstItem)}
            downDisabled={Boolean(lastItem)}
            moveDialog={moveItem}
          />
        )}
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}>
          <StyledIndicator>{count}</StyledIndicator>
          {category && (
            <StyledSecondaryIndicator>{category}</StyledSecondaryIndicator>
          )}
        </Stack>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          sx={{overflow: 'hidden'}}>
          <strong>{title}</strong>
          <StyledContent>{content}</StyledContent>
        </Stack>
      </Stack>
    </StyledReorderItem>
  );
};

const StyledReorderItem = styled('div')(({theme, draggable}) => ({
  border: '1px solid #EFEFEF',
  borderRadius: '4px',
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
  cursor: draggable ? 'move' : 'auto',

  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.05),
    border: '1px solid #FFA338',
  },
}));

const StyledIndicator = styled('div')({
  backgroundColor: '#DEDEDE',
  borderRadius: '50%',
  width: '48px',
  height: '48px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
  fontSize: '16px',
});

const StyledSecondaryIndicator = styled('div')({
  border: '1px solid #DEDEDE',
  borderRadius: '4px',
  width: '32px',
  height: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
  fontSize: '14px',
  color: '#DEDEDE',
});

const StyledContent = styled('div')({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  width: '100%',
  fontSize: '14px',
});
