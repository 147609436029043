import {styled} from '@mui/material/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {IconButton, Tooltip} from '@mui/material';
import {accessibilityLabels, strings} from 'settings';

interface ViewButtonProps {
  onClick: () => void;
  active: boolean;
}

export const ViewButton = (props: ViewButtonProps) => {
  return (
    <StyledViewIconWrapper>
      <Tooltip
        title={
          !props.active
            ? strings.QUIZ_OPEN_QUESTION_DISABLED
            : strings.QUIZ_OPEN_QUESTION
        }>
        <IconButton
          data-state={!props.active ? 'disabled' : 'default'}
          aria-label={accessibilityLabels.QUIZ.VIEW_QUESTION}
          onClick={() => (props.active ? props.onClick() : null)}>
          {props.active ? (
            <VisibilityIcon htmlColor="#DEDEDE" />
          ) : (
            <VisibilityOffIcon htmlColor="#DEDEDE" />
          )}
        </IconButton>
      </Tooltip>
    </StyledViewIconWrapper>
  );
};

const StyledViewIconWrapper = styled('div')({
  button: {
    '&:hover:not([data-state="disabled"])': {
      '*': {
        fill: '#808080',
      },
    },
  },
});
