import {Link} from '@mui/material';

interface NavLinkProps {
  text: string;
  href: string;
}

export const NavLink = (props: NavLinkProps) => {
  return (
    <Link href={props.href} underline="hover" color="#000000" flex="1">
      {props.text}
    </Link>
  );
};
