import React from 'react';
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import TableCell from '@mui/material/TableCell';
import {strings} from 'settings';
import SaveIcon from '@mui/icons-material/Save';
import {SaveModalProps} from './';
import {styled} from '@mui/material/styles';
import parse from 'html-react-parser';
import Diff from 'text-diff';
import {Modal} from 'components/molecules/Modal';

export const SaveModal = (props: SaveModalProps) => {
  const _renderChanges = () => {
    const textDiff = new Diff();
    const changesByQuestion: Array<{
      count: string;
      changes: Array<{field: string; value: string; diffable: boolean}>;
      new: boolean;
    }> = [];

    props?.changes?.forEach?.((row) => {
      let questionIndex = changesByQuestion.findIndex(
        (question) => question.count === row.count,
      );

      if (questionIndex === -1) {
        changesByQuestion.push({
          count: row.count,
          changes: [],
          new: row.oldValue === '##new##',
        });

        questionIndex = changesByQuestion.length - 1;
      }

      if (row.oldValue === '##new##') {
        changesByQuestion[questionIndex]['changes'].push({
          field: row.field,
          value: row.newValue,
          diffable: false,
        });

        return;
      }

      const diffArray = textDiff.main(row.oldValue || '', row.newValue);
      textDiff.cleanupSemantic(diffArray);

      changesByQuestion[questionIndex]['changes'].push({
        field: row.field,
        value: diffArray,
        diffable: true,
      });
    });

    return changesByQuestion.map((question, i) => (
      <React.Fragment key={i}>
        <Typography sx={{mt: 4}} variant="h3">
          Question: {question.count}
          {question.new ? (
            <Chip
              sx={{ml: 2, border: question.new ? '1px solid #ffa338' : ''}}
              label={strings.QUIZ_MODAL_NEW_QUESTION}
            />
          ) : null}
        </Typography>
        <TableContainer
          sx={{mb: 4, mt: 2, border: question.new ? '1px solid #ffa338' : ''}}
          component={Paper}>
          <Table sx={{minWidth: 650}} aria-label="showing changes">
            <TableBody>
              {question.changes.map((change, changesIndex) => (
                <StyledTableRow data-testid="save-modal-row" key={changesIndex}>
                  <TableCell width={'150px'}>{change.field}</TableCell>
                  <TableCell>
                    {change.diffable
                      ? parse(textDiff.prettyHtml(change.value))
                      : change.value}
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </React.Fragment>
    ));
  };

  return (
    <Modal
      onSubmit={props.onSave}
      onClose={props.onClose}
      size="default"
      testID="save-modal"
      title={strings.QUIZ_CHANGES_HEADLINE}
      icon={<SaveIcon />}
      submitDisabled={props.mode === 'error'}>
      {props.mode === 'error' && (
        <>
          <Typography variant="h3">Quiz has errors</Typography>
          <Typography>{strings.QUIZ_CHANGES_SAVE_DISABLED}</Typography>
          <br />
          <Typography>{strings.QUIZ_CHANGES_ERROR_NOTE}</Typography>

          <TableContainer sx={{mb: 4, mt: 2}} component={Paper}>
            <Table sx={{minWidth: 650}} aria-label="showing changes">
              <TableBody>
                {props.errors
                  ? props.errors.map((error, i) => (
                    <StyledTableRow
                      data-testid="save-modal-error-row"
                      key={i}>
                      <TableCell width={'120px'}>
                        <strong>Question {error.count}</strong>
                      </TableCell>
                      <TableCell>{error.fields}</TableCell>
                    </StyledTableRow>
                  ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      {props.mode === 'changes' && (
        <>
          <Typography variant="h3">Check Data before Saving</Typography>
          <Typography>{strings.QUIZ_CHANGES}</Typography>

          {_renderChanges()}

          <Typography>{strings.QUIZ_CHANGES_NOTICE}</Typography>
        </>
      )}
    </Modal>
  );
};

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
