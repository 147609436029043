import {SearchModalContent} from 'components/organisms/SearchModal';

interface SearchModalResponse {
  success: boolean;
  code: number;
  response: Array<SearchModalContent>;
}

export const getSearchContent: SearchModalResponse = {
  success: true,
  code: 200,
  response: [
    {
      id: 24,
      title: 'Module 1: Introduction',
      code: '01',
      type: 'module',
      children: [
        {
          id: 10,
          title: 'Lesson 1 - Hello and Welcome',
          type: 'lesson',
          code: '01',
          children: [
            {
              id: 8074,
              title: 'Discover',
              type: 'quiz',
              code: '1',
            },
            {
              id: 8095,
              title: 'Dialog',
              type: 'quiz',
              code: '2',
            },
          ],
        },
      ],
    },
    {
      id: 2819,
      title: 'Module 2: Everyday Life',
      type: 'module',
      code: '02',
      children: [
        {
          id: 2821,
          title: "Lesson 1 - I'd like a cup of coffee please",
          type: 'lesson',
          code: '01',
          children: [
            {
              id: 8074,
              title: 'Discover',
              type: 'quiz',
              code: '1',
            },
            {
              id: 8095,
              title: 'Dialog',
              type: 'quiz',
              code: '2',
            },
            {
              id: 43744,
              title: 'Dialog',
              type: 'quiz',
              code: '3',
            },
            {
              id: 8097,
              title: 'Review',
              type: 'quiz',
              code: '4',
            },
            {
              id: 8999,
              title: 'Review',
              type: 'quiz',
              code: '5',
            },
          ],
        },
        {
          id: 2823,
          title: 'Lesson 2 - Are you hungry?',
          type: 'lesson',
          code: '02',
          children: [],
        },
        {
          id: 3352,
          title: 'Lesson 3 - Counting I',
          type: 'lesson',
          code: '03',
          children: [],
        },
      ],
    },
    {
      id: 191,
      title: 'Module 3: You and Me',
      code: '03',
      type: 'module',
      children: [],
    },
    {
      id: 45275,
      title: 'Module 4: On the way',
      code: '04',
      type: 'module',
      children: [],
    },
  ],
};
