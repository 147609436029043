import {styled} from '@mui/material/styles';
import {IconButton} from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface ReorderButtonsProps {
  upDisabled: boolean;
  downDisabled: boolean;
  moveDialog: (direction: 'up' | 'down') => void;
}

export const ReorderButtons = (props: ReorderButtonsProps) => {
  return (
    <StyledMoveItemWrapper>
      <IconButton
        data-testid="reorder-button-move-up"
        aria-label="move-up"
        size="small"
        disabled={props.upDisabled}
        sx={{width: '24px', height: '24px'}}
        onClick={() => {
          props.moveDialog('up');
        }}>
        <ArrowDropUpIcon
          sx={{position: 'absolute', width: '32px', height: '32px'}}
          htmlColor={
            props.upDisabled ? 'rgba(222, 222, 222, 0.4)' : 'rgb(222, 222, 222)'
          }
        />
      </IconButton>
      <IconButton
        data-testid="reorder-button-move-down"
        aria-label="move-down"
        size="small"
        disabled={props.downDisabled}
        sx={{width: '24px', height: '24px'}}
        onClick={() => {
          props.moveDialog('down');
        }}>
        <ArrowDropDownIcon
          sx={{position: 'absolute', width: '32px', height: '32px'}}
          htmlColor={
            props.downDisabled
              ? 'rgba(222, 222, 222, 0.4)'
              : 'rgb(222, 222, 222)'
          }
        />
      </IconButton>
    </StyledMoveItemWrapper>
  );
};

const StyledMoveItemWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});
