import React from 'react';
import {FormControl, TextField, Tooltip} from '@mui/material';
import {useStores} from 'store/root-store';
import {Observer} from 'mobx-react';
import {DataGridFieldChip} from 'components/molecules/DataGridFieldChip';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import {styled} from '@mui/material/styles';
import {Modal} from 'components/molecules/Modal';
import {strings} from 'settings';
import {PersonSwitcher} from 'components/molecules/PersonSwitcher';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {observer} from 'mobx-react-lite';
import {VideoIdInput} from 'components/molecules/VideoIdInput';

export const VideoModal = observer(() => {
  const {quizStore, authStore} = useStores();

  const _onClose = () => {
    quizStore.videoModal.onClose();
  };

  const _onSubmit = () => {
    quizStore.videoModal.setUpdate(true);
  };

  const _getSearchLink = () => {
    const searchValue = (
      quizStore.videoModal.solution ||
      quizStore.videoModal.description ||
      ''
    ).replaceAll(' ', '_');

    return `https://www.lingvano.com/${authStore.language}/wp-admin/admin.php?page=lv-videos&search=${searchValue}`;
  };

  const _copyIdToClipboard = () => {
    navigator.clipboard.writeText(String(quizStore.videoModal?.id));
  };

  return (
    <Modal
      onSubmit={_onSubmit}
      onClose={_onClose}
      readOnly={quizStore.videoModal.readOnly}
      size="default"
      testID="video-modal"
      title={strings.VIDEO_MODAL_HEADLINE}
      submitText={strings.QUIZ_CHANGE}
      icon={<OndemandVideoIcon />}>
      <Observer>
        {() => {
          return (
            <StyledWrapper>
              <StyledContentWrapper>
                <DataGridFieldChip
                  icon={
                    <OndemandVideoIcon
                      htmlColor="#616161"
                      sx={{ml: 0.5, width: '20px', height: '20px'}}
                    />
                  }
                  testId={'video-chip'}
                  fields={[
                    {
                      title: 'id',
                      value: String(quizStore.videoModal.id),
                      style: 'bold',
                    },
                    {
                      title: 'solution',
                      value: quizStore.videoModal.solution,
                      style: 'italic',
                    },
                    {
                      title: 'description',
                      value: quizStore.videoModal.description,
                      style: 'italic',
                    },
                  ]}
                />
                <FormControl sx={{width: '100%', mt: 3}}>
                  <VideoIdInput
                    autoFocus
                    value={quizStore.videoModal.id || null}
                    placeholder={'insert ID'}
                    disabled={quizStore.videoModal.readOnly}
                    onChange={(event) => {
                      quizStore.videoModal.setId(Number(event.target.value));
                    }}
                    sx={{mt: 2}}
                    InputProps={{
                      endAdornment: (
                        <>
                          {quizStore.videoModal.id ? (
                            <Tooltip title={strings.VIDEO_MODAL_COPY_TOOLTIP}>
                              <IconButton
                                aria-label="copy-id-to-clipboard-button"
                                onClick={() => {
                                  _copyIdToClipboard();
                                }}>
                                <ContentCopyIcon />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                          <Tooltip title={strings.VIDEO_MODAL_LINK_TOOLTIP}>
                            <IconButton
                              aria-label="video-id-search-button"
                              href={_getSearchLink()}
                              target="_blank">
                              <ImageSearchIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      ),
                    }}
                  />
                  <TextField
                    label={'Solution'}
                    type="text"
                    value={quizStore.videoModal.solution || ''}
                    placeholder={'insert solution'}
                    onChange={(event) => {
                      quizStore.videoModal.setSolution(event.target.value);
                    }}
                    disabled={
                      quizStore.videoModal.disabledField === 'solution' ||
                      quizStore.videoModal.readOnly
                    }
                    helperText={
                      quizStore.videoModal.disabledField === 'solution'
                        ? strings.VIDEO_MODAL_TARGET_VOCAB_HINT
                        : ''
                    }
                    sx={{mt: 2}}
                  />
                  <TextField
                    label={'Description'}
                    type="text"
                    value={quizStore.videoModal.description || ''}
                    placeholder={'description is optional'}
                    onChange={(event) => {
                      quizStore.videoModal.setDescription(event.target.value);
                    }}
                    disabled={
                      quizStore.videoModal.disabledField === 'description' ||
                      quizStore.videoModal.readOnly
                    }
                    helperText={
                      quizStore.videoModal.disabledField === 'description'
                        ? strings.VIDEO_MODAL_TARGET_VOCAB_HINT
                        : ''
                    }
                    sx={{my: 2}}
                  />
                  <PersonSwitcher
                    person={quizStore.videoModal.person || 'a'}
                    testID={'dv-modal-add-video-person'}
                    disabled={quizStore.videoModal.readOnly}
                    onChange={(event) => {
                      quizStore.videoModal.setPerson(event);
                    }}
                  />
                </FormControl>
              </StyledContentWrapper>

              <StyledVideoWrapper>
                {quizStore.videoModal.id ? (
                  <video width="492" height="369" controls muted autoPlay loop>
                    <source
                      src={`https://videos-${authStore.language}.lingvano.com/${quizStore.videoModal.id}-480p.mp4`}
                      type="video/mp4"
                      data-testid={'video-modal-element'}
                    />
                    Your browser does not support to preview the video.
                  </video>
                ) : null}
              </StyledVideoWrapper>
            </StyledWrapper>
          );
        }}
      </Observer>
    </Modal>
  );
});

const StyledWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const StyledContentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
  paddingRight: '24px',
});

const StyledVideoWrapper = styled('div')({
  display: 'flex',
  width: '516px',
  paddingLeft: '24px',
  borderLeft: '1px solid #C6C6C6',
});
